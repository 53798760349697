<template>
    <el-form label-position="top">
        <el-steps style="margin-bottom: 50px" :active="mainStep" align-center>
            <el-step title="Configure" description="Configure the project" />
            <el-step title="Details" description="Provide the project details" />
            <el-step title="Final" description="Review the details" />
        </el-steps>

        <div v-if="mainStep == 0">
            <project-category-step v-model:form="form" v-if="activeStep == 'project_category'"
                style="text-align: center;"></project-category-step>
            <div v-if="activeStep == 'project_type'" style="text-align: center;">
                <standard-procurement-step v-model:form="form"
                    v-if="form.project_type == 0"></standard-procurement-step>
                <agreement-type-step v-model:form="form" v-if="form.project_type == 1"></agreement-type-step>
            </div>
            <div v-if="activeStep == 'agreement_type'" style="text-align: center;">
                <reopen-regular-interval-question-step v-model:form="form"
                    v-if="form.agreement_type == 0"></reopen-regular-interval-question-step>
            </div>

            <select-regular-interval-step v-model:form="form" v-if="activeStep == 'regular_interval'"
                style="text-align: center;"></select-regular-interval-step>

            <reopen-regular-interval-step v-model:form="form" v-if="activeStep == 'first_reopen'"
                style="text-align: center;"></reopen-regular-interval-step>
            <second-reopen-interval-step v-model:form="form" v-if="activeStep == 'second_reopen'"
                style="text-align: center;"></second-reopen-interval-step>

            <can-reopen-step v-model:form="form" v-if="activeStep == 'can_reopen'"
                style="text-align: center;"></can-reopen-step>
            <framework-agreement-select-threshold-step v-model:form="form" v-if="activeStep == 'select_threshold'"
                style="text-align: center;"></framework-agreement-select-threshold-step>
        </div>
        <div v-if="mainStep == 1">
            <procurement-projects-form v-model:selected="detail_form" @save="saveDetailForm" :pin_notice="selected.pin_notice"></procurement-projects-form>
        </div>
        <div v-if="mainStep == 2">
            <div style="margin-left: 50px;margin-right: 50px">
                <div class="row">
                    <div class="col-md-12">

                        <div><strong>Project Name:</strong> {{ detail_form.name }}</div>
                        <div>
                            <div><strong>Project Type : </strong> {{ form.project_type == 0 ? 'Standard Procurement':'Framework Agreement' }}</div>
                            <div v-if="form.project_type == 0">
                                <strong>{{ form.standard_type == 0 ? 'Open Procedure' : 'Competitive Flexible Procedure (CFP)' }} </strong>
                            </div>
                            <div v-if="form.project_type == 1">
                                <strong>{{ form.agreement_type == 0 ? 'Open Framework' : 'Closed Framework' }}</strong>
                                <div v-if="form.agreement_type == 0">
                                    <div v-if="form.reopen_regular_interval == 1">
                                        <span>Regular interval: {{
                                            $helpers.getRecurringIntervalLabel(form.recurring_interval) }} </span>
                                    </div>
                                    <div v-else>
                                        <div v-if="form.first_input_dates && form.first_input_dates.length">
                                            <div>Reopening schedule for the first 3 years</div>
                                            <div v-for="dateItem, i in form.first_input_dates" :key="i">{{
                                                form.first_input_dates.length > 1 ? $helpers.getOrdenalSuffix(i+1)+':' :
                                                '' }} {{ dateItem.date }}</div>
                                        </div>
                                        <div v-if="form.second_input_dates && form.second_input_dates.length">
                                            <div>Reopening schedule for the period after the first 3 years</div>
                                            <div v-for="dateItem, i in form.second_input_dates" :key="i">{{
                                                form.second_input_dates.length > 1 ?
                                                $helpers.getOrdenalSuffix(i+1)+':':'' }} {{ dateItem.date }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>Contact Value: {{ form.contact_value }}</div>
                            <div> <span v-if="form.can_re_open && form.project_type == 1"> Manual Reopen, </span> <span>{{ form.threshold == 0 ?
                                    'Above-Threshold':'Below-Threshold'}}</span> <span v-if="form.project_type == 1">({{
                                        form.standard_type == 0 ? 'Open Procedure' :'Competitive Flexible Procedure'
                                    }})</span></div>
                        </div>

                        <div>
                            <br>
                            {{ detail_form.description }}
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-4">
                        <div><strong>Main Description</strong></div>
                        <div v-html="detail_form.main_description"></div>

                        <div><strong>Common Description</strong></div>
                        <div v-html="detail_form.common_description"></div>
                    </div>
                    <div class="col-md-4">
                        <div><strong>My Files Description</strong></div>
                        <div v-html="detail_form.my_files_description"></div>
                        <div><strong>My Questions Description</strong></div>
                        <div v-html="detail_form.my_questions_description"></div>
                    </div>
                    <div class="col-md-4">
                        <div><strong>Specification Description</strong></div>
                        <div v-html="detail_form.spec_description"></div>
                        <div><strong>Q&A Description</strong></div>
                        <div v-html="detail_form.qa_description"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="text-align: center">
            <br>
            <el-button-group>
                <el-button type="primary" v-if="activeStep != 'project_category'" @click="handleBack"><i
                        class="fa fa-arrow-left"></i>Back</el-button>
                <el-button type="primary" @click="handleValidate" v-if="mainStep < 2">
                    Next <i class="fa fa-arrow-right"></i>
                </el-button>
                <el-button type="primary" @click="handleValidate" v-if="mainStep >= 2">
                    {{ selected.id ? 'Update':'Submit'}}
                </el-button>
            </el-button-group>
        </div>
    </el-form>
</template>

<script>
import { reactive, ref, computed, onUnmounted } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ReopenRegularIntervalStep from './ReopenRegularIntervalStep.vue'
import ProjectCategoryStep from './ProjectCategoryStep.vue'
import SecondReopenIntervalStep from './SecondReopenIntervalStep.vue'
import StandardProcurementStep from './StandardProcurementStep.vue'
import AgreementTypeStep from './AgreementTypeStep.vue'
import ReopenRegularIntervalQuestionStep from './ReopenRegularIntervalQuestionStep.vue'
import SelectRegularIntervalStep from './SelectRegularIntervalStep.vue'
import CanReopenStep from './CanReopenStep.vue'
import FrameworkAgreementSelectThresholdStep from './FrameworkAgreementSelectThresholdStep.vue'
import ProcurementProjectsForm from './ProcurementProjectsForm.vue'
import Swal from 'sweetalert2';
import { ProcurementProjects } from '@/api_services/procurement_projects';
import { useToast } from 'vue-toast-notification'

export default {
    components: { ReopenRegularIntervalStep, ProjectCategoryStep, SecondReopenIntervalStep, StandardProcurementStep, AgreementTypeStep, ReopenRegularIntervalQuestionStep, SelectRegularIntervalStep, CanReopenStep, FrameworkAgreementSelectThresholdStep, ProcurementProjectsForm },
    name: 'ProcurementStepper',
    props: {
        selected: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const toast = useToast()

        const steps = ref(['project_category', 'project_type', 'agreement_type', 'first_reopen', 'second_reopen', 'regular_interval', 'can_reopen', 'select_threshold'])
        const activeStep = ref(steps.value[0])
        const mainStep = ref(0)
        const loading = ref(false)

        const initialForm = () => ({
            name: null,
            description:null,
            first_input: null,
            second_input: null,
            first_input_dates: [],
            second_input_dates: [],
            project_category: null,
            project_type: null,
            standard_type: null,
            threshold: null,
            agreement_type: null,
            recurring_interval: null,
            reopen_regular_interval: null,
            can_re_open: null,
            run_another_interval: null,
            duration_type: null,
            duration_value: null,
            is_framework: null,
            framework_category: null,
            category: null,
            procedure_type: null,
            contact_value: null,
           
        })
      
        const form = reactive(initialForm())
        let detail_form = reactive(initialForm())

        if (props.selected && props.selected.id) {
            Object.entries(props.selected).forEach(([key, value]) => {
                if (key in form) {
                    form[key] = value
                }
            })

            Object.entries(props.selected).forEach(([key, value]) => {
                if (key in detail_form) {
                    detail_form[key] = value
                }
            })

            if (form.first_input_dates && form.first_input_dates.length) {
                form.first_input = form.first_input_dates.length
            }

            if (form.second_input_dates && form.second_input_dates.length) {
                form.second_input = form.second_input_dates.length
            }
        }
       

        const resetForm = () => {
            Object.assign(form, initialForm())
        }


        const saveDetailForm = (payload) => {
            detail_form = reactive(payload)
        }


        const rules = computed(() => {
            const v = {}
            return v
        })

        const v$ = useVuelidate(rules, form)

     

        const handleValidate = () => {
            v$.value.$touch()

            if (v$.value.$invalid) {
                return
            }

            if (mainStep.value == 2) {
                submitForm()
                return
            }

            next()
        }

        const submitForm = () => {
            v$.value.$touch()
            if (!v$.value.$invalid) {
                beforeSubmit()
            } else {
                console.log('Please fix validation errors.')
            }
        }

        onUnmounted(() => {
            resetForm()
        })

        const next = () => {
            if (mainStep.value == 1) {
                mainStep.value = 2
                return
            }

            const currentIndex = steps.value.indexOf(activeStep.value)
            const nextIndex = currentIndex + 1
            if (nextIndex < steps.value.length) {
                if (activeStep.value == 'agreement_type') {
                    if (form.agreement_type == 0 &&
                        form.reopen_regular_interval == 1
                    ) {
                        activeStep.value = 'regular_interval'
                        return
                    }
                }

                if (activeStep.value == 'second_reopen') {
                    activeStep.value = 'can_reopen'
                    return
                }

                if (activeStep.value == 'project_type') {
                    if (form.agreement_type == 1) {
                        activeStep.value = 'select_threshold'
                        return
                    }
                    if (form.project_type == 0) {
                        mainStep.value = 1
                        return
                    }
                }


                if (activeStep.value == 'agreement_type') {
                    if (form.agreement_type == 1) {
                        mainStep.value = 1
                        return
                    }
                }

                activeStep.value = steps.value[nextIndex]

            } else {

                if (activeStep.value == 'select_threshold') {
                    mainStep.value = 1
                    return
                }

                if (mainStep.value == 1) {
                    mainStep.value = 2
                    return
                }

                console.log('You are already at the last step.')
            }
        }

        const handleBack = () => {
            if (mainStep.value == 1) {
                mainStep.value = 0
                return
            }

            if (mainStep.value == 2) {
                mainStep.value = 1
                return
            }
            const currentIndex = steps.value.indexOf(activeStep.value)
            const previousIndex = currentIndex - 1
            if (previousIndex >= 0) {
                if (activeStep.value == 'regular_interval') {
                    activeStep.value = 'agreement_type'
                    return
                }

                if (activeStep.value == 'select_threshold') {
                    if (form.agreement_type == 1) {
                        activeStep.value = 'project_type'
                        return
                    }
                }
                activeStep.value = steps.value[previousIndex]
            } else {
                console.log('You are already at the first step.')
            }
        }

        const beforeSubmit = () => {
            Swal.fire({
                title: 'Review',
                text: 'You are about to '+(props.selected.id ? 'update this project.':'create a new project.')+' Please verify that all the details are accurate. Would you like to proceed?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSubmit()
                }
            })
        }

        const handleSubmit = () => {
            loading.value = true
            
            const payload = Object.assign({}, detail_form, {
                recurring_interval: form.recurring_interval,
                category: 0,
                project_type: form.project_type == null ? 0 : form.project_type,
                standard_type: form.standard_type == null ? 0:form.standard_type,
                agreement_type: form.agreement_type == null ? 0 : form.agreement_type,
                threshold: form.threshold,
                can_re_open: form.can_re_open == null ? 0: form.can_re_open,
                reopen_regular_interval: form.reopen_regular_interval == null ? 0 : form.reopen_regular_interval,
                first_input_dates: form.first_input_dates,
                second_input_dates: form.second_input_dates,
                contact_value: form.contact_value
            })

            if (props.selected.id) payload.id = props.selected.id

            ProcurementProjects.create(payload)
                .then(resp => {
                    toast.success(resp.data.message || 'Procurement Project has been saved successfully')
                    emit('save', form)
                })
                .catch(error => {
                    console.error('error:', error.response?.data || error)
                })
                .finally(() => {
                    loading.value = false
                })
        }

        return {
            form,
            v$,
            handleValidate,
            detail_form,
            steps,
            mainStep,
            activeStep,
            next,
            handleBack,
            beforeSubmit,
            handleSubmit,
            saveDetailForm
        }
    }
}
</script>

<style scoped>
span {
    color: red;
    font-size: 0.9rem;
}
</style>