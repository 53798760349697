<template>
    <div v-if="form" class="">
      <div class="pb-0 card-header">
        <h5 class="font-weight-bolder mb-0">{{title}}</h5>
      </div>
      <div class="card-body">
      <br/>
      <br>
        <form @submit.prevent.prevent="" @reset.prevent="onCancel">
    
          <h6>Group Form</h6>
  
          <div class="form-group">
            <label class="form-label">Name</label>
            <input type="text" v-model.trim="form.name"
            :class="{ 'is-invalid': v$.form.name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
              <div class="error-text">Name {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Description</label>
            <input type="text" v-model.trim="form.description"
            :class="{ 'is-invalid': v$.form.description.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.description.$errors" :key="error.$uid">
              <div class="error-text">Description {{ error.$message }}</div>
            </div>
          </div>
  
          <div class="pt-4 text-end form-buttons">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="button" @click="onSubmit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>
    
  </template>
  
  <script>
    import { Group } from '@/api_services/group';
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, requiredIf } from '@vuelidate/validators'
    import ElTagInput from '@/components/common/ElTagInput'

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: Object,
        selected_company: Number
      },
      components: {
        ElTagInput
      },
      data() {
        return {
          isSaving: false,
          form:{
            company_id: this.selected_company,
            name: null,
            description: null
          }
        }
      },
      validations() {
        return {
          form: {
            name: {
              required,
              $autoDirty: true
            },
            description: {
              required,
              $autoDirty: true
            }
          },
        }
      },
      created: function () {
        this.form = JSON.parse(JSON.stringify(this.selected))
      },
      watch: {
        selected(selected) {
          this.form = JSON.parse(JSON.stringify(selected))
        },
      },
      computed: {
        title() {
          return this.isNew ? 'Add New' : `Manage Group`
        },
        isNew() {
          return !this.selected?.id
        },
      },
      methods: {
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return

          this.isSaving = true
          const promise = this.isNew ? Group.create(this.form) : Group.update(this.selected.id, this.form)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Group has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
      }
    }
  </script>
  