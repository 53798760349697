<template>
    <div v-if="form" class="">
      <div class="pb-0 card-header">
        <h5 class="font-weight-bolder mb-0">{{title}}</h5>
      </div>
      <div class="card-body">
      <br/>
      <br>
        <form @submit.prevent.prevent="" @reset.prevent="onCancel">
    
          <h6>Timeline Form</h6>

          <div v-if="!isNew" class="form-group">
            <label class="form-label">Status</label>
            <select class="form-select" v-model="form.status">
                <option value="0">Pending</option>
                <option value="1">On-Going</option>
                <option value="2">Done</option>
                <option value="3">Canceled</option>
            </select>
          </div>

          <hr>

          <div class="form-group">
            <label class="form-label">Type</label>
            <select class="form-select" v-model="form.type">
                <option value="0">Procurement Project</option>
                <option value="1">Preliminary Market Engagement (PME)</option>
                <option value="2">Planned Procurement Notice (PPN)</option>
                <option value="3">Tender Notice (TN)</option>
            </select>
          </div>
  
          <div class="form-group">
            <label class="form-label">Name</label>
            <input type="text" v-model.trim="form.name"
            :class="{ 'is-invalid': v$.form.name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
              <div class="error-text">Name {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Description</label>
            <input type="text" v-model.trim="form.description"
            :class="{ 'is-invalid': v$.form.description.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.description.$errors" :key="error.$uid">
              <div class="error-text">Description {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">Start Date</label><br>
            <el-date-picker 
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            type="date" v-model.trim="form.start_date"
            :class="{ 'is-invalid': v$.form.start_date.$errors.length, '': true }" />
  
            <div class="input-errors" v-for="error of v$.form.start_date.$errors" :key="error.$uid">
              <div class="error-text">Start Date {{ error.$message }}</div>
            </div>
          </div>

          <div class="form-group">
            <label class="form-label">End Date</label><br>
            <el-date-picker 
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            type="date" v-model.trim="form.end_date"
            :class="{ 'is-invalid': v$.form.end_date.$errors.length, '': true }" />
  
            <div class="input-errors" v-for="error of v$.form.end_date.$errors" :key="error.$uid">
              <div class="error-text">End Date {{ error.$message }}</div>
            </div>
          </div>
  
          <div class="pt-4 text-end form-buttons">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="button" @click="onSubmit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>
    
  </template>
  
  <script>
    import { ProcurementProjectTimeline } from '@/api_services/procurement_project_timeline'
    import { useVuelidate } from '@vuelidate/core'
    import { required, email, requiredIf } from '@vuelidate/validators'
    import { mapState } from 'vuex'
    import ElTagInput from '@/components/common/ElTagInput'
    import Swal from 'sweetalert2';

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: Object,
        project_id: Number
      },
      components: {
        ElTagInput
      },
      data() {
        return {
          isSaving: false,
          form:{
            name: null,
            type: null,
            description: null,
            start_date: null,
            end_date: null,
            project_id: null
          }
        }
      },
      validations() {
        return {
          form: {
            name: {
              required,
              $autoDirty: true
            },
            type: {
              required,
              $autoDirty: true
            },
            description: {
              required,
              $autoDirty: true
            },
            start_date: {
              required,
              $autoDirty: true
            },
            end_date: {
              required,
              $autoDirty: true
            }
          },
        }
      },
      created: function () {
        this.form = JSON.parse(JSON.stringify(this.selected))
        this.form.project_id = this.project_id
      },
      watch: {
        selected(selected) {
          this.form = JSON.parse(JSON.stringify(selected))
        },
      },
      computed: {
        title() {
          return this.isNew ? 'Add New' : `Manage Procurement Project Timeline`
        },
        isNew() {
          return !this.selected?.id
        },
      },
      methods: {
        updateKeywords(value){
            this.form.keywords = value
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return

          this.isSaving = true
          const promise = this.isNew ? ProcurementProjectTimeline.create(this.form) : ProcurementProjectTimeline.update(this.selected.id, this.form)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Procurement Project Timeline has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
      }
    }
  </script>
  