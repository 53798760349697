<template>
    
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h5>List of Firebase Notifications</h5>
              <div class="pb-0 card-header">
                <div class="row">
                <div class="col-md-2">
                    <el-input v-on:keyup.enter="getFirebaseNotifications" v-model="search_value" placeholder="Search" class="input-with-select">
                    <template #append>
                        <el-button @click="getFirebaseNotifications"><i class="fa fa-search"></i></el-button>
                    </template>
                    </el-input>
                </div>
                <div class="col-md-10" style="text-align: right;">
                    <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" @click="getFirebaseNotifications()">
                    <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                    </button>
                </div>
                </div>
                <div class="text-end">
                </div>
            </div>
            <br>
              <div class="table-responsive">
                <table class="table table-fluid align-items-center mb-0 table-hover table-striped">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Sent To
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Title
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Message
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Link
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Date Sent
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!notifications.length">
                      <td v-if="loading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No data</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="notification in notifications" :key="notification.id">
                        <td>{{ notification.full_name }}</td>
                        <td>{{ notification.title }}</td>
                        <td>{{ notification.message }}</td>
                        <td><b><a href="{{ notification.link }}" target="_blank">Click Me</a></b></td>
                        <td>{{ notification.timestamp }}</td>
                        <td>
                            <a class="link" @click="showRemove(notification)" v-tooltip="'Delete'" >
                                <i class="fas fa-trash text-secondary"></i>
                            </a>
                        </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="getFirebaseNotifications()" />
            </div>
          </div>
        </div>
      </div>
    
      
      <el-dialog id="modal-remove" v-model="dialogVisible" title="Confirm Delete?" width="500" :before-close="handleClose">
        <div v-if="to_remove">
          You are about to remove this notification for {{ to_remove.full_name }}, Proceed?
        </div>
        <template #footer>
          <div class="dialog-footer">
            <el-button @click="dialogVisible = false">Cancel</el-button>
            <el-button v-bind:disabled="loading" type="danger" @click="remove">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    </template>
    
    <script>
    import { FirebaseNotifications } from '@/api_services/firebase_notifications'
    
    export default {
      data() {
        return {
          dialogVisible: false,
          notifications: [],
          to_remove: null,
          meta: {
            page: 1,
            total: 0,
            pages: 1
          },
          showForm: false,
          loading: false,
          search_value: null,
        }
      },
      watch: {
        search_value: function () {
            //if (!this.search_value || this.search_value.length > 2) this.getFirebaseNotifications()
        }
      },
      created: function () {
        this.getFirebaseNotifications()
      },
      methods: {
        handleClose() {
          this.dialogVisible = false
        },
        search() {
          this.meta.page = 1
          this.getFirebaseNotifications()
        },
        remove(bvModalEvt) {
          bvModalEvt.preventDefault()

          this.loading = true
          FirebaseNotifications.delete(this.to_remove)
            .then(result => {
              this.$toast.success(result?.data?.message || 'Notification has been removed successfully')
              this.getFirebaseNotifications()

              this.handleClose()
            })
            .catch(error => {
                this.$toast.error('An error occured while deleting notification')
                console.error('error:', error.response.data)

            }).finally(() => {
                this.loading = false
            })
        },
        showRemove(type) {
          this.to_remove = Object.assign({}, type)
          this.dialogVisible = true
        },
        getFirebaseNotifications() {
          this.loading = true
          this.handleClose()
          this.notifications = []
          FirebaseNotifications.get({
              params: {
                page: this.meta.page,
                search_value: this.search_value,
              }
            })
            .then(result => {
              if (result.data.firebase_notifications) this.notifications = result.data.firebase_notifications
              this.meta = result.data.meta
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.loading = false
            })
        }
      },
    }
    </script>
    