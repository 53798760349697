<template>
<div class="row mb-4">
    <div class="col-md-8">
            <select class="form-select" @change="loadTimelines" v-model="selected_type">
                <option value="4">ALL</option>
                <option value="0">Procurement Project</option>
                <option value="1">Preliminary Market Engagement (PME)</option>
                <option value="2">Planned Procurement Notice (PPN)</option>
                <option value="3">Tender Notice (TN)</option>
            </select>
    </div>
    <div class="col-md-4">
        <el-button-group>

            <el-button @click="reverse = !reverse" type="primary">
                <i v-if="!reverse" class="fa fa-arrow-up"></i>
                <i v-if="reverse" class="fa fa-arrow-down"></i>
                &nbsp;
                {{ reverse ? 'Sort by Descending' : 'Sort by Ascending' }}
            </el-button>   

            </el-button-group>
    </div>
</div>

<a-timeline v-if="Object.values(timeline_grouped).length > 0" :reverse="reverse">
    <template v-for="(timeline_group, key_name, index) in timeline_grouped">
        <a-timeline-item :position="'left'" :color="key_name < today_date ? 'gray' : 'blue' ">
            <template #dot>
                <template v-if="key_name < today_date"><CheckCircleOutlined style="font-size: 18px" /></template>
                <template v-if="key_name >= today_date"><ClockCircleOutlined style="font-size: 22px" /></template>
            </template>

            <a-list
                item-layout="horizontal"
                :data-source="timeline_group"
                :loading="false"
                :bordered="true"
            >
                <template #renderItem="{ item }">
                    <a-list-item>
                        <template #actions>
                        <p class="mb-0 text-sm" >From: <b>{{ $filters.dateFormatFilter(item.start_date) }}</b></p>
                        <p class="mb-0 text-sm" >To: <b>{{ $filters.dateFormatFilter(item.end_date) }}</b></p>
                        </template>
                        <a-skeleton avatar :title="false" :loading="false" active>
                        <a-list-item-meta class="me-3" style="text-align: justify; text-justify: inter-word;"
                            :description="item.description"
                        >
                            <template #title>
                            <p class="mb-0">{{ item.name }}
                                <template v-if="item.type < 4">
                                (
                                    <template v-if="item.type == 0">Procurement Project</template>
                                    <template v-if="item.type == 1">Preliminary Market Engagement (PME)</template>
                                    <template v-if="item.type == 2">Planned Procurement Notice (PPN)</template>
                                    <template v-if="item.type == 3">Tender Notice (TN)</template>
                                )
                                </template>
                            </p>
                            </template>
                            <template #avatar>
                                <a-avatar :style="{'background-color': 
                                    (item.status == 0) ? 'gray' : 
                                    (item.status == 1) ? 'skyblue' :
                                    (item.status == 2) ? 'green' : 'red'
                                }">
                                    <template #icon>
                                        <template v-if="item.status == 0">
                                            <MessageOutlined />
                                        </template>
                                        <template v-if="item.status == 1">
                                            <ClockCircleOutlined />
                                        </template>
                                        <template v-if="item.status == 2">
                                            <CheckCircleOutlined />
                                        </template>
                                        <template v-if="item.status == 3">
                                            <StopOutlined />
                                        </template>
                                        
                                    </template>
                                </a-avatar>
                            </template>
                        </a-list-item-meta>
                        <div>
                            <template v-if="item.status == 0"><a-tag color="gray">Pending</a-tag></template>
                            <template v-if="item.status == 1"><a-tag color="skyblue">On-Going</a-tag></template>
                            <template v-if="item.status == 2"><a-tag color="green">Done</a-tag></template>
                            <template v-if="item.status == 3"><a-tag color="red">Canceled</a-tag></template>
                        </div>
                        </a-skeleton>
                    </a-list-item>
                </template>
            </a-list>
            </a-timeline-item>
    </template>
</a-timeline>
<template v-else>
    <a-empty description="No event added!" />
</template>

</template>
  
  <script>
    import { ProcurementProjectTimeline } from '@/api_services/procurement_project_timeline'
    import { ClockCircleOutlined, MessageOutlined, SyncOutlined, CheckCircleOutlined, StopOutlined } from '@ant-design/icons-vue'

    export default {
      components: {
        ClockCircleOutlined, MessageOutlined, SyncOutlined, CheckCircleOutlined, StopOutlined
      },
      props: {
        procurement_project: {
          type: Object,
          required: false,
          default: []
        },
        type: {
            type: Number,
            default: 4
        }
      },
      data() {
        return {
            selected_type: null,
            reverse: false,
            today_date: null,
            CheckCircleOutlined: CheckCircleOutlined,
            timelines: [],
            timeline_grouped: {}
        }
      },
      created: function () {
        this.selected_type = this.type
        this.loadTimelines()
      },
      watch: {
        
      },
      computed: {
        
      },
      methods: {
        redirectToList(){
            window.location.href = '/main/procurement_projects/?target=' + btoa(this.procurement_project.id)
        },
        loadTimelines(){
          this.timelines = []
          this.timeline_grouped = {}
          this.globalState.loading = true
          ProcurementProjectTimeline.get({
              params: {
                project_id: this.procurement_project.id,
                if_collection: true,
                type: (this.selected_type < 4 ? this.selected_type : null)
              }
            })
            .then(result => {
              if (result.data.timelines){
                this.timelines = result.data.timelines
                this.today_date = result.data.today_date

                this.timelines.forEach((timeline) => {
                    const date = timeline.start_date
                    if (this.timeline_grouped[date]) {
                        this.timeline_grouped[date].push(timeline);
                    } else {
                        this.timeline_grouped[date] = [timeline];
                    }
                })
              } 
              
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      }
    }
  </script>
  <style>
   .ant-avatar span{
        vertical-align: 2px !important
   } 
</style>
  