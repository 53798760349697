<template>

    <div class="row">
        <div class="col-md-4 mb-2">
          <el-text size="large">List of Modules <b v-if="save_status != 0">( {{ save_status == 1 ? "Saved" : "Unsave" }} )</b></el-text>
          <hr class="mt-1">
          <a-menu
                
            mode="inline"
            :items="module_items"
            :v-model:openKeys="selected_module"
            @click="menuClick"
            :openKeys="openKeys"
          ></a-menu>
        </div>
        <div class="col-md-8 mb-2">
          <el-text size="large">List of Permissions</el-text>
          <hr class="mt-1">
          
          <template v-if="selected_module">
            <div class="row">
              <div class="col-md-12 mb-2">
                <el-checkbox class="float-start" @change="restrictChange" v-model="restrict_check" label="Restrict Entire Module" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <template v-for="data in selected_module.default_permission_list">
                  <div>
                      <PermissionItem
                      :is_restricted="restrict_check"
                      :key="'permission_item_' + data.id"
                      :features_prop="data"
                      @check_update="updateSelection"
                    ></PermissionItem>
                  </div>
                  
                </template>
              </div>
            </div>
          </template>
          <el-empty v-else description="No selected module!" />
        </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <hr class="mt-1">
        <el-button @click="savePermissions" type="primary"><i class="fa fa-save"></i>&nbsp;Save</el-button>
      </div>
    </div>
  </template>
  
  <script>

  import PermissionItem from './PermissionItem.vue'
  import { Group } from '@/api_services/group';
  import { StopOutlined, MenuOutlined } from '@ant-design/icons-vue'
  import { h, shallowRef } from 'vue';

    export default {
      emits: ["save"],
      props: {
        modules_and_permissions_prop: {
          type: Object
        },
        selected_group_data: {
          required: true,
          default: []
        },
        target_individual_user: {
          required: false,
          default: null
        }
      },
      components: {
        PermissionItem
      },
      data() {
        return {
          restrict_check: false,
          group_data: null,
          save_status: 0,
          selected_modules_and_permissions: null,
          module_items: [],
          selected_module: null,
          openKeys: [],
        }
      },
      created: function () {
        this.selected_modules_and_permissions = this.modules_and_permissions_prop
        this.setupModule(this.selected_modules_and_permissions)
      },
      watch: {
        selected_group_data: {
          handler(newValue) {
            if(newValue){
              
            }
          },
          deep: true
        },
      },
      computed: {
        
      },
      methods: {
        restrictChange(value){
          let is_restrict = value ? 1 : 0
          let module_id = this.selected_module.originItemValue.key
          
          let target_module = this.module_items.find(item => item.key === module_id)
          target_module.is_module_restricted = is_restrict
          target_module.default_permission_list.forEach(dp => {
            dp.is_restricted_to_module = is_restrict
          })
          target_module.icon = (is_restrict ? h(StopOutlined) : h(MenuOutlined))
        },
        savePermissions(){
          let selected_data = []
          let self = this

          this.module_items.forEach(module => {
              let module_id = module.key
              
              module.default_permission_list.forEach(default_permission => {
                let target_group_data = null
                if(self.group_data?.group_permissions ){
                  target_group_data = self.group_data.group_permissions.find(o => o.permission_list_id === default_permission.id)
                }

                let permission_features = default_permission.selected_permissions
                if(!permission_features){
                  permission_features = (target_group_data?.permission_features ? target_group_data?.permission_features : default_permission.features)
                }

                let item = {
                  id: (target_group_data ? target_group_data.id : null),
                  group_id: self.group_data?.id,
                  permission_type: (self.target_individual_user ? 1 : 0),
                  module_id: module_id,
                  permission_list_id: default_permission.id,
                  permission_features: permission_features,
                  is_restricted_to_module: default_permission.is_restricted_to_module,
                  user_id: (self.target_individual_user ? self.target_individual_user : null)
                }

                selected_data.push(item)
              })
            })
            //console.log(selected_data)
            this.globalState.loading = true

            Group.saveGroupPermissions({ data: selected_data })
            .then(resp => {
              
              this.$toast.success('Group permissions successfully saved')
              this.$emit('save', true)
            })
            .catch(error => {
              console.error('error:', error)
            })
            .finally(() => {
              this.globalState.loading = false
            })
        },
        updateSelection({feature_item_id, module_id, returned_features, selected_default_feature_count}){
          let target = this.module_items.find(o => o.key === module_id)
          if(target){

            let target_group_permissions = target.default_permission_list.find(item => item.id === feature_item_id)
            if(target_group_permissions.list_group_permissions){
              target_group_permissions.list_group_permissions.selected_permission_features = JSON.stringify(returned_features)
            }
            target_group_permissions.selected_permissions = JSON.stringify(returned_features)

            let current_count = 0
            let default_permission_list = target.default_permission_list
            default_permission_list.forEach(permission => {
              let selected_permissions = JSON.parse(permission.selected_permissions)
              current_count += selected_permissions?.length ?? 0
            })

            target.label = target?.title + " ("+ (current_count) +"/"+ target?.main_checkbox_count +")"
          }
        },
        menuClick(value){
            let is_restricted = false
            this.selected_module = value.item
            let module_target = this.module_items.find(o => o.key === this.selected_module.originItemValue.key)
            if(module_target){
              is_restricted = module_target.is_module_restricted ? true : false
            }
            this.restrict_check = is_restricted
        },
        setupModule(items){
          this.group_data = this.selected_group_data
          let menu_items = []
          let self = this

          items.forEach(module => {
            let main_checkbox_count = 0
            let main_group_permissions_count = 0
            let main_group_permissions = self.group_data?.group_permissions
            let selected_group_permission = null
            let is_module_restricted = 0

            let default_permission_list = module.default_permission_list
            default_permission_list.forEach(permission => {
              let features = JSON.parse(permission.features)
              main_checkbox_count = main_checkbox_count + features.length

              selected_group_permission = (main_group_permissions ? main_group_permissions.find(o => o.permission_list_id === permission.id) : null) 
              if(selected_group_permission){
                let selected_group_permission_features = JSON.parse(selected_group_permission?.permission_features)
                main_group_permissions_count += selected_group_permission_features?.length ?? 0

                if(selected_group_permission.is_restricted_to_module == 1){
                  is_module_restricted = 1
                  permission.is_restricted_to_module = 1
                }

                permission.list_group_permissions = {
                  selected_permission_features: selected_group_permission?.permission_features,
                  
                }
              }
            })
            
            let group_checked_data = (!selected_group_permission ? main_checkbox_count : main_group_permissions_count)

            menu_items.push({
              key: module.id,
              title: module.name,
              label: module.name + " ("+ group_checked_data + "/" + main_checkbox_count +")",
              children: null,
              default_permission_list: default_permission_list,
              main_checkbox_count: main_checkbox_count,
              group_checked_count: group_checked_data,
              is_module_restricted: is_module_restricted,
              icon: (is_module_restricted ? h(StopOutlined) : h(MenuOutlined))
            })
          })

          this.module_items = menu_items
        }
      }
    }
  </script>
  