<template>
    <div class="">
      <div class="" >
        <el-switch
     
        v-if="form.id && savedUser && savedUser.enable_manual_pin"
        v-model="enable_test_status"
        size="large"
        active-text="Enable Manual PPN Activation"
        inactive-text="Disable Manual PPN Activation"
      />
      <br/>
        <form @submit.prevent.prevent="onSubmit" @reset.prevent="onCancel">
          <el-alert
            v-if="form.status == ProcurementProjectStatuses.CODE_PENDING"
            class="mb-4 "
            title="Urgent Action Required!"
            type="warning"
            description="To proceed with your procurement project, it's necessary to 
            Publish a Planned Procurement Notice. Please do so by clicking the `Publish Planned Procurement Notice` button.
            Your prompt attention to this matter is greatly appreciated."
            show-icon
            :closable="false"
          />

          <div v-if="!isNew" class="row mb-3">
            <!-- <div class="col-md-4">
              <p><i class="fa fa-info-circle text-primary"></i>&nbsp;Status: <b>{{ form.status_name }}</b></p>
              <div v-if="form.is_framework">
                <div>Framework Option:
                  <span v-if="form.category == 0">Closed Framework</span>
                  <span v-if="form.category == 1">Open Framework</span>
                </div>
              </div>
              <div v-if="!form.is_framework">
                <div>Tender Option:
                  <span v-if="form.procedure_type == 0">Open Procedure</span>
                  <span v-if="form.procedure_type == 1">Restricted Procedure</span>
                </div>
              </div>
            </div> -->
            <div class="col-md-4">
              <el-button v-if="selected.status == ProcurementProjectStatuses.CODE_PENDING || (selected.status == ProcurementProjectStatuses.CODE_PRE_PIN || selected.status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED)" @click="openPublicationModal()" type="primary">
                <i class="fa fa-"></i>
                Publish 
                {{ selected.status == ProcurementProjectStatuses.CODE_PENDING ? 'Preliminary Market Engagement (PME)' : '' }}
                {{ (selected.status == ProcurementProjectStatuses.CODE_PRE_PIN || selected.status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED) ? 'Planned Procurement Notice (PPN)' : '' }}
              </el-button>
              <!-- <el-button style="margin-left: 5px;" v-if="selected.status != ProcurementProjectStatuses.CODE_PENDING"
                @click="handleOpenModal(FATNoticeTypes.F01.value)"
                type="primary"
                class="float-end"
              > -->
              <!--<el-button  v-if="selected.status == ProcurementProjectStatuses.CODE_PENDING"
                @click="pmeClick(TPPNoticeTypes.UK2.value)"
                type="primary"
              >
                <i class="fa fa-upload"></i>&nbsp;{{ selected.status == ProcurementProjectStatuses.CODE_PENDING ? 'Publish' : 'Check published' }} Preliminary Market Engagement Notice</el-button>


                  <div v-if="(selected.status == ProcurementProjectStatuses.CODE_ACTIVE || selected.status == ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED) || selected.status == ProcurementProjectStatuses.CODE_ITT_PENDING || selected.status == ProcurementProjectStatuses.CODE_ITT || selected.status == ProcurementProjectStatuses.CODE_ITT_SUBMITTED || selected.status == ProcurementProjectStatuses.CODE_MARKING_STAGE">
                      <el-button 
                      @click="handleOpenModal(TPPNoticeTypes.UK4.value)"
                      type="success"
                      class="float-end"
                      >
                        <i class="fa fa-upload"></i>&nbsp;{{ (selected.status == ProcurementProjectStatuses.CODE_ACTIVE || selected.status == ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED) ||
                        selected.status == ProcurementProjectStatuses.CODE_ITT_PENDING ? 
                        'Publish' : 'Check published' }} Tender Notice
                      </el-button>
                  </div>-->
            </div>
  

          </div>
        <div class="row">
          <!-- <div class="col-md-3">
            <div class="form-group">
              <label class="form-label">Type</label>
              <select 
              :class="{ 'is-invalid': v$.form.type.$errors.length, 'form-control form-control-sm': true }"
              v-model="form.type" >
                    <option v-for="procurement_type,i in procurement_types" :value="procurement_type.type_code" :key="i">{{procurement_type.type}}</option>
              </select>
              <div class="input-errors" v-for="error of v$.form.type.$errors" :key="error.$uid">
                <div class="error-text">Select Type</div>
              </div>
            </div>
          </div> -->
          <div class="col-md-12">

            <div class="form-group">
            <label class="form-label">Name</label>
            <input type="text" v-model.trim="form.name"
            :class="{ 'is-invalid': v$.form.name.$errors.length, 'form-control form-control-sm': true }">
  
            <div class="input-errors" v-for="error of v$.form.name.$errors" :key="error.$uid">
              <div class="error-text">Name {{ error.$message }}</div>
            </div>
          </div>
          </div>
          <div class="col-md-12">

            <div class="form-group">
            <label class="form-label">Description *</label>
            <textarea v-model.trim="form.description"
            :class="{ 'is-invalid': v$.form.description.$errors.length, 'form-control form-control-sm': true }"></textarea>
  
            <div class="input-errors" v-for="error of v$.form.description.$errors" :key="error.$uid">
              <div class="error-text">Description {{ error.$message }}</div>
            </div>
          </div>
          </div>
        </div>
      
        <div v-if="enable_test_status" class="form-group">
            <label class="form-label">Status</label>
            <select v-if="enable_test_status"
                class="form-control form-control-sm"
                v-model="form.status" >
                      <option value="ATC">Activate PPN</option>
                      <option value="ITT">Activate TN</option>
                </select>
          </div>

          <!-- <div class="row" v-if="form.is_framework">
            <div class="col-md-4" v-if="form.id">
              <div class="form-group">
                <label class="form-label">Category</label>
                <select
                    class="form-control form-control-sm"
                    v-model="form.category" >
                          <option :value="0">Closed Framework</option>
                          <option :value="1">Open Framework</option>
                    </select>
              </div>
            </div>
          </div> -->

          <div class="row" v-if="!form.is_framework">
            <div class="col-md-4" v-if="form.id">
              <!-- <div class="form-group">
            <label class="form-label">Tender Option</label>
            <select
                class="form-control form-control-sm"
                v-model="form.procedure_type" >
                      <option :value="0">Open Procedure</option>
                      <option :value="1">Restricted Procedure</option>
                </select>
          </div> -->

          
            </div>
            <div class="col-md-4" v-if="form.id && form.is_framework">
              <!-- <div class="form-group" v-if="form.category == 1">
              <label class="form-label">Recurring Interval (In months)</label>
              <select
                  class="form-control form-control-sm"
                  v-model="recurring_interval" >
                        <option :value="1">Every Month</option>
                        <option :value="3">Every 3 Months</option>
                        <option :value="6">Every 6 Months</option>
                        <option :value="12">Yearly</option>
                        <option :value="0">Custom</option>
                  </select>
            </div> -->
            </div>
            <div class="col-md-4" v-if="!recurring_interval" >
              <div class="form-group">
                <label class="form-label">Custom Interval in months</label><br>
                <el-input-number v-model="form.recurring_interval" controls-position="right" :min="1" step-strictly></el-input-number>
              </div>
            </div>
          </div>
          <br>

          <div v-if="isNew">
          <div class="form-group">
            <label>Main Description *</label>
            <div :class="{'has-error-field': v$.form.main_description.$errors.length  }">
              <QuillEditor theme="snow" v-model:content="form.main_description" contentType="html" />
            </div>
            <div v-if="v$.form.main_description.$errors.length" class="error-text">Description is required</div>
          </div>
          <div class="form-group">
            <label>Common Files Description *</label>
            <div :class="{ 'has-error-field': v$.form.common_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.common_description" contentType="html" />
            </div>
            <div v-if="v$.form.common_description.$errors.length" class="error-text">Common files description is required</div>
          </div>
          <div class="form-group">
            <label>My Files Description *</label>
            <div :class="{ 'has-error-field': v$.form.my_files_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.my_files_description" contentType="html" />
            </div>
            <div v-if="v$.form.my_files_description.$errors.length" class="error-text">My files description is required</div>
          </div>
          <div class="form-group">
            <label>Q&A Description *</label>
            <div :class="{ 'has-error-field': v$.form.qa_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.qa_description" contentType="html" />
            </div>
            <div v-if="v$.form.qa_description.$errors.length" class="error-text">Q&A description is required</div>
          </div>
          <div class="form-group">
            <label>My Questions Description *</label>
            <div :class="{ 'has-error-field': v$.form.my_questions_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.my_questions_description" contentType="html" />
            </div>
            <div v-if="v$.form.my_questions_description.$errors.length" class="error-text">My question description is required</div>
          </div>
          <div class="form-group">
            <label>Spec Description *</label>
            <div :class="{ 'has-error-field': v$.form.spec_description.$errors.length }">
              <QuillEditor theme="snow" v-model:content="form.spec_description" contentType="html" />
            </div>
            <div v-if="v$.form.spec_description.$errors.length" class="error-text">Spec description is required</div>
          </div>
        </div>

        <div style="text-align: center;" v-if="form.status == 'PEN'">
          <button type="button" class="btn btn-secondary" @click="handleEditAll">Edit All Details</button>
          <div>(Available for draft project only)</div>
        </div>
  
          <div class="pt-4 text-end form-buttons" v-if="form.id">
            <button type="reset" class="btn btn-secondary" :disabled="isSaving">Cancel</button>
            <button type="submit" class="btn btn-success" :disabled="isSaving">{{isSaving ? 'Saving...' : 'Save'}}</button>
          </div>
        </form>
      </div>
    </div>

    <el-dialog
      v-model="tenderModal" 
      title="Find a tender form"
      width="100%"
      @close="tenderModal = false"
    >
    <div>
      <!-- <tender-form :fat_procurement_type="fat_procurement_type" :procurement="selected" :key="fat_procurement_type" @close-dialog="handleCloseDialog"></tender-form> -->
      <tpp-tender-form :tpp_procurement_type="tpp_procurement_type" :procurement="selected" :key="tpp_procurement_type" @close-dialog="handleCloseDialog"></tpp-tender-form>
    </div>
  </el-dialog>

  <el-dialog id="justification-modal" :close-on-click-modal="false" v-model="justification_modal" width="60%">
    <div>
      Please provide a justification for skipping Preliminary Market Engagement Notice {{ (justify_type == 1 ? 'and Planned Procurement Notice' : '') }}!
      <br>
      <el-input autosize type="textarea" class="mt-1" v-model="justification" placeholder="Reason here..." />
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="justification_modal = false">Cancel</el-button>
        <el-button type="danger" @click="updateStatus()">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog :destroy-on-close="true" id="publish-modal" :close-on-click-modal="false" v-model="publish_modal" width="40%">
    <ProjectPublication
      :project="form"
      :selected="selected"
      @cancel="onCancel"
      @save="publishSave"
    ></ProjectPublication>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="publish_modal = false">Close</el-button>
      </div>
    </template>
  </el-dialog>
    
  <!-- <el-dialog id="project-form" v-model="showEditAllDetailsForm" width="60%" :destroy-on-close="true">
        <procurement-stepper :selected="form" @save="onCancel"></procurement-stepper>
    </el-dialog> -->

  </template>
  
  <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects';
    import { useVuelidate } from '@vuelidate/core'
    import { required, requiredIf } from '@vuelidate/validators'
    import { mapState } from 'vuex'
    import TenderForm from '@/components/pages/find_a_tender/TenderFormComponent'
    import TppTenderForm from '@/components/pages/fts_tpp/TPPTenderFormComponent'
    import Swal from 'sweetalert2';
    import ProjectPublication from './ProjectPublication.vue'
    // import ProcurementStepper from './ProcurementStepper.vue';

    export default {
      emits: ["save", "cancel"],
      setup () {
        return { v$: useVuelidate() }
      },
      props: {
        selected: {
            type: Object,
            required: false
        },
        pin_notice: {
            type: Object,
            required: false
        }
      },
      components: {
        TenderForm,
        TppTenderForm,
        ProjectPublication,
            // ProcurementStepper
      },
      data() {
        return {
          publish_modal: false,
          justification: null,
          justify_type: 0,
          justification_modal: false,
          step: 0,
          tenderModal: false,
          fat_procurement_type: null,
          tpp_procurement_type: null,
          enable_test_status: false,
          pin_modal: false,
          procurement_types: null,
          isSaving: false,
          recurring_interval : 1,
          form:{
            category: 0,
            recurring_interval: 1,
            type: null,
            name: null,
            description: null,
            main_description: null,
            common_description: null,
            my_files_description: null,
            qa_description: null,
            my_questions_description: null,
            spec_description: null
          },
          showEditAllDetailsForm: false
        }
      },
      validations() {
        return {
          form: {
            name: {
              required,
              $autoDirty: true
            },
            description: {
              required,
              $autoDirty: true
            },
            spec_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            my_questions_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            qa_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            my_files_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            main_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
            common_description: {
              required: requiredIf(function () {
                return (!this.isNew) ? false : true
              }),
              $autoDirty: true
            },
          },
        }
      },
      created: function () {
        this.form = Object.assign({}, this.selected)
        this.getProcurementProjectTypes()
        if (!this.form.id) {
          if (!this.form.recurring_interval) this.form.recurring_interval = 1
          else this.recurring_interval = this.form.recurring_interval
        }

        if (this.pin_notice) {
          this.form.main_description = this.pin_notice.main_description
          this.form.common_description = this.pin_notice.common_description
          this.form.my_files_description = this.pin_notice.my_files_description
          this.form.qa_description = this.pin_notice.qa_description
          this.form.my_questions_description = this.pin_notice.my_questions_description
          this.form.spec_description = this.pin_notice.spec_description
        }
      },
      watch: {
        selected() {
          this.form = Object.assign({}, this.selected)
          if (!this.form.recurring_interval) this.form.recurring_interval = 1
        },
        'form.main_description': function () {
          if (this.form.main_description == '<p><br></p>') this.form.main_description = null
        },
        'form.common_description': function () {
          if (this.form.common_description == '<p><br></p>') this.form.common_description = null
        },
        'form.my_files_description': function () {
          if (this.form.my_files_description == '<p><br></p>') this.form.my_files_description = null
        },
        'form.qa_description': function () {
          if (this.form.qa_description == '<p><br></p>') this.form.qa_description = null
        },
        'form.my_questions_description': function () {
          if (this.form.my_questions_description == '<p><br></p>') this.form.my_questions_description = null
        },
        'form.spec_description': function () {
          if (this.form.spec_description == '<p><br></p>') this.form.spec_description = null
        },
        form: {
          handler(newVal, oldVal) {
            if (this.isNew) {
              this.$emit('save', this.form)
              this.$emit('update:selected', this.form)
            }
          },
          deep: true
        }
      },
      computed: {
        title() {
          return this.isNew ? 'Add New' : `Manage Project`
        },
        isNew() {
          return !this.selected?.id
        },
        ...mapState(['ProcurementProjectStatuses', "FATNoticeTypes", 'TPPNoticeTypes', 'savedUser'])
      },
      methods: {
        publishSave(){
          this.publish_modal = false
          this.$emit('save', this.form)
        },
        openPublicationModal(type){
          this.publish_modal = true
        },
        updateStatus(){
          this.globalState.loading = true

            this.form.status = this.ProcurementProjectStatuses.CODE_PRE_PIN

              ProcurementProjects.update(this.selected.id, this.form)
              .then(result => {
                this.$toast.success('Successfully updated')
                this.$emit('save', this.form)
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
        },
        pmeClick(type){
          Swal.fire({
            title: 'Publish?',
            text: 'Publish Preliminary Market Engagement (PME) Notice?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
          }).then((result) => {
            if (result.isConfirmed) {
              this.handleOpenModal(type)
            }
            else{

              // activate without publish
              Swal.fire({
                title: 'Activate Without Publication?',
                text: 'Activate Preliminary Market Engagement (PME) Notice without publication?',
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
              }).then((result) => {
                if (result.isConfirmed) {
                  
                }
                else{
                  // skip stages and proceed  publishing tender notice
                  Swal.fire({
                    title: 'Skip Stage?',
                    text: 'Skip stages and proceed  publishing tender notice?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.justify_type = 1
                      this.justification_modal = true
                    }
                  })
                }
              })
            }
          })
        },
        setPrePINStatus(){
          Swal.fire({
            title: 'Initiate Preliminary Market Engagement Phase?',
            text: 'By clicking continue, you\'re updating this project into Preliminary Market Engagement phase. Do you want to proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true

              this.form.status = this.ProcurementProjectStatuses.CODE_PRE_PIN

              ProcurementProjects.update(this.selected.id, this.form)
              .then(result => {
                this.$toast.success('Successfully updated to PME Phase')
                this.$emit('save', this.form)
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
              
            }
          });
        },
        getProcurementProjectTypes(){
            ProcurementProjects.getProcurementProjectTypes()
            .then(resp => {
                this.procurement_types = resp.data.procurement_project_types
            })
            .catch(error => {
                this.$toast.error('An error occured while getting procurement project types')
                console.error('error:', error.response.data)
            })
        },
        handleEditAll(){
          this.$emit('fullEdit', this.form)
        },
        async onSubmit(evt) {
  
          evt.preventDefault()
  
          const result = await this.v$.$validate()
          if (!result) return

          if (this.isNew) {
            this.$emit('save', this.form)
          }
  
          this.isSaving = true
          const promise = this.isNew ? ProcurementProjects.create(this.form) : ProcurementProjects.update(this.selected.id, this.form)
          promise
            .then(resp => {
              
              this.$toast.success(resp.data.message || 'Procurement Project has been saved successfully')
              this.$emit('save', this.form)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isSaving = false
            })
        },
        onCancel() {
          this.$emit('cancel')
        },
        handleOpenModal(procurement_type) {
          this.tenderModal = true;
          this.tpp_procurement_type = procurement_type
          this.fat_procurement_type = procurement_type;
        },
        handleCloseDialog() {
          var self = this
          this.tenderModal = false;
          this.onCancel()
          setTimeout(() => {self.$emit("reload")}, 5000);
        }
      }
    }
  </script>
  