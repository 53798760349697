<style>
.el-segmented{
  padding: 0px !important;
}

.el-segmented__item{
  margin: 0px !important;
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border: 1px solid #dcdfe6
}


.el-segmented__item.is-selected{
  font-weight: bold !important;
}

</style>

<template>

  <el-card class="border-0">
    <h5>Permission Management</h5>

    <el-tabs v-model="main_tab" @tab-change="tabChange" type="border-card">
      <el-tab-pane name="group_tab" label="Group Permissions">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <h6>Group Permissions</h6>
                <div class="pb-0 mt-3">
                  <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <el-text size="large" class="mb-2">Company</el-text>
                                <el-select @change="loadGroups()" v-model="selected_company">
                                    <el-option v-for="company in company_selection"
                                        :label="company.name"
                                        :value="company.id"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="col-md-6">
                                <el-text class="mb-2">&nbsp;</el-text>
                                <el-input v-on:keyup.enter="loadGroups" v-model="search_value" placeholder="Search"
                                    class="input-with-select">
                                    <template #append>
                                    <el-button @click="loadGroups"><i class="fa fa-search"></i></el-button>
                                    </template>
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" style="text-align: right;">

                      <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" @click="loadGroups">
                        <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                      </button>
                      <button type="button" class="btn btn-primary btn-sm mb-0 me-2" @click="addGroup">
                        <i class="fa-solid fa-plus me-1"></i> Add
                      </button>
                    </div>
                  </div>
                  <div class="text-end">
                  </div>
                </div>
                <br>
                <div class="table-responsive" v-loading="loading">
                  <table class="table table-fluid align-items-center mb-0 table-hover ">
                    <thead>
                      <tr>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Name
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Description
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Created By
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Created Date
                        </th>

                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          No of Users
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!groups.length">
                        <td v-if="loading" colspan="100%" class="text-sm">
                          <i class="fas fa-cog fa-spin me-2"></i>Loading...
                        </td>
                        <td v-else colspan="100%">No data</td>
                      </tr>
                      <tr v-else class="text-sm" v-for="group_data in groups" :key="group_data.id">

                        <td>{{ group_data.name }}</td>
                        <td>{{ group_data.description }}</td>
                        <td>{{ group_data.created_by }}</td>
                        <td>{{ $filters.datetimeFormatFilter(group_data.created_at) }}</td>
                        <td>{{ group_data.user_count }}</td>
                        <td>
                          <el-button-group>
                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>Modules and Permissions</span>
                              </template>

                              <el-button v-allowed="'group_management,update'" type="success" size="small"
                                @click="loadPermissionsAndModules(group_data)">
                                <i class="fa fa-gear"></i></el-button>
                            </el-tooltip>
                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>Users</span>
                              </template>

                              <el-button v-allowed="'group_management,update'" type="info" size="small"
                                @click="loadUsers(group_data)">
                                <i class="fa fa-users"></i></el-button>
                            </el-tooltip>
                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>Edit</span>
                              </template>

                              <el-button v-allowed="'group_management,update'" type="primary" size="small"
                                @click="editGroup(group_data)">
                                <i class="fa fa-edit"></i></el-button>
                            </el-tooltip>

                            <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>Delete</span>
                              </template>

                              <el-button v-allowed="'group_management,delete'" type="danger" size="small"
                                @click="deleteGroup(group_data.id)">
                                <i class="fa fa-trash"></i></el-button>
                            </el-tooltip>
                          </el-button-group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer pt-0">
                <pagination :key="'group_permission_pagination'" :meta="meta" v-on:update="paginationChange" />
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane name="individual_tab" label="Individual Permissions">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <h6>Individual Permissions</h6>
                <div class="pb-0 mt-3">
                  <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <el-text size="large" class="mb-2">Company</el-text>
                                <el-select @change="loadIndividualUsers()" v-model="selected_company">
                                    <el-option v-for="company in company_selection"
                                        :label="company.name"
                                        :value="company.id"
                                    ></el-option>
                                </el-select>
                            </div>
                            <div class="col-md-6">
                                <el-text class="mb-2">&nbsp;</el-text>
                                <el-input v-on:keyup.enter="loadIndividualUsers" v-model="user_search_value" placeholder="Search"
                                    class="input-with-select">
                                    <template #append>
                                    <el-button @click="loadIndividualUsers"><i class="fa fa-search"></i></el-button>
                                    </template>
                                </el-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4" style="text-align: right;">

                      <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2" >
                        <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                      </button>
                    </div>
                  </div>
                  <div class="text-end">
                  </div>
                </div>
                <br>
                <div class="table-responsive">
                  <table class="table table-fluid align-items-center mb-0">
                    <thead>
                      <tr class="text-sm">
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Name
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Email
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Permission Status
                        </th>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!users_list.length">
                        <td v-if="loading" colspan="100%" class="text-sm">
                          <i class="fas fa-cog fa-spin me-2"></i>Loading...
                        </td>
                        <td v-else colspan="100%">No data</td>
                      </tr>
                      <tr class="text-sm" v-for="user_data in users_list" :key="'user_row_' + user_data.id">
                        <td>{{ user_data.name }}</td>
                        <td>{{ user_data.email }}</td>
                        <td>
                          <el-tag size="small" type="success" v-if="user_data?.group_permissions.length > 0" effect="dark">Has Permissions</el-tag>
                          <el-tag size="small" type="warning" v-else effect="dark">No Permissions</el-tag>
                        </td>
                        <td>
                        <el-button-group>
                          <el-tooltip :enterable="false" placement="top-start">
                              <template #content>
                                <span>Modules and Permissions</span>
                              </template>

                              <el-button v-allowed="'group_management,update'" type="success" size="small"
                                @click="loadPermissionsAndModules(user_data, user_data.id)">
                                <i class="fa fa-gear"></i></el-button>
                            </el-tooltip>
                            <el-tooltip v-if="user_data?.group_permissions.length > 0" :enterable="false" placement="top-start">
                              <template #content>
                                <span>Delete</span>
                              </template>

                              <el-button v-allowed="'group_management,delete'" type="danger" size="small"
                                @click="deleteIndividualPermissions(user_data.id)">
                                <i class="fa fa-trash"></i></el-button>
                            </el-tooltip>
                          </el-button-group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card-footer pt-0">
                <pagination :key="'user_permission_pagination'" :meta="meta" v-on:update="paginationChange" />
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-card>

  <el-dialog destroy-on-close id="add-modal" :close-on-click-modal="false" v-model="add_modal" width="60%">
    <GroupForm :selected_company="selected_company" :key="'group_' + selected.id" @save="afterSave" @cancel="handleClose" :selected="selected">
    </GroupForm>
  </el-dialog>

  <el-dialog :destroy-on-close="true" id="config-modal" :close-on-click-modal="false" v-model="config_modal" width="60%">
    <PermissionConfig
      :key="'group_config_' + permission_config_component_count"
      :modules_and_permissions_prop="modules_and_permissions"
      :selected_group_data="selected_config_group"
      :target_individual_user="selected_user_id"
      @save="afterConfigSave"
    ></PermissionConfig>
  </el-dialog>

  <el-dialog :destroy-on-close="true" id="users-modal" :close-on-click-modal="false" v-model="users_modal" width="80%">
    <GroupUsers
      :key="'users_' + users_component_count"
      :selected_group_data="selected_config_group"
      :company="selected_company"
      @save="afterUsersSave"
    ></GroupUsers>
  </el-dialog>

</template>
    
    <script>

    import GroupForm from './GroupForm.vue'
    import PermissionConfig from './PermissionConfig.vue'
    import GroupUsers from './GroupUsers.vue'
    import { Group } from '@/api_services/group';
    import Swal from 'sweetalert2';
    import { User } from '@/api_services/user'

    export default {
      computed: {
        user () {
          return this.$store.state.savedUser // get state
        }
      }, 
      components: {
        GroupForm, PermissionConfig, GroupUsers
      },
      data() {
        return {
            selected_user_id: null,
            main_tab: "group_tab",
            users_list: [],
            user_search_value: null,
            users_component_count: 1,
            users_modal: false,
            permission_config_component_count: 1,
            selected_config_group: null,
            modules_and_permissions: [],
            config_modal: false,
            selected_company: null,
            company_selection: null,
            search_value: "",
            add_modal: false,
            groups: [],
            meta: {
              page: 1,
              total: 0,
              pages: 1
            },
            selected: null,
            loading: false
        }
      },
      watch: {

      },
      created: function () {
        if(this.user?.companies){
            this.company_selection = this.user?.companies
            this.selected_company = this.company_selection[0].id
        }
        else{
            this.company_selection = [ this.user?.company ]
            this.selected_company = this.user?.company.id
        }

        this.loadGroups()
      },
      methods: {
        deleteIndividualPermissions(id) {

        Swal.fire({
          icon: 'info',
            text:
              'You are about to delete this individual permissions, are you sure you are going to proceed?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',  
        }).then(result => {
            if (result.isConfirmed) {

              this.globalState.loading = true
              Group.deleteIndividualPermissions({ id: id})
                .then(result => {
                  this.$toast.success(result?.data?.message || 'Individual permissions has been removed successfully')
                  this.loadIndividualUsers()
                  
                  this.handleClose()
                })
                .catch(error => {
                    this.$toast.error('An error occured while deleting permissions')
                    console.error('error:', error.response.data)

                }).finally(() => {
                  this.globalState.loading = false
                })
              
            }
        })
        },
        paginationChange(page){
          this.meta.page = page
          if(this.main_tab == "group_tab"){
            this.loadGroups()
          }
          else{
            this.loadIndividualUsers()
          }
        },
        tabChange(value){
          this.meta = { page: 1, total: 0, pages: 1 }
          if(value == "group_tab"){
            this.loadGroups()
          }
          else{
            this.loadIndividualUsers()
          }
        },
        getRoleNames(user) {
          var role_names = ''
          user.roles.forEach(function(role, i) {
            if (i > 0) role_names += ', '
            role_names += role.name
          })
          return role_names
        },
        loadIndividualUsers() {
          this.globalState.loading = true
          User.get({
            params: { term: this.user_search_value, status: 1, page: this.meta.page, company_id: this.selected_company }
          })
            .then(user_result => {
              if (user_result.data.users) this.users_list = user_result.data.users
              this.meta = user_result.data.meta
            })
            .catch(error => {
              this.$toast.error('An error occured while fetching users')
              console.error('error:', error)
            })
            .finally(() => {
              this.globalState.loading = false
            })
        },
        loadUsers(value){
          this.selected_config_group = value
          this.users_modal = true
        },
        afterUsersSave(){
          this.loadGroups()
          this.users_modal = false
        },
        afterConfigSave(){
          if(this.selected_user_id){
            this.loadIndividualUsers()
            this.selected_user_id = null
          }
          else{
            this.loadGroups()
          }
          this.config_modal = false
        },
        loadPermissionsAndModules(value, user_id = null){
          this.selected_user_id = null
          if(user_id){
            this.selected_user_id = user_id
          }
          console.log(value)

          this.modules_and_permissions = null
          this.globalState.loading = true
          
          Group.loadModulesAndPermissions({
              params: {}
            })
            .then(result => {
              if (result.data.data) this.modules_and_permissions = result.data.data
  
              this.selected_config_group = value
              
              this.permission_config_component_count++
              this.config_modal = true
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              this.globalState.loading = false
            })
        },
        deleteGroup(id) {

          Swal.fire({
            icon: 'info',
              text:
                'You are about to delete this group, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {

                this.globalState.loading = true
                Group.delete({ id: id})
                  .then(result => {
                    this.$toast.success(result?.data?.message || 'Group has been removed successfully')
                    this.loadGroups()
                    
                    this.handleClose()
                  })
                  .catch(error => {
                      this.$toast.error('An error occured while deleting group')
                      console.error('error:', error.response.data)

                  }).finally(() => {
                    this.globalState.loading = false
                  })
                
              }
          })
        },
        editGroup(group){
          this.selected = group
          this.add_modal = true
        },
        addGroup(){
          this.selected = { id: null, company_id: this.selected_company }
          this.add_modal = true
        },
        handleClose() {
          this.add_modal = false
        },
        afterSave() {
          this.loadGroups()
          this.add_modal = false
        },
        loadGroups(){
          this.globalState.loading = true
          this.handleClose()
          Group.get({
              params: {
                page: this.meta.page,
                search_value: this.search_value,
                selected_company: this.selected_company
              }
            })
            .then(result => {
              if (result.data.groups) this.groups = result.data.groups
              this.meta = result.data.meta
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      },
    }
    </script>
    