<template>
    <el-form label-position="top">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div>
                    <div>
                        <label class="large-label">Please select interval</label>
                        <div :class="{ 'has-error-field': v$.recurring_interval.$errors.length }">
                            <select class="form-control form-control-sm" v-model="form.recurring_interval">
                                <option :value="6">6 month</option>
                                <option :value="12">12 months</option>
                                <option :value="18">18 months</option>
                                <option :value="24">24 months</option>
                                <option :value="36">36 months</option>
                            </select>
                        </div>
                        <div v-if="v$.recurring_interval.$errors.length" class="error-text">Select an option</div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { reactive, ref, watch, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import dayjs from 'dayjs'

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    emits: ['update:form'],
    setup(props, { emit }) {

        const rules = computed(() => {
            return {
                recurring_interval: {
                    required,
                    minValue: minValue(0),
                    $autoDirty: true
                },
            }
        })

        const v$ = useVuelidate(rules, props.form)

        watch(() => props.form, (newVal) => emit('update:form', newVal), { deep: true })

        return { v$ }
    }
}
</script>

<style scoped></style>