<template>
    <el-form label-position="top">
      <div >
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <hr>
            <label class="large-label">
              In compliance with the Procurement Act 2023, after the initial three-year period,
               an open framework must be reopened at least once within the following five years to permit the inclusion of new suppliers.
                How many times are you planning to reopen within the next 5 years?
            </label>
            <div :class="{ 'has-error-field': v$.second_input.$error }">
                <el-input type="number" v-model="form.second_input" />
            </div>
            <div v-if="v$.second_input.$error" class="error-text">
                <div v-if="v$.second_input.required.$invalid">This field is required</div>
                <div v-if="v$.second_input.minValue.$invalid">Value must be greater than 0</div>
            </div>
            <br>
            </div>
        </div>
    
        <div v-if="form.second_input > 0">
            <div
            class="row"
            v-for="(dateItem, i) in form.second_input_dates"
            :key="i"
            >
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <label class="large-label"> {{ form.second_input_dates.length > 1 ? $helpers.getOrdenalSuffix(i+1) : '' }} Reopen Date</label>
                <div :class="{ 'has-error-field': v$.second_input_dates[i]?.date?.$error }">
                <el-date-picker
                    :key="'second-input-' + i + '-' + getSecondMinDate(i)?.getTime()"
                    v-model="form.second_input_dates[i].date"
                    type="date"
                    placeholder="Pick a date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    style="width: 100%"
                    :disabled-date="(date) => getSecondDisabledDate(date, i)"
                    :default-value="!form.second_input_dates[i].date ? getSecondMinDate(i) : null"
                    />
                </div>
                <div v-if="v$.second_input_dates[i]?.date?.$error" class="error-text">
                <div v-if="v$.second_input_dates[i].date.required.$invalid">Date is required</div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </el-form>
  </template>
  
  <script>
  import { reactive, ref, watch, computed } from 'vue'
  import useVuelidate from '@vuelidate/core'
  import { required, minValue } from '@vuelidate/validators'
  import dayjs from 'dayjs'

  export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    emits: ['update:form'],
    setup(props, { emit }) {

      const rules = computed(() => {
        const secondInputDates = props.form.second_input_dates || []

        const secondInputdateRules = secondInputDates.map(() => ({
            date: { required }
        }))
  
        return {
          second_input: {
            required,
            minValue: minValue(1),
            $autoDirty: true
          },
          second_input_dates: secondInputdateRules
        }
      })
  
      const v$ = useVuelidate(rules, props.form)
  
      watch(
        () => props.form.second_input,
        (newVal) => {
            props.form.second_input_dates.splice(0)
          if (newVal && newVal > 0) {
            for (let i = 0; i < newVal; i++) {
                props.form.second_input_dates.push({ date: null })
            }
          }
        }
      )

      watch(() => props.form, (newVal) => emit('update:form', newVal), { deep: true })
        const getSecondDisabledDate = (date, index) => {
            const today = dayjs()
            const threeYears = today.add(3, 'year')

            const startDateOfSecondDate = threeYears.add(1, 'day')
            const maxDate = startDateOfSecondDate.add(5, 'year')

            let min = startDateOfSecondDate
            if (index > 0 && props.form.second_input_dates[index - 1]?.date) {
                min = dayjs(props.form.second_input_dates[index - 1].date).add(1, 'day')
            }

            return date < min.toDate() || date > maxDate.toDate()
        }

        const getSecondMinDate = (index) => {
            const baseStartDate = dayjs().add(3, 'year').add(1, 'day')

            if (index === 0) {
                return baseStartDate.toDate()
            }

            const prevDate = props.form.second_input_dates[index - 1]?.date
            if (prevDate) {
                const parsedDate = dayjs(prevDate)
                return parsedDate.add(1, 'day').toDate()
            }

            return baseStartDate.toDate()
        }
  
      return { v$, getSecondDisabledDate, getSecondMinDate }
    }
  }
  </script>
  
  <style scoped>
  .has-error-field input {
    border-color: red !important;
  }
  .error-text {
    color: red;
    font-size: 0.9em;
  }
  </style>