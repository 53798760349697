
<style scoped>
    .thread_card >>> .el-card__header{
        background-color: rgb(209.4, 236.7, 195.9) !important;
    }
    .thread_card >>> .el-card__footer{
        background-color: whitesmoke !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
</style>
<template>
    <el-card shadow="never" class="thread_card mb-3 bg-white">
        <template #header>
            <div class="">
                <h4 class="mb-0">
                    <el-tag
                        class="me-1 "
                        type="success"
                        effect="dark"
                    >THREAD</el-tag>
                    {{ thread_data?.title }}</h4>
                <el-text size="small"><p class="mb-0 mt-2 text-sm"><i class="fa fa-user"></i> {{ thread_data?.user }}&nbsp; <i class="fa fa-clock"></i>&nbsp;
                    <el-tooltip
                        v-if="thread_data?.created_at"
                        class="box-item"
                        effect="dark"
                        :content="$filters.datetimeFormatFilter(thread_data?.created_at)"
                        placement="right-end"
                    >
                        <timeago class="text-sm" :autoUpdate="60" :datetime="thread_data?.created_at"/>
                    </el-tooltip>
                </p></el-text>
                <el-tag
                    v-if="thread_data.tag"
                    effect="dark"
                    class="mt-2 me-1"
                    v-for="tag in thread_data.tag"
                    :key="tag"
                    :disable-transitions="false"
                    size="default"
                    >
                    #{{ tag }}
                </el-tag>
            </div>
        </template>
        <div >
            <div class="mb-3">
                <el-text type="info" class="" size="large"><i class="fa fa-link"></i> This thread is related to:</el-text>
                <el-text type="primary" v-for="related_info in list_related_info"  class="ms-2" size="large">
                    <b>{{ related_info.model }}: <u>{{ related_info.main_display_col_name }}</u></b>
                </el-text>
            </div>
            <div class="mb-3" v-html="thread_data?.content"></div>
            <div v-if="thread_data.attachments">
                <hr class="m-0 mb-1">
                <el-text type="primary" class="ms-2" size="large"><i class="fa fa-file"></i> Thread Attatchments <i>(Click to view)</i></el-text>
                <ElUpload 
                    :from_reply="true"
                    :show_icon="false" :drag="false" v-model="thread_data.attachments"
                    :data="thread_data.attachments"></ElUpload>
            </div>

            <hr class="m-0">
            <div class="row">
                <div class="col-md-7 p-1">
                    <el-button link type="primary" @click="viewLikes(thread_data)" v-if="thread_data.total_likes > 0" class=" ms-2"> 
                        <i class="fa fa-thumbs-up me-1"></i>
                        {{ (thread_data?.user_liked_this_thread && thread_data?.user_liked_this_thread?.status == 1) ? "You" : thread_data?.latest_like }} 
                        {{ (thread_data.total_likes > 1) ? (" and " + (thread_data.total_likes - 1) ) + " other" + ( (thread_data.total_likes - 1) > 1 ? "s" : "" ) : "" }}</el-button>
                </div>
                <div class="col-md-5 ">
                    <el-button 
                        v-if="$store.state.checkUserGroupPermissions('forum-thread', 'like', true)"
                        @click="likeClick(thread_data)" 
                        size="small" 
                        class="float-end mt-2 me-1" 
                        :type="(thread_data?.user_liked_this_thread && thread_data?.user_liked_this_thread?.status == 1) ? 'primary' : ''">
                        <i :class="(thread_data?.user_liked_this_thread && thread_data?.user_liked_this_thread?.status == 1) ? 'fa fa-thumbs-up' : ''"></i>&nbsp;
                        {{ (thread_data?.user_liked_this_thread && thread_data?.user_liked_this_thread?.status == 1) ? 'Liked' : 'Like' }}
                    </el-button>
                </div>
            </div>
        </div>
        <template #footer>
            <el-row>
                <el-col :xs="24" :sm="24" :md="20" :lg="21" :xl="21">
                    <el-row class="row-bg">
                        <el-col :xs="11" :sm="7" :md="5" :lg="3" :xl="3">
                            <el-avatar shape="square" :size="100"> 
                                <b class="h1">{{ thread_data?.user?.substring(0, 1).toUpperCase() }}</b>
                             </el-avatar>
                        </el-col>
                        <el-col :xs="13" :sm="12" :md="19" :lg="21" :xl="21" >
                            <el-text size="large" class=" text-bolder" type="success"><i class="fa fa-user"></i> {{ thread_data?.user }}</el-text><br>
                            <el-tag type="success" effect="dark" class="mb-1"><i class="fa fa-gear"></i> {{ thread_data?.user_role }}</el-tag>
                            <p class="text-sm">Total Thread Posted: <b>{{ thread_data?.user_posted_thread_count }}</b></p>
                        </el-col>
                    </el-row>
                </el-col>
                <el-col :xs="24" :sm="24" :md="4" :lg="3" :xl="3">
                    
                </el-col>
            </el-row>
        </template>
    </el-card>
  </template>
  
  <script>
    import { Forum } from '@/api_services/forum'
    import ElUpload from '@/components/common/ElUpload'

    export default {
      props: {
        data: {
          type: Object,
          required: true,
          default: []
        }
      },
      components: {
        ElUpload
      },
      data() {
        return {
            list_related_info: [],
            thread_data: [],
            like_form: {
                like_id: null,
                like_type: 1,
                thread_id: null,
                reply_id: null,
                status: 1
            }
        }
      },
      created: function () {
        if(!this.$store.state.checkUserGroupPermissions('forum-thread', 'view', true)){
            window.location.href = "/"
        }

        if(this.data){
            this.thread_data = this.data
            this.like_form.like_id = this.thread_data?.user_liked_this_thread?.id
            this.getThreadRelatedInfo()
        }
      },
      watch: {
        data: {
          handler(value){
            if(value){
              this.thread_data = value
              this.like_form.like_id = this.thread_data?.user_liked_this_thread?.id
              this.getThreadRelatedInfo()
            }
          },
          deep:true
        },
      },
      computed: {
        
      },
      methods: {
        getThreadRelatedInfo(){
        this.globalState.loading = true
        this.isLoading = true
        
          if(this.thread_data.related_information && this.thread_data.related_information.length > 0){
            var selected_data = []
            this.thread_data.related_information.forEach(function(data) {
              var object = {}
              object["target_id"] = (data?.key?.id > 0 ? data?.key?.id : data?.target_id)
              object["model_id"] = data?.model_id

              selected_data.push(object)
            }) 
          }

        Forum.getThreadRelatedInformation({ params: {related_information: JSON.stringify(selected_data)} })
                .then(result => {
                    if(result.data.list_related_info)
                        this.list_related_info = result.data.list_related_info
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.globalState.loading = false
                    this.isLoading = false
                })
      },
        viewLikes(data){
            this.$emit("view_likes", { id: data.id, type: "thread" })
        },
        likeClick(){
            this.globalState.loading = true
            this.like_form.thread_id = this.thread_data.id

            if(this.thread_data?.user_liked_this_thread?.status){
                if(this.thread_data?.user_liked_this_thread?.status == 1)
                    this.like_form.status = 0
                else
                    this.like_form.status = 1
            }
            else{
                this.like_form.status = 1
            }
            
            Forum.updateLike(this.like_form)
            .then(result => {
                this.$toast.success(this.like_form.status ? "Liked!" : "Unliked")
                this.thread_data = result.data.returned_data
                this.like_form.like_id = this.thread_data?.user_liked_this_thread?.id
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
                this.globalState.loading = false
            })
        },
      }
    }
  </script>
  