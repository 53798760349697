<template>

<el-card shadow="never" class="mb-2">
  <div class="row">
    <div class="col-md-8">
      <p>{{ feature_item.name }}</p>
    </div>
    <div class="col-md-4">
      <el-checkbox :disabled="disable_check" class="float-end" @change="handleCheckAllChange" :indeterminate="isIndeterminate" v-model="select_all_check" label="Select All" />
    </div>
  </div>
                 <hr class="mt-0">
                <el-checkbox-group v-model="selected_module_features">
                  <el-checkbox :disabled="disable_check" border v-for="feature in JSON.parse(feature_item.features)" :label="feature.split('_').join(' ').toUpperCase()" :value="feature" />
                </el-checkbox-group>
              </el-card>
    
  </template>
  
  <script>

import { required } from '@vuelidate/validators';

    export default {
      emits: ["check_update"],
      props: {
        features_prop: {
          type: Object
        },
        is_restricted: {
          required: false,
          default: false
        }
      },
      components: {
        
      },
      data() {
        return {
          isIndeterminate: true,
          select_all_check: false,
          group_permissions_data: null,
          feature_item: null,
          selected_module_features: [],
          disable_check: false
        }
      },
      created: function () {
        this.disable_check = this.is_restricted
        this.feature_item = this.features_prop
        this.group_permissions_data = this.feature_item.list_group_permissions
        this.select_all_check = true
        if(!this.group_permissions_data){
            this.selected_module_features = JSON.parse(this.feature_item.features)
        }
        else if(this.feature_item.selected_permissions){
            this.selected_module_features = JSON.parse(this.feature_item.selected_permissions)
        }
        else{
          this.selected_module_features = JSON.parse(this.group_permissions_data.selected_permission_features)
          if(this.selected_module_features.length != JSON.parse(this.feature_item.features).length){
            this.select_all_check = false
          }
        }
      },
      watch: {
        'feature_item.selected_permissions': {
          handler(newValue) {
            if(newValue)
              this.selected_module_features = JSON.parse(newValue)
          },
          deep: true
        },
        features_prop: {
          handler(newValue) {
            this.feature_item = newValue
          },
          deep: true
        },
        selected_module_features: {
          handler(newValue) {
            const checkedCount = newValue?.length ?? 0
            const features = JSON.parse(this.feature_item.features)

            this.select_all_check = checkedCount === features.length
            this.isIndeterminate = checkedCount > 0 && checkedCount < features.length
            
            this.$emit("check_update", {feature_item_id: this.feature_item.id, module_id: this.feature_item.module_id, returned_features: newValue, selected_default_feature_count: features.length })
          },
          deep: true
        },
        is_restricted: {
          handler(newValue) {
            this.disable_check = newValue
          },
          deep: true
        },
      },
      computed: {
        
      },
      methods: {
        handleCheckAllChange(val){
          this.selected_module_features = val ? JSON.parse(this.feature_item.features) : []
          this.isIndeterminate = false
        }
      }
    }
  </script>
  