<template>
    <el-form label-position="top" @submit.prevent="handleSave" v-loading="loading">
        <div class="row">
            <div class="col-md-12" style="text-align: justify;">                
                <h5>
                    <p><b>Notice: </b>Our system has detected that certain required information is missing from your profile. To proceed with publishing any type of notice through our platform, please complete and submit the necessary details.</p>
                    <p>This step is in compliance with the <b>Find a Tender Service (FTS) requirement</b> and the <b>Central Digital Platform (CDP)</b> guidelines. Kindly note that this is a <b>one-time submission</b> and you will not be required to provide these details again for future publications.</p>
                    <p>Thank you for your cooperation.</p>
                </h5>
                <hr>
            </div>
            <div  class="col-md-3"></div>
            <div  class="col-md-6">
                <div class="form-group">
                    <label class="form-label">Procurement API key </label>
                    <input type="text" class="form-control form-control-sm" v-model.trim="form.procurement_api_key"  :class="{ 'has-error-field': v$.procurement_api_key.$errors.length }"/>
                    <div v-if="v$.procurement_api_key.$errors.length" class="error-text">Please provide a value</div>
                </div>


                <div class="form-group" >
                    <label class="form-label">Identifier</label>
                    <input type="text" class="form-control form-control-sm" v-model.trim="form.identifier" :class="{ 'has-error-field': v$.identifier.$errors.length }"/>
                    <div v-if="v$.identifier.$errors.length" class="error-text">Please provide a value</div>
                </div>

                <div class="form-group" >
                    <label class="form-label">Identification Register</label>
                    <el-select v-model="form.identification_register" :class="{ 'has-error-field': v$.identification_register.$errors.length }">
                    <el-option label="Companies House" :value="0"></el-option>
                    <el-option label="Charity Commission (England and Wales)" :value="1"></el-option>
                    <el-option label="Scottish Charity Register" :value="2"></el-option>
                    <el-option label="Charity Commission for Nothern Ireland" :value="3"></el-option>
                    <el-option label="NHS Organisation Data Service" :value="4"></el-option>
                    <el-option label="UK Register of Learning Providers (UKPRN number)" :value="5"></el-option>
                    <el-option label="Mutuals Public Register" :value="6"></el-option>
                    <el-option label="Guernsey Registry" :value="7"></el-option>
                    <el-option label="Jersey Financial Services Commission Registry" :value="8"></el-option>
                    <el-option label="Isle of Man Companies Registry" :value="9"></el-option>
                    <el-option label="Public Procurement Organisation Number" :value="10"></el-option>
                    </el-select>
                    <div v-if="v$.identification_register.$errors.length" class="error-text">Please provide a value</div>

                </div>


                <div style="text-align:center">
                    <button type="submit" class="btn btn-info">Save</button>
                    <button type="button" class="btn" @click="close()" style="margin-left: 10px">Close</button>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { reactive, ref, watch, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import dayjs from 'dayjs'
import { useToast } from 'vue-toast-notification'
import { Company } from '@/api_services/company';

export default {
    props: {
        form: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: false
        }
    },
    emits: ['save', 'close'],
    setup(props, { emit }) {
        const toast = useToast()
        const loading = ref(false)

        const rules = computed(() => {
            return {
                procurement_api_key: {
                    required,
                    $autoDirty: true
                },
                identifier: {
                    required,
                    $autoDirty: true
                },
                identification_register: {
                    required,
                    $autoDirty: true
                },
            }
        })

        const v$ = useVuelidate(rules, props.form)
        
        const handleSave = async () => {
            v$.value.$touch()

            if (v$.value.$invalid) {
                console.log('Form has validation errors')
                return
            }
            
            const payload = {
                procurement_api_key: props.form.procurement_api_key,
                identifier: props.form.identifier,
                identification_register: props.form.identification_register
            }

            // Optional success message
            console.log('Form submitted:', props.form)

            Company.update(props.form.id, payload)
            .then(resp => {
                toast.success('Procurement setting has been saved successfully')
                emit('save', payload)
            })
            .catch(error => {
                console.error('error:', error.response?.data || error)
            })
            .finally(() => {
                loading.value = false
            })
        }

        const close = () => {
            // You can handle a close action here or emit an event to the parent
            emit('close', true)
        }

        return { v$, handleSave, close }
    }
}
</script>

<style scoped>

</style>