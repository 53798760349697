import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'

//prod only
const firebaseConfigProd = {
  apiKey: 'AIzaSyB0K184-MnJWNW328DrioY4IXEYrJtsqIE',
  authDomain: 'costcomparison-25661.firebaseapp.com',
  projectId: 'costcomparison-25661',
  storageBucket: 'costcomparison-25661.appspot.com',
  messagingSenderId: '24757738118',
  appId: '1:24757738118:web:00503923f5e90081da8f07',
  measurementId: 'G-RV6QJVL4V4'
}

//staging
const firebaseConfig = {
  apiKey: "AIzaSyAlo4q1bYmmAaakXJhBQMC0Wd_iuMNvMqY",
  authDomain: "cpras-new-staging.firebaseapp.com",
  databaseURL: "https://cpras-new-staging-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "cpras-new-staging",
  storageBucket: "cpras-new-staging.firebasestorage.app",
  messagingSenderId: "288784175247",
  appId: "1:288784175247:web:704ea32ea05d06e84aa0fa",
  measurementId: "G-HZKSSDCMX1"
};

const firebaseApp = firebase.initializeApp(process.env.VUE_APP_MODE == 'production' ? firebaseConfigProd : firebaseConfig)

export const db = firebaseApp.database()