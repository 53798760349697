<template>
    <el-form label-position="top">
        <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4">
                <label class="large-label">
                    According to the Procurement Act 2023, an open framework must be reopened at least once within the first three years from its initial award to allow new suppliers to join.
                    How many times are you planning to reopen within the next 3 years?
                </label>
                <div :class="{ 'has-error-field': v$.first_input.$error }">
                    <el-input type="number" v-model="form.first_input" />
                </div>
                <div v-if="v$.first_input.$error" class="error-text">
                    <div v-if="v$.first_input.required.$invalid">This field is required</div>
                    <div v-if="v$.first_input.minValue.$invalid">Value must be greater than 0</div>
                </div>
                <br>
            </div>
        </div>

        <div v-if="form.first_input > 0">
            <div class="row" v-for="(dateItem, i) in form.first_input_dates" :key="i">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <label class="large-label"> {{ form.first_input_dates.length > 1 ? $helpers.getOrdenalSuffix(i + 1) :
                        '' }} Reopen Date</label>
                    <div :class="{ 'has-error-field': v$.first_input_dates[i]?.date?.$error }">
                        <el-date-picker v-model="form.first_input_dates[i].date" type="date" placeholder="Pick a date"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: 100%"
                            :disabled-date="(date) => getDisabledDate(date, i)" />

                    </div>
                    <div v-if="v$.first_input_dates[i]?.date?.$error" class="error-text">
                        <div v-if="v$.first_input_dates[i].date.required.$invalid">Date is required</div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { reactive, ref, watch, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import dayjs from 'dayjs'

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    emits: ['update:form'],
    setup(props, { emit }) {

        const rules = computed(() => {
            const firstInputDates = props.form.first_input_dates || []

            const firstInputdateRules = firstInputDates.map(() => ({
                date: { required }
            }))

            return {
                first_input: {
                    required,
                    minValue: minValue(1),
                    $autoDirty: true
                },
                first_input_dates: firstInputdateRules,
            }
        })

        const v$ = useVuelidate(rules, props.form)

        watch(
            () => props.form.first_input,
            (newVal) => {
                props.form.first_input_dates.splice(0)
                if (newVal && newVal > 0) {
                    for (let i = 0; i < newVal; i++) {
                        props.form.first_input_dates.push({ date: null })
                    }
                }
            }
        )

        watch(() => props.form, (newVal) => emit('update:form', newVal), { deep: true })


        const getDisabledDate = (date, index) => {
            const today = dayjs()
            const maxDate = today.add(3, 'year')

            let min = today
            if (index > 0 && props.form.first_input_dates[index - 1]?.date) {
                min = dayjs(props.form.first_input_dates[index - 1].date).add(1, 'day')
            }

            return date < min.toDate() || date > maxDate.toDate()
        }

        return { v$, getDisabledDate }
    }
}
</script>

<style scoped>
.has-error-field input {
    border-color: red !important;
}

.error-text {
    color: red;
    font-size: 0.9em;
}
</style>