<template>
    <loading-component :loading="loading" :message="loading_title_message"
    :content="loading_content_message"></loading-component>

    <el-steps :align-center="false"  finish-status="info" process-status="success" :active="active_step">
        <el-step :icon="publication_step.pme_publish.active == 1 ? Checked : CircleClose" v-if="publication_step.pme_publish.included == 1 && procurement_project.status == ProcurementProjectStatuses.CODE_PENDING" title="PME Publication">
        </el-step>
        <el-step :icon="publication_step.pme_without_publish.active == 1 ? Document : CircleClose" v-if="publication_step.pme_without_publish.included == 1 && procurement_project.status == ProcurementProjectStatuses.CODE_PENDING" title="Unpublish Activation">
        </el-step>
        <el-step :icon="publication_step.ppn_publish.active == 1 ? Checked : CircleClose" v-if="publication_step.ppn_publish.included == 1 " title="PPN Publication">
        </el-step>
        <el-step :icon="publication_step.ppn_without_publish.active == 1 ? Document : CircleClose" v-if="publication_step.ppn_without_publish.included == 1 " title="Unpublish Activation">
        </el-step>
        <el-step :icon="publication_step.skip_pme_ppn_stage.active == 1 ? Failed : CircleClose" v-if="publication_step.skip_pme_ppn_stage.included == 1" title="Skip">
        </el-step>
        <el-step :icon="publication_step.tn_publish.active == 1 ? Checked : CircleClose" v-if="publication_step.tn_publish.included == 1 && procurement_project.status == ProcurementProjectStatuses.CODE_ITT_PENDING" title="Tender Notice Publication">
        </el-step>
        <el-step :icon="publication_step.contract_award_publish.active == 1 ? Checked : CircleClose" v-if="publication_step.contract_award_publish.included == 1 && procurement_project.status == ProcurementProjectStatuses.CODE_MARKING_STAGE" title="Contract Award Notice Publication">
        </el-step>
        <el-step :icon="publication_step.contract_details_publish.active == 1 ? Checked : CircleClose" v-if="publication_step.contract_details_publish.included == 1 && procurement_project.status == ProcurementProjectStatuses.CODE_AWARD_STANDSTILL" title="Contract Details Notice Publication">
        </el-step>
    </el-steps>
    <el-divider class="mt-1"></el-divider>
    <div v-if="publication_step.pme_publish.active == 1 && procurement_project.status == ProcurementProjectStatuses.CODE_PENDING">
        <el-text size="large">Publish Preliminary Market Engagement (PME) Notice?</el-text>
        <br>
        <el-button-group>
          <el-button link @click="publishPME" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes, Publish</el-button><br>
          <el-button link @click="stepButtonClick(0, 'go')" class="mt-3" type="warning"><i class="fa fa-fast-forward"></i>&nbsp;Activate PME Without Publication</el-button>
        </el-button-group>
        
    </div>

    <div v-if="publication_step.pme_without_publish.active == 1">
        <el-text size="large">Activate Preliminary Market Engagement (PME) Without Publication?</el-text>
        <br>
        <el-button-group>
          <el-button link @click="showActivateWithoutPublish('pme')" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes, Activate</el-button><br>
          <el-button link @click="stepButtonClick(3, 'go', 'ppn')" class="mt-3" type="warning"><i class="fa fa-fast-forward"></i>&nbsp;No, Skip PME and Proceed to PPN</el-button><br>
          <el-button link @click="stepButtonClick(1, 'go')" class="mt-3" type="danger"><i class="fa fa-times"></i>&nbsp;No, Skip Stages and Proceed to Tender Notice</el-button>
          
        </el-button-group>

        <el-button link @click="stepButtonClick(1, 'back')" class="mt-3 float-end" type="warning"><i class="fa fa-arrow-left"></i>&nbsp;Back to PME Publish</el-button>
    </div>

    <div v-if="publication_step.ppn_publish.active == 1">
        <el-text size="large">Publish Planned Procurement Notice (PPN) Notice?</el-text>
        <br>
        <el-button-group>
          <el-button link @click="publishPPN" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes, Publish</el-button><br>
          <el-button link @click="stepButtonClick(2, 'go')" class="mt-3" type="warning"><i class="fa fa-fast-forward"></i>&nbsp;Activate PPN Without Publication</el-button>
        </el-button-group>
        <el-button v-if="procurement_project.status == ProcurementProjectStatuses.CODE_PENDING" link @click="stepButtonClick(3, 'back', 'pme')" class="mt-3 float-end" type="info"><i class="fa fa-arrow-left"></i>&nbsp;Back to PME Publish</el-button>
    </div>

    <div v-if="publication_step.ppn_without_publish.active == 1">
        <el-text size="large">Activate Planned Procurement Notice (PPN) Without Publication?</el-text>
        <br>
        <div class="row">
          <div class="col-md-6">
            <el-button link @click="showActivateWithoutPublish('ppn')" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes, Activate</el-button>
          </div>
          <div class="col-md-6">
            <el-button link @click="stepButtonClick(3, 'back')" class="mt-3 float-end" type="warning"><i class="fa fa-arrow-left"></i>&nbsp;Back to PPN Publish</el-button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <el-button link @click="stepButtonClick(3, 'go')" class="mt-3" type="danger"><i class="fa fa-times"></i>&nbsp;No and Proceed to Tender Notice</el-button>
          </div>
          <div class="col-md-6">
            <el-button v-if="procurement_project.status == ProcurementProjectStatuses.CODE_PENDING" link @click="stepButtonClick(3, 'back', 'pme')" class="mt-3 float-end" type="info"><i class="fa fa-arrow-left"></i>&nbsp;Back to PME Publish</el-button>
          </div>
        </div>
    </div>

    <div v-if="publication_step.skip_pme_ppn_stage.active == 1">
        <el-text size="large">Skip {{ (procurement_project.skip_pme == 1 && procurement_project.skip_ppn == 1 ? 'PME & PPN' : 'PPN') }} and Proceed to Tender Notice?</el-text>
        <br>
        <br>
        <el-text>Please provide justification on skipping stages.</el-text>
        <el-input v-model="procurement_project.both_skip_justification" type="textarea"></el-input>
        <div class="form-group">
            <label class="form-label">Attatchments (Optional)</label>
            <ElUpload 
            :quick_remove="true"
            v-model="activate_without_publish_files"
            :data="activate_without_publish_files"
            @uploadChange="updateAttachmentsValue"></ElUpload>

          </div>
        <el-button-group>
          <el-button link @click="skipStages()" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes, Skip and Proceed to Tender Notice</el-button><br>
          <el-button link @click="stepButtonClick(4, 'back')" class="mt-3" type="warning"><i class="fa fa-arrow-left"></i>&nbsp;Back to {{ previous_step == 2 ? 'PME' : 'PPN' }} Publish</el-button>
        </el-button-group>
    </div>

    <div v-if="publication_step.tn_publish.active == 1">
        <br>

        <el-steps :active="tn_stepper">
          <el-step title="Awardee Limit" description="You can setup awardee limit on each LOT" />
          <el-step v-if="(
              (procurement_project.project_type == 0 && procurement_project.standard_type == 1) ||
              (procurement_project.project_type == 1 && procurement_project.standard_type == 1)
          )
        " title="Sub-Stages" description="Mandatory setting up Sub-Stages for Tender Notice" />
          <el-step title="Finalize Publication" description="Review changes and proceed" />
        </el-steps>
        
        <div v-if="tn_stepper == 1">
          <br>
          <el-text 
            size="large"><i class="fas fa-info-circle"></i> Before you can publish the Tender Notice, you 
            have an option to set the awardee limit for each lot or you can skip these setup. After changing the limit,
          you must click the "Update" button otherwise, it wont be save.</el-text><br>

            <AwardeeLotSettings
              :pin_notice="procurement_project?.pin_notice"
            ></AwardeeLotSettings>

          <el-button @click="tn_stepper = 2" class="mt-3" type="primary"><i class="fa fa-arrow-right"></i>&nbsp;Next</el-button>
        </div>
        <div v-if="
          tn_stepper == 2 && (
              (procurement_project.project_type == 0 && procurement_project.standard_type == 1) ||
              (procurement_project.project_type == 1 && procurement_project.standard_type == 1)
          )
        ">
          <br>
          <el-text 
            size=""><i class="fas fa-info-circle"></i> Before you can publish the Tender Notice, you must first add atleast one (1) "Sub Stage". After you successfully published the Tender Notice, you cannot add or delete any stage.</el-text>
            <TenderNoticeSubStages 
            v-if="
            (procurement_project.project_type == 0 && procurement_project.standard_type == 1) ||
            (procurement_project.project_type == 1 && procurement_project.standard_type == 1)
            "
            :if_at_tn_publish="1" @save="stageSaveUpdate" :title="null" :tender_notice_id="procurement_project?.tender_notice_id"></TenderNoticeSubStages>

            <el-button-group>
              <el-button @click="tn_stepper = 1" class="mt-3" type="warning"><i class="fa fa-arrow-left"></i>&nbsp;Back</el-button>
              <el-button @click="tn_stepper = 3" class="mt-3" type="primary"><i class="fa fa-arrow-right"></i>&nbsp;Next</el-button>
            </el-button-group>
            
          </div>
        <div v-if="(tn_stepper == 2 && !((procurement_project.project_type == 0 && procurement_project.standard_type == 1) ||
            (procurement_project.project_type == 1 && procurement_project.standard_type == 1))) || tn_stepper == 3">
          <br>
          <el-text size="large" type="warning" v-if="disable_tn_publish"><i class="fa fa-info-circle"></i>Please add atleast one (1) Sub-Stage before publishing this Tender Notice.</el-text>
          <el-text size="large" type="primary" v-else><i class="fa fa-info-circle"></i>Please review changes and click "Yes, Publish Tender Notice" button below to proceed.</el-text>
          <br>
          <el-button-group>
            <el-button @click="
              tn_stepper = (
                ((procurement_project.project_type == 0 && procurement_project.standard_type == 1) ||
                (procurement_project.project_type == 1 && procurement_project.standard_type == 1)) ? 2 : 1
              )
              " class="mt-3" type="warning"><i class="fa fa-arrow-left"></i>&nbsp;Back</el-button>
            <el-button v-if="!disable_tn_publish" @click="publishTN" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes, Publish Tender Notice</el-button>
          </el-button-group>
        </div>
    </div>

    <div v-if="publication_step.contract_award_publish.active == 1">
        <el-text size="large">Publish Contract Award Notice?</el-text><br>
        <el-text><i class="fa fa-info-o"></i>You can update the awardees for this Tender Notice before publishing by clicking "View/Update Awardees".</el-text>
        <br>
        <el-button-group>
          <el-button @click="publishCAN" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes</el-button>
          <el-button @click="viewAwardees" class="mt-3" type="warning"><i class="fa fa-edit"></i>&nbsp;View/Update Awardees</el-button>
        </el-button-group>
        <br>
        <br>
        <el-button-group>
          <el-button link @click="customiseMail('GET-16')" class="mt-3" type="success"><i class="fa fa-envelope"></i>&nbsp;Update Procurement Awardee Email Content</el-button><br>
          <el-button link @click="customiseMail('GET-17')" class="mt-3" type="danger"><i class="fa fa-envelope"></i>&nbsp;Update Procurement Non-Awardee Email Content</el-button>
        </el-button-group>
    </div>

    <div v-if="publication_step.contract_details_publish.active == 1">
        <el-text size="large">Publish Contract Details Notice?</el-text>
        <br>
        <el-button-group>
          <el-button @click="publishCDN" class="mt-3" type="success"><i class="fa fa-check"></i>&nbsp;Yes</el-button>
          <el-button @click="customiseMail('GET-48')" class="mt-3" type="primary"><i class="fa fa-envelope"></i>&nbsp;Update Email Content</el-button>
        </el-button-group>
    </div>

    <el-dialog
      v-model="tenderModal" 
      title="Find a tender form"
      width="100%"
      @close="tenderModal = false"
    >
    <div>
      <tpp-tender-form :tpp_procurement_type="tpp_procurement_type" :procurement="procurement_project" :key="tpp_procurement_type" @close-dialog="handleCloseDialog" @load-projects="loadProcurementProjects"></tpp-tender-form>
    </div>
  </el-dialog>

  <el-dialog
      v-model="activate_without_publish_modal" 
      title=""
      width="50%"
      @close="activate_without_publish_modal = false"
    >
    <el-text size="large" type="primary">{{ activate_without_publish_title }}</el-text><br>
    <el-text >{{ activate_without_publish_text }}</el-text>
    <br>
    <br>
    <template v-if="procurement_project.threshold == 0">

      <div class="form-group">
        <label class="form-label">Justification</label>
      <el-input
        v-model="activate_without_publish_justification"
        maxlength="1500"
        placeholder="Please provide justification..."
        show-word-limit
        type="textarea"
      />
    </div>
      <div class="form-group">
            <label class="form-label">Attatchments (Optional)</label>
            <ElUpload 
            :quick_remove="true"
            v-model="activate_without_publish_files"
            :data="activate_without_publish_files"
            @uploadChange="updateAttachmentsValue"></ElUpload>

          </div>

    </template>
    <template #footer>
        <div class="dialog-footer">
          <el-button @click="activate_without_publish_modal = false">Cancel</el-button>
          <el-button type="primary" @click="activateWithoutPublish">
            Yes, Proceed
          </el-button>
        </div>
      </template>
  </el-dialog>

  <el-drawer v-model="email_template_drawer" :show-close="true" size="90%" direction="rtl"
        :append-to-body="true" :destroy-on-close="true">
        <GlobalEmailTemplateList
          :target_code="target_code"
        ></GlobalEmailTemplateList>
    </el-drawer>
  
</template>
  
  <script>
  import { h, shallowRef } from 'vue';
  import { Document, Checked, Failed, CircleClose } from '@element-plus/icons-vue'
  import TppTenderForm from '@/components/pages/fts_tpp/TPPTenderFormComponent'
  import { mapState } from 'vuex'
  import { ProcurementProjects } from '@/api_services/procurement_projects';
  import Swal from 'sweetalert2';
  import LoadingComponent from '@/components/common/LoadingComponent.vue'
  import { IttFramework } from '@/api_services/itt_framework'
  import TenderNoticeSubStages from '../itt_frameworks/TenderNoticeSubStages.vue'
  import ElUpload from '@/components/common/ElUpload'
  import AwardeeLotSettings from './AwardeeLotSettings.vue'
  import GlobalEmailTemplateList from '../customisable_email/GlobalEmailTemplateList.vue'
  
  export default {
    computed: {
      ...mapState(['TPPNoticeTypes', 'ProcurementProjectStatuses'])
    },
    components: {
        TppTenderForm,
        LoadingComponent,
        TenderNoticeSubStages,
        ElUpload,
        AwardeeLotSettings,
        GlobalEmailTemplateList
    },
    emits :{
        
    },
    props: ['project'],
    data() {
      return {
        email_template_drawer: false,
        target_code: null,
        tn_stepper: 1,
        activate_without_publish_files: null,
        activate_without_publish_type: null,
        activate_without_publish_justification: null,
        activate_without_publish_title: null,
        activate_without_publish_text: null,
        activate_without_publish_modal: false,
        disable_tn_publish: false,
        loading_title_message: null,
        loading_content_message: null,
        itt_form: {
          name: null,
          main_description: null,
          common_description: null,
          my_files_description: null,
          qa_description: null,
          my_questions_description: null,
          spec_description: null,
          status: 1,
          pin_notice_id: null,
          procurement_project_id: null,
          if_pin_notice: 1
        },
        tpp_procurement_type: null,
        tenderModal: false,
        previous_step: 0,
        CircleClose: shallowRef(CircleClose),
        Document: shallowRef(Document),
        Checked: shallowRef(Checked),
        Failed: shallowRef(Failed),
        active_step: 0,
        procurement_project: null,
        publication_step:{
          pme_publish: {
            included: 0,
            active: 0
          },
          pme_without_publish: {
            included: 0,
            active: 0
          },
          ppn_publish: {
            included: 0,
            active: 0
          },
          ppn_without_publish: {
            included: 0,
            active: 0
          },
          skip_pme_ppn_stage: {
            included: 0,
            active: 0
          },
          tn_publish: {
            included: 0,
            active: 0
          },
          contract_award_publish: {
            included: 0,
            active: 0
          },
          contract_details_publish: {
            included: 0,
            active: 0
          }
        }
      }
    },
    watch: {
      project:{
        deep: true,
        handler(newValue) {
          
        }
      }
    },
    created: function() {
      
      this.procurement_project = this.project
      
      this.checkIfHasProjectData()
      this.setStep()
      if(
        (this.procurement_project.project_type == 0 && this.procurement_project.standard_type == 1) ||
        (this.procurement_project.project_type == 1 && this.procurement_project.standard_type == 1)
      ){

        if(this.procurement_project?.on_going_sub_stages || this.procurement_project?.pending_sub_stages){
          if(this.procurement_project?.on_going_sub_stages.length == 0 || this.procurement_project?.pending_sub_stages.length == 0){
            this.disable_tn_publish = true
          }
        }

      }
      else{
        this.disable_tn_publish = false
      }
      
    },
    methods: {
      customiseMail(template_code){
        this.target_code = template_code
        this.email_template_drawer = true
      },
      viewAwardees(){
        this.$emit('viewAwardees', this.procurement_project)
      },
      loadProcurementProjects() {
        this.$emit('load-projects')
      },
      updateAttachmentsValue(files){
        this.activate_without_publish_files = files
      },
      checkIfHasProjectData(){
        this.globalState.loading = true
        ProcurementProjects.checkIfProjectHasData(this.procurement_project.id, {})
        .then(resp => {
          let lot_count = resp.data.lot_count
          let spec_count = resp.data.spec_count
          let social_val_count = resp.data.social_val_count
          
          if(lot_count == 0 || spec_count == 0 || social_val_count == 0){
            this.$toast.warning('Please setup this project\'s Framework Lots, Specifications, and Social Values.')
            this.$emit('cancel')
          }
        })
        .catch(error => {
          console.error('error:', error.response.data)
        })
        .finally(() => {
          this.globalState.loading = false
        })
      },
      stageSaveUpdate(){
        this.disable_tn_publish = false
      },
      skipStages(){
        if(!this.procurement_project.both_skip_justification){
          this.$toast.warning('Please provide justification for skipping!')
          return false
        }

        Swal.fire({
            title: 'Skip and Proceed to Tender Notice?',
            text: 'Skip ' + (this.procurement_project.skip_pme == 1 && this.procurement_project.skip_ppn == 1 ? 'PME & PPN' : 'PPN')  + ' and proceed to Tender Notice?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true
              let formData = new FormData()

              // directly called this initializing method since key variables (this.procurement_project.skip_pme, 
              // this.procurement_project.skip_ppn, this.procurement_project.both_skip_justification)
              // are already set its value and directly passed into ProcurementProjects.update

              // please change this into tender notice api publication and update ProcurementProjects with
              // those aforementioned variables
              this.procurement_project.activate_without_publish_type = "both"
              if(this.activate_without_publish_files && this.activate_without_publish_files.length > 0){
                if(typeof this.activate_without_publish_files != "object"){
                  this.activate_without_publish_files = JSON.parse(this.activate_without_publish_files)
                }
                this.activate_without_publish_files.forEach(function(file) {
                  if(file.raw){
                    formData.append('files[]', file.raw)
                  }
                }) 
              }

              for ( var key in this.procurement_project ) {
                if(typeof this.procurement_project[key] === 'boolean' || this.procurement_project[key] instanceof Boolean){
                  this.procurement_project[key] = this.procurement_project[key] ? 1 : 0; 
                }

                if(this.procurement_project[key] !== null)
                  formData.append(key, this.procurement_project[key]);
              }

              ProcurementProjects.updateWithFiles(this.procurement_project.id, formData)
              .then(result => {
                
                this.loading_title_message = "Initiating Tender Notice"
                this.loading_content_message = "Please wait......."
                this.globalState.loading = true

                this.itt_form.name = this.procurement_project.name
                this.itt_form.main_description = this.procurement_project?.pin_notice.main_description
                this.itt_form.common_description = this.procurement_project?.pin_notice.common_description
                this.itt_form.my_files_description = this.procurement_project?.pin_notice.my_files_description
                this.itt_form.qa_description = this.procurement_project?.pin_notice.qa_description
                this.itt_form.my_questions_description = this.procurement_project?.pin_notice.my_questions_description
                this.itt_form.spec_description = this.procurement_project?.pin_notice.spec_description
                this.itt_form.pin_notice_id = this.procurement_project?.pin_notice.id
                this.itt_form.procurement_project_id = this.procurement_project.id

                IttFramework.create(this.itt_form)
                  .then(result => {
                    this.$toast.success('New Tender Notice has been created and currently pending')
                    this.$router.push({
                      path: '/main/tender_notices'
                    })
                  })
                  .catch(error => {
                    console.log(error)
                  })
                  .finally(() => {
                    this.globalState.loading = false
                  })

              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                
              })

            }
          })
      },
      showActivateWithoutPublish(type){
        this.activate_without_publish_type = type
        this.activate_without_publish_files = null
        this.activate_without_publish_justification = null
        this.activate_without_publish_title = 'Activate ' + type.toUpperCase() + " without publishing?"
        this.activate_without_publish_text = 'You are going to activate ' + type.toUpperCase() + " without publishing this stage. Are you sure?"
        this.activate_without_publish_modal = true
      },
      async activateWithoutPublish(){
        if(!this.activate_without_publish_justification && this.procurement_project.threshold == 0){
          this.$toast.warning("You need to write justification!")
          return false
        }

        let justification = await Swal.fire({
          title: 'Activate ' + this.activate_without_publish_type.toUpperCase() + " without publishing?",
          text: 'You are going to activate ' + this.activate_without_publish_type.toUpperCase() + " without publishing this stage. Are you sure?",
          confirmButtonText: 'Yes, I am sure!',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#d33',
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            let formData = new FormData()
            
            //if (justification) {
              //this.globalState.loading = true

              this.procurement_project.status = (this.activate_without_publish_type == 'pme' ? this.ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED : this.ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED)
              
              if(this.activate_without_publish_type == 'pme'){
                this.procurement_project.pme_not_published = 1
                if(this.procurement_project.threshold == 0)
                  this.procurement_project.pme_skip_justification = this.activate_without_publish_justification
              }

              if(this.activate_without_publish_type == 'ppn'){
                this.procurement_project.ppn_not_published = 1
                if(this.procurement_project.threshold == 0)
                  this.procurement_project.ppn_skip_justification = this.activate_without_publish_justification
              }

              if(this.activate_without_publish_files && this.activate_without_publish_files.length > 0){
                if(typeof this.activate_without_publish_files != "object"){
                  this.activate_without_publish_files = JSON.parse(this.activate_without_publish_files)
                }
                this.activate_without_publish_files.forEach(function(file) {
                  if(file.raw){
                    formData.append('files[]', file.raw)
                  }
                }) 
              }

              this.procurement_project.activate_without_publish_type = this.activate_without_publish_type

              for ( var key in this.procurement_project ) {
                if(typeof this.procurement_project[key] === 'boolean' || this.procurement_project[key] instanceof Boolean){
                  this.procurement_project[key] = this.procurement_project[key] ? 1 : 0; 
                }

                if(this.procurement_project[key] !== null)
                  formData.append(key, this.procurement_project[key]);
              }

              ProcurementProjects.updateWithFiles(this.procurement_project.id, formData)
              .then(result => {
                this.$toast.success('Successfully activated ' + this.activate_without_publish_type.toUpperCase())
                this.$emit('save', this.procurement_project)
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
            //}
          }
        });

      },
      setStatusManually(status){
        // remove this function and change into api publication
        Swal.fire({
            title: 'Set status manually?',
            text: 'Status: ' + status,
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true

              this.procurement_project.status = status

              ProcurementProjects.update(this.procurement_project.id, this.procurement_project)
              .then(result => {
                this.$toast.success('Successfully updated status')
                this.$emit('save', this.procurement_project)
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
              
            }
          });
      },
        onCancel() {
          this.$emit('cancel')
        },
        handleCloseDialog() {
          var self = this
          this.tenderModal = false;
          this.onCancel()
          setTimeout(() => {self.$emit("reload")}, 5000);
        },
        publishTN(){
          this.setStatusManually(this.ProcurementProjectStatuses.CODE_ITT)
        },
        publishCAN(){
          this.setStatusManually(this.ProcurementProjectStatuses.CODE_AWARD_STANDSTILL)
        },
        publishCDN(){
          this.setStatusManually(this.ProcurementProjectStatuses.CODE_TN_ARCHIVED)
        },
        publishPME(){
          this.tpp_procurement_type = this.TPPNoticeTypes.UK2.value
          this.tenderModal = true

          // this.setStatusManually(this.ProcurementProjectStatuses.CODE_PRE_PIN)
        },
        publishPPN(){
          this.tpp_procurement_type = this.TPPNoticeTypes.UK3.value
          this.tenderModal = true

          // this.setStatusManually(this.ProcurementProjectStatuses.CODE_ACTIVE)
        },
        setActive(type){
          this.publication_step.pme_publish.active = 0
          this.publication_step.pme_without_publish.active = 0
          this.publication_step.ppn_publish.active = 0
          this.publication_step.ppn_without_publish.active = 0
          this.publication_step.skip_pme_ppn_stage.active = 0
          this.publication_step.tn_publish.active = 0
          this.publication_step.contract_award_publish.active = 0
          this.publication_step.contract_details_publish.active = 0

          this.publication_step[type].active = 1
        },
        stepButtonClick(step, type, destination = ""){
          if(step == 0){
            this.publication_step.pme_publish.included = 1
            this.publication_step.pme_without_publish.included = 1
            this.active_step = 1
            this.previous_step = 0

            this.setActive("pme_without_publish")
          }
          if(step == 1 && type == "go"){
            this.publication_step.pme_publish.included = 1
            this.publication_step.pme_without_publish.included = 1
            this.publication_step.skip_pme_ppn_stage.included = 1
            this.active_step = 2
            this.previous_step = 1

            this.procurement_project.skip_pme = 1
            this.procurement_project.skip_ppn = 1
            this.setActive("skip_pme_ppn_stage")
          }
          if(step == 2){
            this.publication_step.ppn_publish.included = 1
            this.publication_step.ppn_without_publish.included = 1
            this.active_step = 1
            this.previous_step = 0

            this.setActive("ppn_without_publish")
          }
          if(step == 3 && type == "go"){
            if(destination == "ppn"){
              this.publication_step.pme_publish.included = 0
              this.publication_step.pme_without_publish.included = 0

              this.publication_step.ppn_publish.included = 1
              this.publication_step.ppn_without_publish.included = 1
              this.publication_step.skip_pme_ppn_stage.included = 1
              this.active_step = 0
              this.previous_step = 0

              this.procurement_project.skip_pme = 1
              
              this.setActive("ppn_publish")
            }
            else{
              this.publication_step.ppn_publish.included = 1
              this.publication_step.ppn_without_publish.included = 1
              this.publication_step.skip_pme_ppn_stage.included = 1
              this.active_step = 2
              this.previous_step = 3

              this.procurement_project.skip_ppn = 1
              
              this.setActive("skip_pme_ppn_stage")
            }
            
          }
          if(step == 1 && type == "back"){
            this.publication_step.pme_publish.included = 1
            this.publication_step.pme_without_publish.included = 0
            this.active_step = 0
            this.previous_step = 0

            this.procurement_project.skip_pme = 0

            this.setActive("pme_publish")
          }
          if(step == 3 && type == "back"){
            if(destination == "pme"){
              this.publication_step.pme_publish.included = 1
              this.publication_step.pme_without_publish.included = 1

              this.publication_step.ppn_publish.included = 0
              this.publication_step.ppn_without_publish.included = 0
              this.publication_step.skip_pme_ppn_stage.included = 1
              this.active_step = 0
              this.previous_step = 0

              this.procurement_project.skip_pme = 0
              
              this.setActive("pme_publish")
            }
            else{
              this.publication_step.ppn_publish.included = 1
              this.publication_step.ppn_without_publish.included = 0
              this.active_step = 0
              this.previous_step = 0

              this.procurement_project.skip_ppn = 0

              this.setActive("ppn_publish")
            }
            
          }
          if(step == 4 && type == "back"){
            if(this.previous_step == 1){
              this.publication_step.skip_pme_ppn_stage.included = 0
              this.publication_step.pme_without_publish.included = 0
              this.publication_step.pme_publish.included = 1
              
              this.active_step = 0
              this.previous_step = 0

              this.procurement_project.skip_pme = 0
              this.procurement_project.skip_ppn = 0
              this.setActive("pme_publish")
            }

            if(this.previous_step == 3){
              this.publication_step.skip_pme_ppn_stage.included = 0
              this.publication_step.ppn_without_publish.included = 0
              this.publication_step.ppn_publish.included = 1
              
              this.active_step = 0
              this.previous_step = 0

              this.procurement_project.skip_ppn = 0
              this.setActive("ppn_publish")
            }
          }
        },
        setStep(){
          let project_type = this.procurement_project.project_type
          let standard_type = this.procurement_project.standard_type
          let agreement_type = this.procurement_project.agreement_type
          let threshold = this.procurement_project.threshold

          let project_status = this.procurement_project.status
          if(project_status == this.ProcurementProjectStatuses.CODE_PENDING){
            this.publication_step.pme_publish.included = 1
            this.publication_step.pme_publish.active = 1
          }

          if(project_status == this.ProcurementProjectStatuses.CODE_PRE_PIN || project_status == this.ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED){
            this.publication_step.ppn_publish.included = 1
            this.publication_step.ppn_publish.active = 1
          }

          if( project_status == this.ProcurementProjectStatuses.CODE_ITT_PENDING ){
            this.publication_step.tn_publish.included = 1
            this.publication_step.tn_publish.active = 1
          }

          if( project_status == this.ProcurementProjectStatuses.CODE_MARKING_STAGE ){
            this.publication_step.contract_award_publish.included = 1
            this.publication_step.contract_award_publish.active = 1
          }

          if( project_status == this.ProcurementProjectStatuses.CODE_AWARD_STANDSTILL ){
            this.publication_step.contract_details_publish.included = 1
            this.publication_step.contract_details_publish.active = 1
          }
        }
    }
  }
  </script>
  <style scoped>
    .el-upload-list__item{
      margin-top: 1px !important
    }
  </style>