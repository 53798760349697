<template>
  <div v-if="$store.state.checkUserGroupModuleRestriction('framework-lot-details-mod') != 1"> 
  <div v-if="lot">

    <el-drawer title="" v-model="showPricingSetting" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true" :before-close="handleClose">
      <pin-pricing-setting-form :pin_notice="if_pin_notice ? itt_framework : null " :lot="selected"
        :pricing="selected.pin_pricing_setting" v-if="selected" @close="handleClose"></pin-pricing-setting-form>
    </el-drawer>

    <el-drawer title="" v-model="showSpecValues" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true">
      <pin-lot-supplier-specs-list :kind="kind" :lot="selected" v-if="selected"></pin-lot-supplier-specs-list>
    </el-drawer>

    <el-drawer v-model="showPricingCosts" :showClose="true" size="70%" :with-header="true" direction="rtl"
      :destroy-on-close="true" :append-to-body="true">
      <div class="container">
        <pin-supplier-costs-list :lot="selected"
          :pin_notice="if_pin_notice ? itt_framework: null"></pin-supplier-costs-list>
      </div>
    </el-drawer>

    <el-drawer title="" v-model="showAdd" direction="rtl" :destroy-on-close="true" :append-to-body="true">
      <supplierhub-lot-form @close="handleSet($event)" :lot="selected" :pin_notice_id="pin_notice_id"
        :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice"></supplierhub-lot-form>
    </el-drawer>

    <el-drawer title="" v-model="showSupplierhubSpec" direction="rtl" size="70%" :destroy-on-close="true"
      :append-to-body="true">
      <supplierhub-lot-view @reOpenSpec="reOpenSpec" @close="handleClose()" :lot="selected" :itt_framework="itt_framework"
        :pin_notice_id="pin_notice_id" :itt_framework_id="itt_framework_id" :if_pin_notice="if_pin_notice" :kind="kind"
        v-if="selected && showSupplierhubSpec"></supplierhub-lot-view>
    </el-drawer>

    <div class="row">
      <div class="col-md-12">
          <h4>{{ (selected.lot_no ? "Lot " + selected.lot_no + " - " : "") }}{{ selected.name }}
          <el-tooltip :enterable="false" placement="top-start" 
          v-if="($store.state.checkUserGroupPermissions('framework-lot-details', 'edit', false, false, '', '', [], true) == 1) ||
          ($store.state.checkUserGroupPermissions('framework-lot-details', 'edit', false, false, '', '', [], true) == 0 && allowCreate)
          ">
            <template #content>
              <span>Edit </span>
            </template>
            <el-button type="warning" style="font-size: 12px" class="link" @click="handleEdit"><i class="fa fa-edit"></i> Edit</el-button>
          </el-tooltip>
          <el-button size="default" v-if="(!selected.user_forum_related_info || selected.user_forum_related_info.length == 0) && ($store.state.isUserASupplier() || $store.state.isUserABuyer() || $store.state.isAdmin()) "
          @click="createThread(selected)" class="" type="success" >
            <i class="fa fa-edit"></i>&nbsp;Create a Thread
          </el-button>
        </h4>
        <div v-html="selected.short_description"></div>
        <div v-html="selected.long_description"></div>
        <hr>
        <div>
          <div v-if="forSupplierValue()">
            <el-button 
              v-if="$store.state.checkUserGroupPermissions('framework-lot-details', 'pricing_cost', true)"
              size="small" type="primary" plain @click="handleSupplierCosts()">Pricing
              Costs</el-button>
            <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(0)">Specifications</el-button>
            <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(1)">Social
              Values</el-button>
          </div>
          <div v-else>

            <!-- <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(0)">Specifications</el-button>
            <el-button size="small" type="primary" plain @click="handleSupplierSpecsView(1)">Social
              Values</el-button> -->

            <!-- <el-button
              v-if="itt_framework.if_user_joined && !itt_framework.is_archived && (itt_framework.project_status == 'ITT')"
              plain type="primary" @click="handleViewPinLots()" v-tooltip="'Submit values'">Submit Lot Spec/Social
              Values
              and Pricing Costs</el-button> -->

            <el-button plain type="warning" @click="handleShowPricing()"
              v-if="(pin_notice && pin_notice.project_status == 'DOC' || itt_framework && itt_framework.project_status == 'DOC')">
              <i class="fa fa-cog"></i> Set Up Pricing Configuration</el-button>
            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Specifications</span>
              </template>
              <el-button plain size="medium" type="primary" @click="handleView(0)">Specifications</el-button>
            </el-tooltip>
 
            <el-tooltip :enterable="false" placement="top-start">
              <template #content>
                <span>Social Values</span>
              </template>
              <el-button plain size="medium" type="primary" @click="handleView(1)">Social Values</el-button>
            </el-tooltip>
          </div>

        </div>
      </div>
      <div v-if="$store.state.checkUserGroupPermissions('framework-lot-details', 'essential_framework_files', false, true, 'common_files', 'view')" class="col-md-6">
        <common-files :itt_framework="itt_framework" :lot_id="selected.id" :if_pin_notice="if_pin_notice" :pin_notice="pin_notice"
          :update="update"></common-files>
      </div>
      <div v-if="$store.state.checkUserGroupPermissions('framework-lot-details', 'my_file_library', false, true, 'my_files', 'view')" class="col-md-6">
        <my-files :itt_framework="itt_framework" :lot_id="selected.id" :if_pin_notice="if_pin_notice" :pin_notice="pin_notice"
          @updated="handleUpdate"></my-files>
      </div>

      <div v-if="$store.state.checkUserGroupPermissions('framework-lot-details', 'qa_board', false, true, 'questions', 'view')" class="col-md-6">
        <public-questions-with-answers-list :itt_framework="itt_framework" :lot_id="selected.id" :pin_notice="pin_notice"
          :if_pin_notice="if_pin_notice"></public-questions-with-answers-list>
      </div>

      <div v-if="$store.state.checkUserGroupPermissions('framework-lot-details', 'my_question', false, true, 'questions', 'view')" class="col-md-6" >
        <my-questions-list :itt_framework="itt_framework" :lot_id="selected.id" :pin_notice="pin_notice"
          :if_pin_notice="if_pin_notice"></my-questions-list>
      </div>

      <div v-if="$store.state.checkUserGroupPermissions('framework-lot-details', 'related_forum_threads', true)"
      class="col-md-12">
        <ForumRelatedInformationList
          :data="selected.forum_related_info"
          :title="'Threads Related to this Lot'"
        ></ForumRelatedInformationList>
      </div>

    </div>
  </div>
</div>
<div v-else>
    <el-empty description="Your group doesn't have access to this module!" />
  </div>

  <el-drawer v-model="thread_drawer" :show-close="true" size="70%" direction="rtl"
    :append-to-body="true">
    <div class="container">
      
      <ForumThreadForm :prop_selected_related_info="selected_related_info" :key="'thread_form_' + thread_form_counter" :show_category_selection="true" 
      :selected="selected_thread" @save="afterThreadSave" @cancel="thread_drawer = false">
      </ForumThreadForm>
    </div>
  </el-drawer>

</template>

<script>


import {
  mapState
} from 'vuex'
import PinLotSupplierSpecsList from '../../pin_notice/PinLotSupplierSpecsList.vue'
import PinPricingSettingForm from '../../pin_notice/PinPricingSettingForm.vue'
import PinSupplierCostsList from '../../pin_notice/PinSupplierCostsList.vue'
import MyQuestionsList from '../../questions_and_answers/MyQuestionsList.vue'
import PublicQuestionsWithAnswersList from '../../questions_and_answers/PublicQuestionsWithAnswersList.vue'
import CommonFiles from '../CommonFiles.vue'
import MyFiles from '../MyFiles.vue'
import SupplierhubLotForm from './SupplierhubLotForm.vue'
import SupplierhubLotView from './SupplierhubLotView.vue'
import ForumThreadForm from '../../forum/ForumThreadForm.vue'
import ForumRelatedInformationList from '../../forum/ForumRelatedInformationList.vue'

export default {
  emits: ["reOpenLot", "reOpenSpec", "updateOpenSpecListKind"],
  computed: {
    ...mapState(['IttStatuses', 'ProcurementProjectStatuses', 'ProjectFilters', 'BuyerRoleName']),
    user() {
      return this.$store.state.savedUser // get state
    }
  },
  props: {
    joined: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: false
    },
    itt_framework: {
      type: Object,
      required: false,
      default: null
    },
    lot: {
      type: Object,
      required: false,
      default: null
    },
    itt_framework_id: {
      type: Number,
      required: false,
      default: null
    },
    pin_notice_id: {
      type: Number,
      required: false,
      default: null
    },
    if_pin_notice: {
      type: Number,
      required: false,
      default: 0
    },
    pin_notice: {
      type: Object,
      required: false,
      default: null
    },
    open_spec_list_kind: {
      type: Number,
      required: false,
      default: 2
    },
  },
  components: {
    SupplierhubLotView,
    MyQuestionsList,
    PublicQuestionsWithAnswersList,
    MyFiles,
    CommonFiles,
    SupplierhubLotForm,
    PinSupplierCostsList,
    PinLotSupplierSpecsList,
    PinPricingSettingForm,
    ForumThreadForm,
    ForumRelatedInformationList
  },
  data() {
    return {
      selected_related_info: [],
      selected_thread: null,
      thread_form_counter: 1,
      thread_drawer: false,
      showSupplierhubSpec: false,
      update: 0,
      kind: 0,
      showViewSpec: false,
      selected: null,
      showAdd: false,
      showPricingCosts: false,
      showSpecValues: false,
      kind: null,
      showPricingSetting: false
    }
  },
  created: function () {
    this.selected = Object.assign({}, this.lot)

    if (this.selected.model_info) {
          this.selected_related_info = []
          let key_object = {}
          let id_object = { id: this.selected?.id };
          key_object["key"] = id_object
          key_object["model_id"] = this.selected?.model_info?.id

          this.selected_related_info.push(key_object)
    }
    if(this.open_spec_list_kind != 2){
      this.handleView(this.open_spec_list_kind)
      this.$emit('updateOpenSpecListKind', 2)
    }
  },
  methods: {
    reOpenSpec(retured_kind){
      this.showSupplierhubSpec = false
      this.$emit('reOpenLot', {data: this.selected, kind: retured_kind})
    },
    afterThreadSave(){
      this.thread_form_counter++
      this.thread_drawer = false
      this.$emit('reOpenLot', {data: this.selected, kind: 2})
    },
    createThread(selected){
      this.selected_thread = {
        title: "Framework Lot: " + selected.name,
        content: null,
        tag: [],
        attachments: null
      }
      this.thread_drawer = true
    },
    handleShowPricing() {
      this.showPricingSetting = true
      this.selected = Object.assign({}, this.lot)
    },
    handleSupplierSpecsView(kind) {
      this.selected = Object.assign({}, this.lot)
      this.showSpecValues = true
      this.kind = kind
    },
    handleSupplierCosts() {
      this.showPricingCosts = true
      this.selected = Object.assign({}, this.lot)
    },
    forSupplierValue() {
      return this.itt_framework && this.itt_framework.if_user_joined && !this.itt_framework.is_archived && this.itt_framework.project_status == 'ITT' && this.$store.state.isUserASupplier()
    },
    handleSet(lot) {
      this.selected = Object.assign({}, lot)
      this.showAdd = false
    },
    handleView(kind) {
      this.showSupplierhubSpec = true
      this.kind = kind
    },
    handleClose() {
      this.showPricingCosts = false
      this.showSpecValues = false
      this.showPricingSetting = false
      this.showSupplierhubSpec = false
    },
    handleUpdate() {
      this.update += 1
    },
    handleEdit() {
      let type = "itt"
      if (this.if_pin_notice == 1) type = "pin"

      if (!this.joined) {
        Swal.fire({
          title: 'Warning!',
          text: "You need to join this " + type + " in order to edit this lot!",
          icon: 'warning',
        })
        return false
      }

      this.selected = Object.assign({}, this.lot)
      this.showAdd = true
    },
  }
}
</script>
