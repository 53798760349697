import api from './api'

const API = '/api/procurement_projects'

export const ProcurementProjects = {
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  checkProcurementApiKey: function(){
    return api.get(API + "/procurement-api-key", {})
  },
  getProcurementProjectTypes: function(params) {
    return api.get(API + "/procurement_project_types", params)
  },
  checkIfProjectHasData: function(id, params) {
    return api.get(API + "/check-if-project-has-data/" + id, params)
  },
  loadAwardees: function(id, params) {
    return api.get(API + "/load-awardees/" + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(id, params) {
    return api.update(API+'/'+id, params)
  },
  saveAwardeeSettings: function(id, params) {
    return api.update(API+'/save-awardee-settings/'+id, params)
  },
  saveAwardeeSettingsWithFiles: function(id, params) {
    return api.updateFormData(`${API}/save-awardee-settings/${id}`, params)
  },
  updateWithFiles: function(id, params) {
    return api.updateFormData(`${API}/update-with-files/${id}`, params)
  },
  load_history: function(id, params) {
    return api.get(API + '/load_history/' + id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  exportHistories: function(type, id, params) {
    return api.get(API+"/export_histories/" + type + "/" + id, params)
  },
  importProjects: function(params) {
    return api.createFormData(API + "/import_projects", params)
  },
}
