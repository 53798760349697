import api from './api'

const API = '/api/group'

export const Group = {
  get: function(params) {
    return api.get(API, params)
  },
  loadModulesAndPermissions: function(params) {
    return api.get(API + "/load-modules-and-permissions", params)
  },
  loadGroupUsers: function(params) {
    return api.get(API + "/load-group-users", params)
  },
  create: function(params) {
      return api.create(API, params)
  },
  saveGroupPermissions: function(params) {
    return api.create(API + '/save-group-permissions', params)
  },
  saveGroupUsers: function(params) {
    return api.create(API + '/save-group-users', params)
  },
  update: function(id, params) {
      return api.update(API+'/'+id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
  deleteIndividualPermissions: function(params) {
    return api.delete(API+'/delete-individual-permissions/'+params.id, params)
  },
}
