<style>
    .gutter {
      cursor: e-resize;
      background: url(https://raw.githubusercontent.com/RickStrahl/jquery-resizable/master/assets/vsizegrip.png) center center no-repeat #e4e7ed;
    }
</style>
<template>
    <div class="row">
        <div class="col-md-12">
            <el-card class="border-0" shadow="never">
                <div class="row">
                    <div class="col-md-6 mb-3 ">
                        <h5 class=""><i class="fa fa-envelope"></i>
                            {{ !target_code ? ' Email Templates' : 'Update Email Content' }}</h5>
                    </div>
                    <div class="col-md-6 mb-3">
                        <!--<el-button v-if="$store.state.isAdmin()" @click="addNewGlobal" class="mb-1 float-end" type="success">
                            <i class="fa fa-plus"></i>&nbsp;Add Global Email Template</el-button>-->

                        <el-button v-if="$store.state.isAdmin()" @click="openEmailSettings" class="me-1 mb-1 float-end" type="primary">
                            <i class="fa fa-gear"></i>&nbsp;Email Template Settings</el-button>
                    </div>
                </div>
                
                <div class="row">
                    <div v-if="!target_code" id="menu_col" class="col-md-3 me-0 pe-0">
                        <el-card  style="border-top-right-radius: 0px; border-bottom-right-radius: 0px; height: 100% !important;" shadow="never">
                            <div style="max-height: 600px;" class="overflow-auto">   
                                <a-menu
                                    ref="templateMenu"
                                    mode="inline"
                                    :items="menu_global_templates"
                                    :v-model:selectedKeys="selected_menu"
                                    @click="menuClick"
                                    :openKeys="openKeys"
                                ></a-menu>
                            </div>
                            
                        </el-card>
                    </div>
                    <div id="template_col" :class="!target_code ? 'col-md-9' : 'col-md-12'" class="ms-0 ps-0">
                        <el-card style="border-top-left-radius: 0px; border-bottom-left-radius: 0px" shadow="never">
                            <el-empty v-if="!selected_menu" description="No template selected" />
                            <template v-else>
                                <div class="row">
                                    <div class="col-md-10 mb-2">
                                        <el-form-item class="mb-1" label="Version:">
                                            <el-select
                                                filterable
                                                @change="versionChange"
                                                v-model="selected_version"
                                                placeholder="Select version"
                                                size=""
                                            >
                                            <el-option key="0" value="0" label="Original Template"></el-option>
                                            <el-option
                                                v-for="item in email_templates"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            />
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                    <div class="col-md-2 mb-2 pt-1">
                                        <el-tag class="float-end" type="success" v-if="(selected_version != '0' && selected_email_template?.selected == 1) || (selected_version == '0' && (!default_selected_email_template_id || !selected_email_template))" size="large">Selected as Default</el-tag>
                                        <el-button v-else @click="updateSelection" class="mb-1 float-end" type="primary">
                                            <i class="fa fa-eye"></i> Set as Default</el-button>
                                    </div>
                                    <el-divider class="mt-0 mb-2"></el-divider>
                                </div>
                                <div class="row">
                                    <div class="col-md-9 mb-2">
                                        <el-text >Template Name: <b>{{ selected_template?.name }}</b></el-text>
                                    </div>
                                    <div class="col-md-3 mb-2">
                                        <el-button-group class="mt-1 float-end">
                                            <el-tooltip v-if="selected_version != '0' || $store.state.isAdmin()" :enterable="false" placement="top-start">
                                                <template #content>
                                                    <span>Edit</span>
                                                </template>
                                                <el-button @click="editVersion" class="mb-1" type="warning">
                                                    <i class="fa fa-edit"></i></el-button>
                                            </el-tooltip>
                                            <el-tooltip v-if="selected_version != '0' || $store.state.isAdmin()" :enterable="false" placement="top-start">
                                                <template #content>
                                                    <span>Send Test Email</span>
                                                </template>
                                                <el-button :disabled="disable_state" @click="showTestMail" class="mb-1" type="info">
                                                    <i v-if="disable_count == 0" class="fa fa-envelope"></i>{{ (this.timer_min > 0 || this.timer_sec > 0) ? (this.timer_min + ":" + this.timer_sec) : ""}}</el-button>
                                            </el-tooltip>
                                            <el-tooltip :enterable="false" placement="top-start">
                                                <template #content>
                                                    <span>Create New Version</span>
                                                </template>
                                                <el-button class="mb-1" @click="createVersion" type="success">
                                                    <i class="fa fa-plus"></i> 
                                                </el-button>
                                            </el-tooltip>
                                        </el-button-group>
                                    </div>
                                    <el-divider class="mt-1 mb-2"></el-divider>
                                </div>
                                
                                <div class="row">
                                    <div class="col-md-12">
                                        <el-switch
                                            size="large"
                                            v-model="show_variable_sample_values"
                                            :active-action-icon="View"
                                            :inactive-action-icon="Hide"
                                            active-text="Show Sample Values"
                                            class="float-end"
                                            @change="variableSwitchChange"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <el-card class="border-0 template_content_container" shadow="never" v-html="variable_converted_selected_template ? variable_converted_selected_template : selected_template?.content"></el-card>
                                    </div>
                                </div>
                                
                            </template>
                        </el-card>
                    </div>
                </div>
            </el-card>
        </div>
    </div>

    <el-drawer v-model="template_form_drawer" :show-close="true" size="80%" direction="rtl"
        :append-to-body="true">
        <GlobalEmailTemplateForm
            :prop_is_new="is_new"
            :prop_form_type="form_type"
            :prop_selected_global_template="selected_global_template"
            :prop_email_templates="email_templates"
            :prop_selected_email_template="selected_email_template"
            :prop_latest_version="latest_version"
            :prop_is_global="(selected_version == '0' ? 1 : 0)"
            @save="afterSave"
            :key="'template_form_' + form_counter"
        ></GlobalEmailTemplateForm>
    </el-drawer>

    <el-dialog destroy-on-close v-model="tets_mail_dialog" title="Send Test Mail" width="500" >
        <el-input v-model="test_mail_form.email" />
        <template #footer>
            <div class="dialog-footer">
            <el-button @click="tets_mail_dialog = false">Cancel</el-button>
            <el-button type="primary" @click="sendTestMail">
                Confirm
            </el-button>
            </div>
        </template>
    </el-dialog>

    <el-drawer v-model="email_settings_drawer" :show-close="true" size="70%" direction="rtl"
        :append-to-body="true">
        <EmailTemplateSettings></EmailTemplateSettings>
    </el-drawer>
</template>

  <script>
  import TemplateEditor from '@/components/common/TemplateEditor.vue'
  import { GlobalEmailTemplate } from '@/api_services/global_email_templates'
  import { EmailTemplate } from '@/api_services/email_templates'
  import GlobalEmailTemplateForm from './GlobalEmailTemplateForm.vue'
  import EmailTemplateSettings from './EmailTemplateSettings.vue'
  import { h, shallowRef } from 'vue';
  import { Hide, View } from '@element-plus/icons-vue'
  import { MailOutlined, SettingOutlined } from '@ant-design/icons-vue'
  
  export default {
    mounted() {
        if(!this.target_code){
            Split(['#menu_col', '#template_col'], {
                sizes: [this.menu_sizes[0], this.menu_sizes[1]],
                gutterSize: 8,
                expandToMin: true,
                gutterAlign: 'end',
                snapOffset: 0,
                minSize: [270, 500],
            })
        }
    },
    components: {
        TemplateEditor, GlobalEmailTemplateForm, EmailTemplateSettings
    },
    props: {
        target_code:{
            type: String,
            required: false,
            default: null
        },
    },
    data() {
        return {
            email_settings_drawer: false,
            variable_converted_selected_template: null,
            Hide: shallowRef(Hide),
            View: shallowRef(View),
            show_variable_sample_values: false,
            default_selected_email_template_id: null,
            timer_min: 0,
            timer_sec: 0,
            disable_count: 0,
            disable_state: false,
            test_mail_form:{
                global_template_id: null,
                content: null,
                email: null
            },
            tets_mail_dialog: false,
            form_counter: 1,
            prop_is_new: 1,
            menu_sizes: [25, 73],
            selected_template: null,
            latest_version: null,
            form_type: "global",
            template_form_drawer: false,
            selected_email_template: null,
            email_templates: [],
            selected_version: "0",
            meta: {
                page: 1,
                total: 0,
                pages: 1
            },
            menu_global_templates: [],
            list_global_templates: [],
            selected_menu: null,
            openKeys: [],
            selected_global_template: null
        }
    },
    watch: {
        '$store.state.test_email_time':{
            handler(value){
                this.timer_min = value.min
                this.timer_sec = value.sec

                if(this.timer_min > 0 || this.timer_sec > 0){
                    this.disable_count = 1
                    this.disable_state = true
                }
                else{
                    this.disable_count = 0
                    this.disable_state = false
                }
            },
            deep:true
        }
    },
    computed: {
        user() {
            return this.$store.state.savedUser
        },
    },
    created: function () {
        if(!this.target_code){
            window.addEventListener("resize", this.resizeScreen);
            this.resizeScreen()
        }
        
        this.loadGlobalEmailTemplates(1)
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeScreen);
    },
    methods: {
        addNewGlobal(){
            /*this.is_new = 1
            this.form_type = "global"
            this.form_counter++
            this.template_form_drawer = true*/
        },
        openEmailSettings(){
            this.email_settings_drawer = true
        },
        variableSwitchChange(){
            if(this.show_variable_sample_values){
                let content = document.getElementsByClassName("template_content_container")[0]
                
                this.selected_global_template.variables.forEach(function(variable){
                    content.querySelectorAll('.embeded_variable[data-key="'+ variable.backend_name +'"]').forEach(item => {
                        item.outerHTML = variable.sample_value
                    })
                })

                this.variable_converted_selected_template = content.innerHTML
            }
            else{
                this.variable_converted_selected_template = null
            }
        },
        showTestMail(){
            this.test_mail_form.email = this.user?.email
            this.test_mail_form.content = this.selected_template?.content
            this.test_mail_form.global_template_id = this.selected_global_template.id

            this.tets_mail_dialog = true
        },
        sendTestMail(){
            this.globalState.loading = true
            EmailTemplate.sendTestMail(this.test_mail_form)
                .then(result => {
                    this.$toast.success('Successfully sent!')
                    this.$store.commit('initiateTestEmailCooldown', 1)
                })
                .catch(error => {
                    this.$toast.error('An error occured while fetching request')
                    console.error('error:', error)
                })
                .finally(() => {
                    this.globalState.loading = false
                    this.tets_mail_dialog = false
                })
        },
        editVersion(){
            this.is_new = 0

            if(this.selected_version == '0')
                this.form_type = "global"
            else
                this.form_type = "new_version"

            this.form_counter++
            this.template_form_drawer = true
        },
        updateSelection(){
            this.globalState.loading = true

            let param = {
                global_template_id: this.selected_global_template?.id,
                email_template_id: this.selected_version
            }

            EmailTemplate.updateSelection(param)
                .then(result => {
                    this.$toast.success('Successfully selected!')
                    this.selected_email_template = result.data.email_template
                    this.default_selected_email_template_id = this.selected_email_template?.id
                    if(this.selected_version == '0'){
                        this.default_selected_email_template_id = null
                        this.selected_email_template = null
                    }
                })
                .catch(error => {
                    this.$toast.error('An error occured while fetching request')
                    console.error('error:', error.response.data)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
        },
        resizeScreen(){
            let width = window.innerWidth
            if(width <= 958){
                this.menu_sizes = [100, 100]
            }
        },
        versionChange(value){
            this.variable_converted_selected_template = null
            this.show_variable_sample_values = false
            if(value > 0){
                this.showEmailTemplate(value)
            }
            else{
                this.selected_template = this.selected_global_template
            }
        },
        showEmailTemplate(id){
            this.globalState.loading = true
            EmailTemplate.showEmailTemplate(id)
                .then(result => {
                    if (result.data.selected_email_template) {
                        this.selected_template = result.data.selected_email_template
                        this.selected_email_template = this.selected_template
                        this.form_counter++

                    } else {
                        this.selected_template = null
                        this.$toast.error('No data found')
                    }
                })
                .catch(error => {
                    this.$toast.error('An error occured while fetching request')
                    console.error('error:', error.response.data)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
        },
        afterSave(returned_data){
            this.template_form_drawer = false
            this.showGlobalEmailTemplates(returned_data?.id)
        },
        createVersion(){
            this.is_new = 1
            this.form_type = "new_version"
            this.form_counter++
            this.template_form_drawer = true
        },
        showGlobalEmailTemplates(global_template_id){
                this.variable_converted_selected_template = null
                this.show_variable_sample_values = false

                this.globalState.loading = true
                this.selected_version = "0"
                GlobalEmailTemplate.show(global_template_id)
                .then(result => {
                    if (result.data.global_email_template) {
                        this.selected_global_template = result.data.global_email_template
                        this.email_templates = result.data.email_templates
                        this.selected_email_template = result.data.selected_email_template
                        this.latest_version = result.data.latest_version
                        this.selected_template = (this.selected_email_template ? this.selected_email_template : this.selected_global_template)
                        if(this.selected_email_template){
                            this.selected_version = this.selected_email_template.id
                            this.default_selected_email_template_id = this.selected_email_template.id
                        }

                    } else {
                        this.$toast.error('No data found')
                    }
                })
                .catch(error => {
                    this.$toast.error('An error occured while fetching request')
                    console.error('error:', error.response.data)
                })
                .finally(() => {
                    this.globalState.loading = false
                })
        },
        menuClick(value){
            this.selected_menu = value.item
            this.showGlobalEmailTemplates(this.selected_menu.template_id)
        },
        loadGlobalEmailTemplates(all = 0){
            this.globalState.loading = true
            
            let param = {
                page: this.meta.page,
                all: all,
                target_code: this.target_code
            }

            GlobalEmailTemplate.loadGlobalTemplates({
                params: param
            })
            .then(result => {
                if (result.data.global_email_templates) {
                    if(all){
                        let agg = {
                            temp: []
                        };
                        let self = this
                        result.data.global_email_templates.forEach(path => {
                            path.display_path.split('/').reduce((agg, part, level, parts) => {
                                if (!agg[part]) {
                                    agg[part] = {
                                        temp: []
                                    };
                                    self.openKeys.push(path.id + "-" + (level + 1))
                                    agg.temp.push({
                                        template_id: path.id,
                                        key: ( parts.length != (level + 1) ? path.id + "-" + (level + 1) : path.code),
                                        title: part,
                                        label: part,
                                        level: level + 1,
                                        children: ( parts.length != (level + 1) ? agg[part].temp : null),
                                        icon: ( parts.length != (level + 1) ? null : ( path.for_admin == 1 ? h(SettingOutlined) : h(MailOutlined))),
                                    })
                                }
                                return agg[part];
                            }, agg)
                        })

                        this.menu_global_templates = agg.temp;
                        if(this.target_code){
                            this.selected_menu = this.menu_global_templates[0]
                            this.showGlobalEmailTemplates(this.selected_menu.template_id)
                        }
                    }
                    else
                        this.list_global_templates = result.data.global_email_templates
                }
            })
            .catch(error => {
                this.$toast.error('An error occured while fetching request')
                console.error('error:', error)
            })
            .finally(() => {
                this.globalState.loading = false
            })
      
        }
    }
  }
  </script>
  