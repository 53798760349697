<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-6">
                    <label>Is this an open framework scheme?</label>
                    <br/><label style="color: #a9a9a9">An open framework scheme is a series of successive frameworks on substantially the same terms, with awarded suppliers carried over but new suppliers able to join as well.</label>
                    <div :class="{ 'has-error-field': v.form.is_open_framework.$invalid }">
                        <el-radio-group v-model="form.is_open_framework">
                            <el-radio :value="0">Yes, a new open framework scheme</el-radio>
                            <!-- <el-radio :value="1">Yes, a continuation of an existing open framework scheme</el-radio> -->
                            <el-radio :value="2">No, a standalone framework</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.is_open_framework.$invalid" class="error-text" >Framework scheme is required</div>
                </div>
                <div class="col-md-6" v-if="form.is_open_framework === 0">
                    <br/><label>Open framework scheme end date (estimated)</label>
                    <div :class="{ 'has-error-field': v.form.framework_end_date.$invalid }" style="width: 224px">
                        <el-date-picker v-model="form.framework_end_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                    </div>
                    <div v-if="v.form.framework_end_date.$invalid" class="error-text" >End date is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Maximum number of suppliers (optional)</label>
                    <div :class="{ 'has-error-field': v.form.maximum_suppliers.$invalid }">
                        <el-input v-model="form.maximum_suppliers"></el-input>
                    </div>
                    <div v-if="v.form.maximum_suppliers.$invalid" class="error-text" >maximum suppliers should be numeric</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Percentage fee charged to suppliers</label>
                    <div :class="{ 'has-error-field': v.form.percentage_fee.$invalid }">
                        <el-input v-model="form.percentage_fee"></el-input>
                    </div>
                    <div v-if="v.form.percentage_fee.$invalid" class="error-text" >Percentage fee is required and should be numeric</div>
                </div>
                <div class="col-md-12">
                    <br/><label>Further information about fees (optional)</label>
                    <el-input v-model="form.further_info_fees" type="textarea" :rows="3"></el-input>
                </div>
                <div class="col-md-12">
                    <br/><label>Framework operation description</label>
                    <br/><label style="color: #a9a9a9">Include how prices are determined and any selection process for the award of contracts.</label>
                    <div :class="{ 'has-error-field': v.form.framework_description.$invalid }">
                        <el-input v-model="form.framework_description" type="textarea" :rows="3"></el-input>
                    </div>
                    <div v-if="v.form.framework_description.$invalid" class="error-text" >Framework description is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Award method when using the framework</label>
                    <div :class="{ 'has-error-field': v.form.award_method.$invalid }">
                        <el-radio-group v-model="form.award_method">
                            <el-radio :value="0">With competition</el-radio>
                            <el-radio :value="1">Without competition</el-radio>
                            <el-radio :value="2">Either with or without competition</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.award_method.$invalid" class="error-text" >Award method is required</div>
                </div>
                <div class="col-md-6">
                    <br/><label>Can the framework be used by other contracting authorities as well as those establishing it?</label>
                    <div :class="{ 'has-error-field': v.form.can_use_other_ca.$invalid }">
                        <el-radio-group v-model="form.can_use_other_ca">
                            <el-radio :value="true">Yes</el-radio>
                            <el-radio :value="false">No</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.can_use_other_ca.$invalid" class="error-text" >Yes/No is required</div>
                </div>
                <div class="row" v-if="form.can_use_other_ca">
                    <div class="col-md-6">
                        <br/><label>Can the framework only be used by contracting authorities in specific locations?</label>
                        <div :class="{ 'has-error-field': v.form.can_use_specific_loc.$invalid }"  style="width: 150px">
                            <el-radio-group v-model="form.can_use_specific_loc">
                                <el-radio :value="true">Yes</el-radio>
                                <el-radio :value="false">No</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.can_use_specific_loc.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-6" v-if="form.can_use_specific_loc">
                    <br/><label>Delivery regions (optional)</label>
                    <br/><label style="color: #A9A9A9">select all that apply.</label>
                    <el-select v-model="form.ca_locations" filterable clearable multiple>
                        <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                            <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                <span style="float: left">{{ c_values.name }}</span>
                                <span class="secondary-selection">
                                    {{ c_abbrev }}
                                </span>
                            </el-option>
                                <el-option-group v-if="'parts' in c_values && !form.ca_locations?.includes(c_abbrev)" :key="c_values.name" :label="'List of ' + c_values.name" style="margin-left: 15px">
                                    <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                        <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                            <span style="float: left">{{ c1_values.name }}</span>
                                            <span class="secondary-selection">
                                                {{ c1_abbrev }}
                                            </span>
                                        </el-option>
                                        <el-option-group v-if="'parts' in c1_values && !form.ca_locations?.includes(c1_abbrev)" :key="c1_values.name" :label="'List of ' + c1_values.name" style="margin-left: 15px">
                                            <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                    <span style="float: left">{{ c2_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c2_abbrev }}
                                                    </span>
                                                </el-option>
                                                    <el-option-group v-if="'parts' in c2_values && !form.ca_locations?.includes(c2_abbrev)" :key="c2_values.name" :label="'List of ' + c2_values.name" style="margin-left: 15px">
                                                        <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                            <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                <span style="float: left">{{ c3_values.name }}</span>
                                                                <span class="secondary-selection">
                                                                    {{ c3_abbrev }}
                                                                </span>
                                                            </el-option>
                                                            <el-option-group v-if="'parts' in c3_values && !form.ca_locations?.includes(c3_abbrev)" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                    <span style="float: left">{{ c4_values.name }}</span>
                                                                    <span class="secondary-selection">
                                                                        {{ c3_abbrev }}
                                                                    </span>
                                                                </el-option>
                                                            </el-option-group>
                                                        </span>
                                                    </el-option-group>
                                            </span>
                                        </el-option-group>
                                    </span>
                            </el-option-group>
                        </el-option-group >
                    </el-select>
                </div>

                    <div class="col-md-12">
                        <br/><label>Contracting authorities that may use the framework</label>
                        <br/><label style="color: #a9a9a9">Describe categories of authorities or list the authorities.</label>
                        <div :class="{ 'has-error-field': v.form.ca_users_desc.$invalid }">
                            <el-input v-model="form.ca_users_desc" type="textarea" :rows="3"></el-input>
                        </div>
                        <div v-if="v.form.ca_users_desc.$invalid" class="error-text" >Description is required</div>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, numeric,
     requiredIf
     } from '@vuelidate/validators'
 
     const FRAMEWORK = 'FRAMEWORK';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'nuts_code_choices'],
         data() {
             return {
                form: {
                    is_open_framework: null,
                    framework_end_date: null,
                    maximum_suppliers: null,
                    percentage_fee: null,
                    further_info_fees: null,
                    justification_four_years: null,
                    justification_eight_years: null,
                    framework_description: null,
                    award_method: null,
                    can_use_other_ca: null,
                    can_use_specific_loc: null,
                    ca_locations: null,
                    ca_users_desc: null,
                    previous_framework: null,
                },
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form
            else this.initValues()
        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    is_open_framework: {
                        required,
                        $autoDirty: true
                    },
                    framework_end_date: {
                        required: requiredIf(function () { return this.form.is_open_framework === 0 }),
                        $autoDirty: true
                    },
                    framework_description: {
                        required,
                        $autoDirty: true
                    },
                    award_method: {
                        required,
                        $autoDirty: true
                    },
                    can_use_other_ca: {
                        required,
                        $autoDirty: true
                    },
                    can_use_specific_loc: {
                        required: requiredIf(function () {return this.form.can_use_other_ca}),
                        $autoDirty: true
                    },
                    ca_users_desc: {
                        required,
                        $autoDirty: true
                    },
                    percentage_fee: {
                        required,
                        numeric,
                        $autoDirty: true
                    },
                    ca_locations: {
                        required: requiredIf(function () { return this.form.can_use_specific_loc}),
                        $autoDirty: true
                    },
                    maximum_suppliers: {
                        numeric,
                        $autoDirty: true
                    }
                }
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', FRAMEWORK, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            disablePastDates(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                return date < today;
            },
            initValues() {
                
            }
        }
     }
 </script>