<template>
  <div>
    <!-- <loading :loading="true"></loading> -->

    <el-dialog title="" v-model="showComplex" width="40%" :before-close="onHideMessageAttachments">
      <div v-if="selected">

        <div v-if="selected.category_name == 'Banking'">
          <p>Please download the template below and upload it in the additional statement area.</p>
          <div v-if="!banking_template">
            Preparing the banking template.Please wait
          </div>
          <a :href="banking_template" v-if="banking_template" download="banking_template.xlsx" target="_blank">Banking
            Template.xlsx</a>
        </div>
        <div v-if="selected.category_name == 'Merchant Services'">
          <p>Documents required are your recent merchant service statement.</p>
          <p>Please download sample statement below:</p>
          <a :href="sample_statement" download="sample_statement.pdf" target="_blank">Sample-Statement.xlsx</a>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="Message Attachments" v-model="show_message_attachments" width="40%"
      :before-close="onHideMessageAttachments">
      <div>
        <procurement-message-attachments v-if="selected"
          :message_attachments="selected.message_attachments"></procurement-message-attachments>
      </div>
    </el-dialog>

    <el-drawer title="Send a Message to Suppliers" v-model="showMessage" width="40%" direction="rtl"
      :before-close="onHideMessageAttachments" :append-to-body="true">
      <div>
        <send-message-to-all-suppliers :query="selected" @sent="getResults()"></send-message-to-all-suppliers>
      </div>
    </el-drawer>

    <div class="row">
      <div class="col-md-12">
        <faca v-if="$store.state.isViewable('BUYER')" class="mb-3" :mandatory="is_faca_mandatory" @uploaded="search()">
        </faca>
      </div>
    </div>
    <div style="text-align: left;" class="row">
      <div class="col-md-12">
        <div class="card m-2">
          <div class="card-header p-0 mx-3 mt-3 position-relative z-index-1">
            <h4>My Procurement Progress</h4>
          </div>
          <div class="p-4 text-sm">
            <p>Welcome to the "My Procurement Progress" page of the CPRAS Procurement Platform. This page serves as a
              central hub for you to access and review your ongoing procurement searches once you have requested final
              pricing from the qualifying suppliers.</p>
            <!-- <p>Below, you will be able to review your initial report and upload any additional documents required by suppliers to present their final offerings. The further competition phase will not be initiated until all documents have been provided by you. </p>
            <p><b>DOCUMENTS REQUIRED FOR EACH LOT:</b>
              <ul>
              <li class="mb-1"><b>For Income Management Systems:</b> No additional files are required.</li>
              <li class="mb-1"><b>For Banking:</b> A form or Excel file with the various pricing elements that we need.</li>
              <li class="mb-1"><b>For Merchant Services:</b> A recent merchant services statement.</li>
              <li class="mb-1"><b>For Call Masking/IVR:</b> No additional files are required.</li>
              <li class="mb-1"><b>For Open Banking Payments:</b> A recent merchant services statement.</li>
              <li class="mb-1"><b>For Open Banking Data:</b> No additional files are required.</li>
              <li class="mb-1"><b>For Procurement Cards:</b> No additional files are required.</li>
              </ul>
            </p> -->
            <p><strong style="color: #ff595e">Please note: You must upload all required documents together and only
                click submit when you are confident all necessary documents are included. The files will be sent
                directly to the suppliers when submitted and there will not be an opportunity for you to add to, edit or
                delete them.</strong></p>
            <p>Once any required documents are submitted, the further competition phase will begin, which typically
              lasts for 10 days, however, suppliers may contact you directly to request an extension. Whether you choose
              to approve this will be at your discretion.</p>
            <p>You will be notified via email when your final report is available and you will be able to access it from
              this page by selecting the 'View Results' button under the 'Action' heading. </p>
            <p>If you have any questions or require any assistance, please contact our support team via the link on your
              Dashboard. We are here to assist you through every stage of the procurement process.</p>
          </div>
          <div class="px-0 pb-2 card-body">
            <div class="row">
              
              <div class="col-md-11">
                <nav class="navbar navbar-expand-lg top-0 z-index-3 w-100 shadow-none my-3 navbar-transparent">
                  <form>
                    <div>
                      <div class="bg-white border-radius-lg d-flex me-2">
                      <input type="text" name="" id="" class="form-control form-control-md border-1 ps-3" v-model="term"
                        placeholder="Search" />
                      <button type="button" class="btn bg-gradient-secondary btn-sm my-1 me-1" @click.prevent="search()">
                        Search
                      </button>
                    </div>
                    </div>
                  </form>
                </nav>
              </div>
              <div class="col-md-1" v-if="$helpers.user() && $helpers.user().is_admin">
                <el-checkbox v-model="view_all" @change="handleViewAll" :true-value="1" :false-value="0"> VIEW ALL</el-checkbox>
              </div>
            </div>
            <div class="table-responsive">
              <div v-if="final_pricing_request == 1" class="mb-2">
                <el-alert :closable="false" type="info">
                  The system is in the process of generating the final pricing for your latest request.
                  Kindly refresh the page or return later to view the results. <el-link @click="getResults"
                    type="warning">Refresh Now</el-link>
                </el-alert>
              </div>
              <table class="table table-fluid align-items-center mb-0">
                <thead class=" text-secondary text-xxs font-weight-bolder opacity-7">
                  <tr>
                    <!-- <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      ID
                    </th> -->
                    <th class="text-uppercase">
                      Category
                    </th>
                    <th class="text-uppercase">
                      Date searched
                    </th>
                    <th class="text-uppercase">
                      Provisional Report
                    </th>
                    <th class="text-uppercase">
                      Final Report
                    </th>
                    <th class="text-uppercase">
                      Further Competition Status
                    </th>
                    <th class="text-uppercase">
                      Additional Statements
                    </th>
                    <th class="text-uppercase">
                      Days Remaining
                    </th>
                    <th class="text-uppercase">
                      Total Days
                    </th>

                    <th class="text-uppercase">
                      Message Attachments
                    </th>
                    <th class="text-uppercase">
                      Notes
                    </th>
                    <th class="text-uppercase">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!results.length">
                    <td v-if="isLoading" colspan="100%" class="text-sm">
                      <i class="fas fa-cog fa-spin me-2"></i>Loading...
                    </td>
                    <td v-else colspan="100%">No Procurement Progress available</td>
                  </tr>
                  <tr v-else v-for="result in results" :key="result.id" class="text-xs">

                    <td>
                      <span>{{ result.category_name }}</span>
                    </td>
                    <td>
                      <span>{{ new Date(result.date_created).toLocaleDateString('en-GB') }}</span>
                    </td>
                    <td>
                      <el-tag type="warning" style="font-size:10px"
                        v-if="!result.process_status && !result.first_generated_file">Generating</el-tag>
                      <div v-else>
                        <div v-if="result.first_generated_file">
                          <a class="link" v-if="result.final_reports && result.final_reports.length > 1"
                            @click="handleShowInitiaReports(result)">
                            <i class="fa-solid fa-file-arrow-down text-secondary"></i> View Provisional Reports
                          </a>
                          <a v-else :href="result.first_generated_file" target="_blank" class="link me-3"
                            v-tooltip="'Download Report'">
                            <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                            file attachment
                          </a>
                        </div>
                        <a class="text-wrap" v-else>
                          Not available yet
                        </a>
                      </div>
                    </td>
                    <td>
                      <div v-if="(result.has_detailed_ccr && result.user_help_request_id) || !result.has_detailed_ccr">
                        <a v-if="result.second_generated_file" @click="downloadFinalReport(result)" class="link me-3"
                          v-tooltip="'Download Report'">
                          <i class="fa-solid fa-file-arrow-down text-secondary"></i>
                          file attachment
                        </a>

                        <a class="text-wrap" v-else>
                          Not available yet
                        </a>
                      </div>
                      <div v-else>
                        <a class="text-wrap">
                          Not available yet
                        </a>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div v-if="result.has_detailed_ccr && !result.user_help_request
      " style="color: #ff6060;">
                          <div class="text-wrap text-bold" style="margin-bottom: 5px">
                            You are REQUIRED to upload additional documentsbefore the competition period can begin</div>
                          <div v-if="result.category_name == 'Banking' || result.category_name == 'Merchant Services'">
                            <a style="cursor: pointer;margin-top: 5px" @click="handleShowComplex(result)">(VIEW REQUIRED
                              DOC/FILES)</a>
                          </div>
                        </div>
                        <span v-else :class="result.status == 2
        ? 'badge badge-success'
        : 'badge badge-warning'
      ">{{
                          result.status == 2 ? 'Completed' : 'In Progress'
                          }}</span>
                        <div
                          v-if="(result.status == 2 || (result.status == 2 && result.has_detailed_ccr && result.user_help_request)) && !result.is_direct_award">
                          <span class="badge badge-info mt-1" v-if="result.offering_notes">{{
                            result.offering_notes.join(", ") }}</span>
                        </div>
                      </div>
                      <span class="badge badge-success" v-if="result.is_direct_award">DIRECT AWARD</span>
                    </td>

                    <td>
                      <div v-if="result.has_detailed_ccr">
                        <div v-if="result.user_help_request" style="text-align: center;">
                          <a :href="
                              result.user_help_request.file_path.length
                                ? result.user_help_request.file_path
                                : result.user_help_request.zip
                            ">Download</a>
                          <div v-if="result.buyer_additional_documents?.length">
                            <a class="link" @click="handleShowAdditionalDocuments(result)">Others</a>
                          </div>
                        </div>
                        <div v-else style="text-align: center;">
                          <a class="link me-3" v-tooltip="'Upload Statements'" @click="showHelpModal(result)">
                            <i class="fa-solid fa-upload text-secondary"></i>
                          </a>
                        </div>
                      </div>
                      <div v-else>Not Applicable</div>
                    </td>
                    <td style="text-align: center;">
                      <span>{{ result.days_remaining_for_re_query }}</span>
                    </td>
                    <td style="text-align: center;">
                      {{ result.total_days_of_procurement }}
                    </td>
                    <td style="text-align: center;">
                      <el-link @click="onShowMessageAttachments(result)" v-if="fcHasStarted(result)">View
                        files</el-link>
                      <span class="disabled" v-else>View files</span>
                    </td>
                    <td style="text-align: center;">
                      <a class="link" v-if="result.params.additional_comments" @click="handleShowNotes(result)">View</a>
                      <span v-else>Not Available</span>
                    </td>

                    <td style="width: 100px">

                      <span v-if="result.process_status">
                        <a v-if="fcHasStarted(result) " style="margin-right: 10px;font-size:19px" class="link"
                          @click="result.status !== 2 && handleMessage(result)"
                          v-tooltip="result.status === 2 ? 'Further competition has ended, messaging is now disabled! Check conversation on messages section' : 'Message'"
                          :disabled="result.status === 2">
                          <i
                            :class="['fas', 'fa-envelope', { 'text-success': result.status !== 2, 'text-secondary': result.status === 2 }]"></i>
                        </a>
                        <span v-else class="disabled" style="font-size:19px;margin-right: 5px">
                          <i class="fas fa-envelope"></i>
                        </span>
                      </span>

                      <a v-if="result.status == 2" style="margin-right: 10px;font-size:19px" class="link me-3"
                        :href="'list/' + result.id + '/view'" v-tooltip="'View Results'">
                        <i class="fa-solid fa-rectangle-list text-info text-lg"></i>
                      </a>
                      <a class="link" style="margin-right: 10px;font-size:19px" @click="showRemove(result)"
                        v-tooltip="'Delete'" v-allowed="'settings, delete'">
                        <i class="fas fa-trash text-danger"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="getResults()" />
          </div>
        </div>
      </div>
    </div>

    <el-dialog id="modal-remove" v-model="dialogVisible" title="Are you sure?" width="500">
      <div v-if="to_remove">
        You are about to remove data no. {{ to_remove.id }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog width="500" v-model="helpModal" title="Please Upload Your Statements" centered>
      <help-component v-if="original" :category_id="original.params.category_id" :buyer="original.buyer"
        :buyer_query_id="original.id" :from_buyer="true" @submitted="closeHelpModal()"></help-component>
    </el-dialog>

    <el-dialog width="500" v-model="showAdditionalDocuments" title="Additional Documents" centered>
      <div v-if="selected">
        <div v-for="document, i in selected.buyer_additional_documents" :key="i">
          <el-link :underline="true" :href="document.url" target="_blank"> {{ document.file_name }} </el-link>
        </div>
      </div>
    </el-dialog>

    <el-dialog width="500" v-model="showNotes" title="Additional Comments" centered>
      <div v-if="selected">
        <p class="small-note"> {{ selected.params.additional_comments }}
        </p>
      </div>
    </el-dialog>

    <el-dialog width="500" v-model="showInitialReports" title="Provisional Reports" centered>
      <div v-if="selected">
        <div v-for="report,i in selected.final_reports" :key="report">
          <a :href="report" target="_blank">Provisional Report {{ i+1 }} <span
              v-if="(i+1) == selected.final_reports.length">(Latest)</span></a>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {
  BuyerSearchQuery
} from '@/api_services/buyer_search_query'
import Modal from '@/components/common/Modal'
import HelpComponent from '../help/help_component.vue'
import Faca from '../dashboard/Faca.vue'
import ProcurementMessageAttachments from './ProcurementMessageAttachments.vue'
import SendMessageToAllSuppliers from '../product_search_messages/SendMessageToAllSuppliers.vue'
import Swal from 'sweetalert2'

export default {
  components: {
    Modal,
    HelpComponent,
    Faca,
    ProcurementMessageAttachments,
    SendMessageToAllSuppliers
  },
  data() {
    return {
      dialogVisible: false,
      isLoading: true,
      results: [],
      view_all: false,
      fields: [
        'id',
        'date_created',
        'provisional_report',
        'final_report',
        'status',
        'action'
      ],
      final_pricing_request: 0,
      selected: null,
      original: null,
      to_remove: null,
      no_company: false,
      term: null,
      meta: {
        page: 1,
        total: 0,
        pages: 1
      },
      is_faca_mandatory: false,
      helpModal: null,
      show_message_attachments: false,
      showMessage: false,
      showComplex: false,
      banking_template: null,
      sample_statement: process.env.VUE_APP_API_ROOT + '/sample_statement.pdf',
      showAdditionalDocuments: false,
      showNotes: false,
      showInitialReports: false
    }
  },
  created: function () {
    this.view_all = this.$route.query.view_all
    this.getUser()
  },
  methods: {
    handleViewAll(){
      this.meta.page = 1
      this.$router.push({ query: { ...this.$route.query, view_all: this.view_all } });
      this.getResults()
    },
    handleShowInitiaReports(result) {
      this.showInitialReports = true
      this.selected = Object.assign({}, result)
    },
    handleShowNotes(result) {
      this.showNotes = true
      this.selected = Object.assign({}, result)
    },
    handleShowAdditionalDocuments(result) {
      this.showAdditionalDocuments = true
      this.selected = Object.assign({}, result)
    },
    fcHasStarted(result) {
      if (result.days_remaining_for_re_query == 'FC not started') return false

      return true
    },
    handleShowComplex(result) {
      this.selected = Object.assign({}, result)
      this.showComplex = true
      if (result.category_name == 'Banking') this.getBankingTemplate()
    },
    handleMessage(result) {
      if (result.status == 2) {
        Swal.fire({
          title: 'Attention!',
          text: "Further Competition is completed, you can no longer initiate conversation with the suppliers",
          icon: 'Info',
          confirmButtonClass: 'btn btn-success',
          buttonsStyling: false
        })
        return false
      }
      this.showMessage = true
      this.selected = Object.assign({}, result)
    },
    onShowMessageAttachments(result) {
      this.selected = Object.assign({}, result)
      this.show_message_attachments = true
    },
    onHideMessageAttachments() {
      this.showComplex = false
      this.showMessage = false
      this.selected = null
      this.show_message_attachments = false
    },
    getBankingTemplate() {
      this.banking_template = null
      BuyerSearchQuery.getBankingTemplate()
        .then(result => {
          this.banking_template = result.data.file
        })
        .catch(error => {

        })
    },
    downloadFinalReport(result) {
      BuyerSearchQuery.downloadFinalReport(result.id)
        .then(result => {
          window.open(result.data.url);
        })
        .catch(error => {

        })
    },
    closeHelpModal() {
      this.original = null
      this.helpModal = false
      this.getResults()
    },
    showHelpModal(result) {
      this.original = Object.assign({}, result)
      this.helpModal = true
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status == 1) return 'table-info'
      return ''
    },
    getUser() {
      let user = this.$store.state.savedUser
      if (!user) return
      if (!user.company) {
        if (user.is_admin) {
          this.no_company = true
          let self = this
          setTimeout(function () {
            self.$router.push('/')
          }, 3000)
        } else {
          this.getResults()
        }
      } else {
        this.getResults()
      }
    },
    search() {
      this.meta.page = 1
      this.getResults()
    },
    remove(bvModalEvt) {
      bvModalEvt.preventDefault()
      BuyerSearchQuery.delete(this.to_remove)
        .then(result => {
          this.$toast.success('Removed successfully')
          this.$nextTick(() => {
            this.dialogVisible = false
          })
          this.getResults()
        })
        .catch(error => {
          this.$toast.error('An error occured while removing search query')
          console.error('error:', error.response.data)
        })
    },
    selectedName(selected) {
      return selected.id ? 'Edit ' + selected.name : 'New'
    },
    showRemove(type) {
      this.to_remove = Object.assign({}, type)
      this.dialogVisible = true
    },
    getResults() {
      this.globalState.loading = true
      this.isLoading = true
      this.showMessage = false
      BuyerSearchQuery.get({
          params: {
            page: this.meta.page,
            view_all: this.view_all
          }
        })
        .then(result => {
          this.is_faca_mandatory = result.data.is_faca_mandatory
          if (result.data.results) this.results = result.data.results
          this.meta = result.data.meta
          this.final_pricing_request = result.data.final_pricing_request
          if (this.$route.query.searched) {
            this.showLoadingScreen()
          } else {
            this.isLoading = false
            this.globalState.loading = false
          }
        })
        .catch(error => {
          this.isLoading = false
          this.globalState.loading = false
          this.$toast.error('An error occured while fetching search queries')
          console.error('error:', error.response.data)
        })
        .finally(() => {
          // this.isLoading = false
        })
    },
    showLoadingScreen() {
      let self = this
      this.isLoading = true
      this.globalState.loading = true

      setTimeout(function () {
        self.isLoading = false
        self.globalState.loading = false
        self.$router.push({ path: self.$route.path, query: {} });
      }, 5000)
    }
  }
}
</script>
