

<template>
  <div >
    <div class="row">
        <div class="col-md-12">
       
            <div><strong>Project Name:</strong> {{ form.name }}</div>
            <div>
                <div><strong>Project Type : </strong> {{ form.project_type == 0 ? 'Standard Procurement':'Framework Agreement' }}</div>
                <div v-if="form.project_type == 0">
                    <strong>{{ form.standard_type == 0 ? 'Open Procedure':'Competitive Flexible Procedure (CFP)' }} </strong> 
                </div>
                <div v-if="form.project_type == 1">
                    <strong>{{ form.agreement_type == 0 ? 'Open Framework':'Closed Framework' }}</strong> 
                    <div v-if="form.agreement_type == 0">
                        <div v-if="form.reopen_regular_interval == 1">
                            <span >Regular interval: {{ $helpers.getRecurringIntervalLabel(form.recurring_interval) }} </span> <span v-if="form.can_re_open"> (Manual Reopen)</span>
                        </div>
                        <div v-else>
                            <div v-if="form.first_input_dates && form.first_input_dates.length">
                                <div>Reopening schedule for the first 3 years</div>
                                <div v-for="dateItem, i in form.first_input_dates" :key="i">{{
                                    form.first_input_dates.length > 1 ? $helpers.getOrdenalSuffix(i+1)+':' :
                                    '' }} {{ dateItem.date }}</div>
                            </div>
                            <div v-if="form.second_input_dates && form.second_input_dates.length">
                                <div>Reopening schedule for the period after the first 3 years</div>
                                <div v-for="dateItem, i in form.second_input_dates" :key="i">{{
                                    form.second_input_dates.length > 1 ?
                                    $helpers.getOrdenalSuffix(i+1)+':':'' }} {{ dateItem.date }}</div>
                            </div>

                            <!-- <span >1st Interval: {{ $helpers.getRecurringIntervalLabel(form.recurring_interval) }}</span>
                                    <div v-for="other,i in other_recurring_intervals" :key="i">
                                        {{ $helpers.getOrdenalSuffix(i+2) }} Interval: {{ $helpers.getRecurringIntervalLabel(other) }}
                                    </div> -->
                        </div>
                        
                    </div>
                </div>
                <div>Contact Value: {{ form.contact_value }}</div>

                <div> <span v-if="form.can_re_open && form.project_type == 1"> Manual Reopen, </span> <span>{{ form.threshold == 0 ? 'Above-Threshold':'Below-Threshold'}}</span> <span v-if="form.project_type == 1">({{ form.standard_type == 0 ? 'Open Procedure':'Competitive Flexible Procedure' }})</span></div>
            </div>
           
            <div>
                <br>
                {{ form.description }}
            </div>
            <hr>
        </div>
        <div class="col-md-4">
            <div><strong>Main Description</strong></div>
            <div v-html="form.main_description"></div>

            <div><strong>Common Description</strong></div>
            <div v-html="form.common_description"></div>
        </div>
        <div class="col-md-4">
            <div><strong>My Files Description</strong></div>
            <div v-html="form.my_files_description"></div>
            <div><strong>My Questions Description</strong></div>
            <div v-html="form.my_questions_description"></div>
        </div>
        <div class="col-md-4">
            <div><strong>Specification Description</strong></div>
            <div v-html="form.spec_description"></div>
            <div><strong>Q&A Description</strong></div>
            <div v-html="form.qa_description"></div>
        </div>
    </div>
</div>
</template>

<script>


export default {
    props: ['form'],
    data() {
        return {
        
        }
    },
    created: function() {
        if (this.form.pin_notice) {
            this.form.common_description = this.form.pin_notice.common_description
            this.form.main_description = this.form.pin_notice.main_description
            this.form.my_files_description = this.form.pin_notice.my_files_description
            this.form.my_questions_description = this.form.pin_notice.my_questions_description
            this.form.qa_description = this.form.pin_notice.qa_description
            this.form.spec_description = this.form.pin_notice.spec_description
        }
    },
    methods: {
        
    }
}
</script>
