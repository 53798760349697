<style>
.el-aside{
  overflow: visible !important
}
.el-footer{
  height: auto !important;
  /*background-color: whitesmoke;*/
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
<template>
  
    <el-row class="pt-3 pb-2">
    <el-col class="" :xs="24" :sm="24" :md="5" :lg="3" :xl="3">

      <div class="row">
          <div class="col-md-10 p-0">
              <div class=" text-center">
                
                <el-avatar shape="square" :size="100"> 
                  <b class="h1">{{ reply_data.user?.substring(0, 1).toUpperCase() }}</b> </el-avatar>
                  <p class="text-sm text-bolder text-dark mt-3 mb-1">{{ reply_data.user }}</p>
                  <p class="text-sm text-dark mt-0">{{ reply_data?.user_role }}</p>
              </div>
              <div class="col-md-4 text-center"></div>
            </div>
        </div>

    </el-col>
    <el-col :xs="24" :sm="24" :md="19" :lg="21" :xl="21">

      <div class="row flex-column h-100">
        <div class="col flex-grow-0">
          <div class="row">
            <div class="col-md-10">
              <p class="mb-1">
                <el-text class="mx-1" size="default"><i class="fa fa-clock"></i>&nbsp;
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="$filters.datetimeFormatFilter(reply_data.created_at)"
                  placement="right-end"
              >
              <timeago class="text-sm" :autoUpdate="60" :datetime="reply_data?.created_at"/>
              </el-tooltip></el-text>
              </p>
            </div>
            <div class="col-md-2">
              <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Delete"
                        placement="top"
                    >
              <el-button 
              v-if="($store.state.checkUserGroupPermissions('forum-reply', 'delete', false) && user?.company?.id == reply_data.company_id) || $store.state.isAdmin() || reply_data.user_id == user?.id"
              @click="deleteReply(reply_data)" size="small" class="float-end mb-2" type="danger"><i class="fa fa-trash"></i></el-button>
            </el-tooltip>
            <el-tooltip
                        class="box-item"
                        effect="dark"
                        content="Edit"
                        placement="top"
                    >
              <el-button 
              v-if="($store.state.checkUserGroupPermissions('forum-reply', 'edit', false) && user?.company?.id == reply_data.company_id) || $store.state.isAdmin() || reply_data.user_id == user?.id"
              @click="edit(reply_data)" size="small" class="float-end mb-2 me-2" type="info"><i class="fa fa-edit"></i></el-button>
            </el-tooltip>
            </div>
          </div>
        </div>
        <div class="col flex-fill">
          <div class="row">
            <div class="col-md-12">
              <ReplyBodyForEditor :is_replying="false" :replying_content="reply_data.target_replies"></ReplyBodyForEditor>
            </div>
              
              <div class="col-md-12">
                <div v-html="reply_data.content"></div>
                <div v-if="reply_data.attachments">
                  <br/>
                  <ElUpload 
                  :key="'upload_reply_item_' + upload_counter"
                  :from_reply="true"
                  :show_icon="false" :drag="false" v-model="reply_data.attachments"
                  :data="reply_data.attachments"></ElUpload>
                </div>
                
              </div>
          </div>
        </div>
        <div class="col flex-grow-0">
          <el-divider class="mt-0 mb-0" />
          <div class="row">
              <div class="col-md-7 pt-2">
                <el-button link type="primary" @click="viewLikes(reply_data)" v-if="reply_data.total_likes > 0" class="text-sm "> 
                  <i class="fa fa-thumbs-up me-1"></i>
                  {{ (reply_data?.user_liked_this_reply && reply_data?.user_liked_this_reply?.status == 1) ? "You" : reply_data?.latest_like }} 
                  {{ (reply_data.total_likes > 1) ? (" and " + (reply_data.total_likes - 1) ) + " other" + ( (reply_data.total_likes - 1) > 1 ? "s" : "" ) : "" }}</el-button>
              </div>
              <div class="col-md-5 ">
                <el-button 
                  v-if="$store.state.checkUserGroupPermissions('forum-reply', 'reply_to_other', true)"
                  :disabled="!done_edit"
                  @click="replyClick(reply_data)" 
                  size="small" 
                  class="float-end mt-2" 
                  type="success">
                  <i class="ni ni-send"></i>&nbsp;Reply
                </el-button>

                <el-button 
                  v-if="$store.state.checkUserGroupPermissions('forum-reply', 'like', true)"
                  @click="likeClick(reply_data)" 
                  size="small" 
                  class="float-end mt-2 me-1" 
                  :type="(reply_data?.user_liked_this_reply && reply_data?.user_liked_this_reply?.status == 1) ? 'primary' : ''">
                  <i :class="(reply_data?.user_liked_this_reply && reply_data?.user_liked_this_reply?.status == 1) ? 'fa fa-thumbs-up' : ''"></i>&nbsp;
                  {{ (reply_data?.user_liked_this_reply && reply_data?.user_liked_this_reply?.status == 1) ? 'Liked' : 'Like' }}
                </el-button>
              </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>

  <el-divider class="mt-2 mb-3" />
</template>

<script>
  import ReplyBodyForEditor from '../../pages/forum/ReplyBodyForEditor.vue'
  import ElUpload from '../ElUpload'
  import { Forum } from '@/api_services/forum'
  import Swal from 'sweetalert2'

  export default {
    components: {
      ReplyBodyForEditor, ElUpload
    },
    props: {
      data: {
        type: Object,
        required: true,
        default: []
      },
      if_done_edit: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        done_edit: true,
        upload_counter: 1,
        reply_data: {},
        like_form: {
          like_id: null,
          like_type: 0,
          thread_id: null,
          reply_id: null,
          status: 1
        }
      } 
    },
    created: function () {
      if(this.data){
        this.reply_data = this.data
        this.like_form.like_id = this.reply_data?.user_liked_this_reply?.id
      }
      this.done_edit = this.if_done_edit
    },
    watch: {
      data: {
          handler(value){
            if(value){
              this.reply_data = value
              this.upload_counter++
            }
          },
          deep:true
      },
      if_done_edit: {
          handler(value){
            this.done_edit = value
          },
          deep:true
        },
    },
    computed: {
      user() {
        return this.$store.state.savedUser // get state
      }
    },
    methods: {
      deleteReply(data){
        
        Swal.fire({
          icon: 'warning',
          title: 'Remove this reply?',
          text:
            'Permanently remove this reply including its uploaded files?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',  
          }).then(result => {
                    if (result.isConfirmed) {
                      this.globalState.loading = true
                      let delete_form = {
                        id: data.id,
                        delete_target: 0
                      }

                      Forum.delete(delete_form)
                      .then(result => {
                        this.$toast.success("Successfully removed!")
                        this.$emit("delete_reply", data)
                      })
                      .catch(error => {
                        console.log(error)
                      })
                      .finally(() => {
                        this.globalState.loading = false
                      })
                    }
                    else{
                        return false
                    }
                })
      },
      edit(data){
        this.$emit("edit", data)
      },
      viewLikes(data){
        this.$emit("view_likes", { id: data.id, type: "reply" })
      },
      likeClick(){
        this.globalState.loading = true
        this.like_form.reply_id = this.reply_data.id

        if(this.reply_data?.user_liked_this_reply?.status){
          if(this.reply_data?.user_liked_this_reply?.status == 1)
            this.like_form.status = 0
          else
            this.like_form.status = 1
        }
        else{
          this.like_form.status = 1
        }

        Forum.updateLike(this.like_form)
        .then(result => {
          this.$toast.success(this.like_form.status ? "Liked!" : "Unliked")
          this.reply_data = result.data.returned_data
          this.like_form.like_id = this.reply_data?.user_liked_this_reply?.id
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.globalState.loading = false
        })
      },
      replyClick(data){
        this.$emit("reply", data)
      }
    }
  }
</script>
