<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <label>How will the documents be provided?</label>
                    <div :class="{ 'has-error-field': v.form.further_documents_method.$invalid }">
                        <el-input v-model="form.further_documents_method" type="textarea" :rows="3"></el-input>
                    </div>
                    <div v-if="v.form.further_documents_method.$invalid" class="error-text" >Information is required</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required,
     requiredIf
     } from '@vuelidate/validators'
 
     const DOCUMENTS = 'DOCUMENTS';

     export default {
         props: ['previous_form', 'saved_form', 'tpp_procurement_type', 'is_not_submitted'],
         data() {
             return {
                form: {
                    upload_file: null,
                    web_address: null,
                    description: null,
                    provide_further_documents: null,
                    further_documents_method: null,
                    web_address_technical: null,
                    description_technical: null
                },
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form
        },
        computed: {
            ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    further_documents_method: {
                        required,
                        $autoDirty: true
                    }
                }
            }

            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', DOCUMENTS, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            }
        }
    }

 </script>