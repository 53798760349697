<template>
    <div>
      <h6 v-if="!if_at_tn_publish" class="text-dark">Sub-Stages: {{ title }}</h6>
      <div class="row">
        <div class="col-md-12">
          <div class="card my-3">
            
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <el-button v-if="(!if_read_on_can_edit || !read_only) && !edit_but_cant_add" class="float-end mb-2 me-2" type="primary" @click="openForm"><i class="fa fa-save"></i>&nbsp;Add Stage</el-button>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr class="text-sm">
                      <th>Name</th>
                      <th>Description</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th v-if="!if_at_tn_publish">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!stage_list.length">
                      <td v-if="globalState.loading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No stages</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="(stage, index) in stage_list" :key="stage.id">
                      <td>{{ stage?.stage_name }}</td>
                      <td>{{ stage?.description }}</td>
                      <td>{{ $filters.dateFormatFilter(stage.start_date) }}</td>
                      <td>{{ $filters.dateFormatFilter(stage.end_date) }}</td>
                      <td v-if="!if_at_tn_publish">
                        <template v-if="stage.status == 0">
                          <el-tag type="info">Pending</el-tag>
                        </template>
                        <template v-else-if="stage.status == 1">
                            <el-tag type="primary">On-Going</el-tag>
                        </template>
                        <template v-else-if="stage.status == 2">
                          <el-tag type="success">Done</el-tag>
                        </template>
                        <template v-else-if="stage.status == 3">
                          <el-tag type="danger">Canceled</el-tag>
                        </template>
                      </td>
                      <td>
                        <el-button-group v-if="!read_only">
                          <el-tooltip :enterable="false" placement="top-start">
                            <template #content>
                              <span>Edit</span>
                            </template>

                            <el-button type="primary" size="small"
                              @click="editSubStage(stage, index)">
                              <i class="fa fa-edit"></i></el-button>
                          </el-tooltip>

                          <el-tooltip v-if="!if_read_on_can_edit" :enterable="false" placement="top-start">
                            <template #content>
                              <span>Delete</span>
                            </template>

                            <el-button type="danger" size="small"
                              @click="deleteStage(stage)">
                              <i class="fa fa-trash"></i></el-button>
                          </el-tooltip>
                        </el-button-group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    
    <el-dialog id="publish-modal" :destroy-on-close="true" :close-on-click-modal="false" v-model="form_modal" width="40%">
        <el-form label-position="top" @submit.prevent="submitForm">
            <el-text size="large" class="mb-3">{{ !form.id ? 'Add' : 'Edit' }} Stage</el-text>
            <br>
            <br>
            <div v-if="(form.id && !if_at_tn_publish && (form.status == 0 || form.status == 1))" class="form-group">
                <label>Status:</label><br>
                <el-select v-model="status_select">
                  <el-option :value="0" label="&nbsp;" />
                  <el-option :disabled="(form.status == 0 && form.index == 0) ? false : true" :value="1" label="On-Going" />
                  <el-option v-if="form.status == 1" :value="2" label="Done" />
                    <el-option v-if="form.status == 1" :value="3" label="Canceled" />
                </el-select>

            </div>  

            <div v-if="!if_read_on_can_edit" class="form-group">
                <label>Name:</label><br>
                <el-input v-model="form.stage_name"></el-input>

            </div>  
            <div class="form-group">
                <label>Description:</label><br>
                <el-input type="textarea" v-model="form.description"></el-input>

            </div>   
            <div class="form-group">
                <label>Date Range (From & To):</label><br>
                <el-date-picker
                    @change="dateChange"
                    :disabled-date="disabledDate"
                    :clearable="false"
                    :editable="false"
                    v-model="form.date_range"
                    type="daterange"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    format="MMM-DD-YYYY"
                    value-format="YYYY-MM-DD"
                />
            </div>

            <el-button native-type="submit" type="success"><i class="fa fa-save"></i>&nbsp;Save</el-button>
        </el-form>
        
        <template #footer>
        <div class="dialog-footer">
            <el-button @click="form_modal = false">Close</el-button>
        </div>
        </template>
    </el-dialog>

    
  </template>
  
  <script>

import {
  IttFramework
} from '@/api_services/itt_framework'
import Swal from 'sweetalert2'

  export default {
    emits: ['save'],
    watch: {
        
    },
    props: {
        title: String,
        tender_notice_id: Number,
        if_at_tn_publish: {
          type: Number,
          required: false,
          default: 0
        },
        if_read_on_can_edit: {
          type: Boolean,
          required: false,
          default: false
        },
        project_data: {
          type: Object,
          required: false,
          default: []
        },
        read_only: {
          type: Boolean,
          required: false,
          default: false
        },
        edit_but_cant_add: {
          type: Boolean,
          required: false,
          default: false
        }
    },
    components: {
      
    },
    
    data() {
      return {
        status_select: null,
        prev_date_range: null,
        form_modal: false,
        stage_list: [],
        form: {
            id: null,
            tender_notice_id: this.tender_notice_id,
            stage_name: null,
            description: null,
            date_range: null,
            status: 0,
            type: "manage",
            is_manual_status_update: 0
        }
      }
    },
    created: function() {
        this.loadSubStages()
    },
    methods: {
      checkIfOverlaped(a_start, a_end, b_start, b_end) {
          if (a_start < b_start && b_start < a_end) return true
          if (a_start < b_end   && b_end   < a_end) return true
          if (b_start <  a_start && a_end   <  b_end) return true
          return false
      },
      dateChange(date){
        let self = this
        let start_date = new Date(date[0])
        start_date.setHours(0, 0, 0, 0)

        let end_date = new Date(date[1])
        end_date.setHours(0, 0, 0, 0)

        let if_overlap = 0
        this.stage_list.forEach(function(stage){
          let stage_start_date = new Date(stage.start_date)
          stage_start_date.setHours(0, 0, 0, 0)

          let stage_end_date = new Date(stage.end_date)
          stage_end_date.setHours(0, 0, 0, 0)
          
          if(self.checkIfOverlaped(start_date, end_date, stage_start_date, stage_end_date)){
            if_overlap = 1
            if(self.form?.id && stage.id == self.form?.id){
              if_overlap = 0
            }
          }
        })

        if(if_overlap == 1){
          this.$toast.warning("Date selected overlaped to exsisting stages dates. Please select another range of date!")
          this.form.date_range = this.prev_date_range
        }
        
      },
      deleteStage(data){
            let param = {
              type: "delete",
              id: data.id
            }

            Swal.fire({
                icon: 'info',
                text: 'Delete this stage?',
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                cancelButtonText: 'Cancel',
            }).then(result => {
                if (result.isConfirmed) {

                    this.globalState.loading = true
                    IttFramework.manageSubStage(param)
                    .then(result => {
                        this.$toast.success('Successfully deleted!')
                        this.loadSubStages()
                        this.form_modal = false
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.globalState.loading = false
                    })

                }
            })
        },
        editSubStage(stage_data, index){
          var stage = stage_data
          this.form = Object.assign({}, stage)
          this.form.date_range = []
          this.form.date_range[0] = this.form.start_date
          this.form.date_range[1] = this.form.end_date  
          this.prev_date_range = this.form.date_range
          this.form.index = index
          this.status_select = this.form.status

          this.form_modal = true
        },
        openForm(){
            this.form = {
                id: null,
                tender_notice_id: this.tender_notice_id,
                stage_name: null,
                description: null,
                date_range: null,
                status: 0,
                type: "manage"
            }
            this.status_select = this.form.status
            this.form_modal = true
        },
        disabledDate(date){
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            let self = this

            let if_disabled = null
            let start_date_arr = []

            this.stage_list.forEach(function(stage){
              
              let start_date = new Date(stage.start_date)
              start_date.setHours(0, 0, 0, 0)

              let end_date = new Date(stage.end_date)
              end_date.setHours(0, 0, 0, 0)

              if(!start_date_arr.includes(stage.start_date)){
                start_date_arr.push(stage.start_date)
              }

              if(date > start_date && date < end_date){
                if(if_disabled == null){
                  if_disabled = 1
                }

                if(self.form?.id && stage.id == self.form?.id){
                  if_disabled = 0
                }
              }
            })

            if(date < today){
              let converted_date = [
                date.getFullYear(),
                ('0' + (date.getMonth() + 1)).slice(-2),
                ('0' + date.getDate()).slice(-2)
              ].join('-')

              if(!start_date_arr.includes(converted_date)) return true
            }

            if(if_disabled == 1){
              return true
            }

            return false
        },
        async submitForm(){
            this.form.type = "manage"
            if(!this.form.stage_name || !this.form.date_range){
                this.$toast.warning("Please fill out the form!")
                return false
            }

            Swal.fire({
                icon: 'info',
                text: 'Save this stage?',
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                cancelButtonText: 'Cancel',
            }).then(result => {
                if (result.isConfirmed) {
                  if(this.if_at_tn_publish == 1 && this.stage_list.length > 0){
                    this.form.is_manual_status_update = 1
                  }
                  
                    this.form.status = this.status_select

                    this.globalState.loading = true
                    IttFramework.manageSubStage(this.form)
                    .then(result => {
                        this.$toast.success('Successfully saved!')
                        this.loadSubStages()
                        this.form_modal = false
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.globalState.loading = false
                    })

                }
            })
        },
        loadSubStages() {
            this.stage_list = []
            let params = {
                id: this.tender_notice_id,
            }
            
            this.globalState.loading = true
            this.loading = true
            IttFramework.loadSubStages(params)
            .then(result => {
                if(result.data.tender_notice_sub_stages){
                  this.stage_list = result.data.tender_notice_sub_stages
                  if(this.stage_list.length > 0){
                    this.$emit('save', true)
                  }
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.globalState.loading = false
            })
        },
    },
    computed: {
  
    }
  }
  </script>
  