<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="row">
                    <div class="col-md-5">
                        <label>Has the engagement period already ended?</label>
                        <div :class="{ 'has-error-field': v.form.has_engagement_period_ended.$invalid }">
                            <el-radio-group v-model="form.has_engagement_period_ended" @change="() => { form.engagement_deadline = null}">
                                <el-radio :value="true">Yes</el-radio>
                                <el-radio :value="false">No</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.has_engagement_period_ended.$invalid" class="error-text" >Engagement deadline is required</div>
                    </div>
                    <div class="col-md-5">
                        <label>Engagement deadline</label>
                        <label style="color: #A9A9A9">When the engagement period {{ form.has_engagement_period_ended ? 'ended.' : 'will end.' }}</label>
                        <div :class="{ 'has-error-field': v.form.engagement_deadline.$invalid }" style="width: 224px">
                            <el-date-picker v-model="form.engagement_deadline" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                        </div>
                    <div v-if="v.form.engagement_deadline.$invalid" class="error-text" >Engagement deadline is required</div>
                    </div>
                </div>
                <div class="col-md-12">
                    <br/><label>Engagement process description</label>
                    <br/><label style="color: #A9A9A9">Include information on the scope of the engagement and the dates of any events.</label>
                    <div :class="{ 'has-error-field': v.form.process_description.$invalid }">
                        <el-input v-model="form.process_description" type="textarea" :rows="3"></el-input>
                    </div>
                    <div v-if="v.form.process_description.$invalid" class="error-text" >Engagement description is required</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, numeric,
     requiredIf
     } from '@vuelidate/validators'
 
     const ENGAGEMENT = 'ENGAGEMENT';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted'],
         data() {
             return {
                form: {
                    has_engagement_period_ended: null, 
                    engagement_deadline: null, 
                    process_description: null
                },
                update: 0
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form

        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    has_engagement_period_ended: {
                        required,
                        $autoDirty: true
                    },
                    engagement_deadline: {
                        required,
                        $autoDirty: true
                    },
                    process_description: {
                        required,
                        $autoDirty: true
                    }
                }
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', ENGAGEMENT, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            disablePastDates(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);

                if(this.form.has_engagement_period_ended)
                return date > today;
                else return date < today
            },
        }
     }
 </script>