<template>
  <div>
    <a
      id="notifications-dropdown-btn"
      href="#"
      class="p-0 nav-link text-white"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
    <el-badge :value="unreadNotifications" class="item" :hidden="!unreadNotifications">
      <i class="cursor-pointer fa fa-bell"></i>
    </el-badge>
          </a>
    <ul
      id="notifications-dropdown-menu"
      class="dropdown-menu px-2 py-3  dropdown-menu-end me-sm-n4 "
      aria-labelledby="notifications-dropdown-btn"
      role="menu"
    >
      <li v-if="!notifications.length">
        <span class="dropdown-item border-radius-md">
          No New Notifications
        </span>
      </li>
      <li v-for="notification in notifications" :key="notification.id">
        <a
          class="dropdown-item border-radius-md"
          :class="{ unseen: !notification.seen }"
          @click="seenNotif(notification)"
        >
          <div class="text-bolder small" style="font-size: 0.875rem;">
            {{ notification.title }}
          </div>
          <div style="min-width: 200px; font-size: 0.75rem;">
            {{ limitedMessage(notification.message) }}
          </div>
          <div class="timeAgo">
            {{ convertTimestampToDate(notification.timestamp) }}
          </div>
        </a>
      </li>
      <li v-if="notifications.length">
        <div
          class="dropdown-item"
          style="background-color: transparent;"
          @click="deleteAllNotifications()"
        >
          <button type="button" class="btn mb-0 btn-primary btn-sm w-100">
            Clear all
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import { User } from '@/api_services/user'
import { db } from '@/firebase'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)

window.$ = window.jQuery = require("jquery");

export default {
  computed: {
    unreadMessagesCount() {
      var count = 0
      this.notifications.forEach(function(notification) {
        if (!notification.seen) count++
      })
      return count
    }
  },
  data() {
    return {
      unreadNotifications: 0,
      notifications: [],
      doneInitFirebase: false,
      user: this.$store.state.savedUser
    }
  },
  created: function() {
    this.user = this.$store.state.savedUser
    if (!this.doneInitFirebase) this.initialiazeFirebase()
    this.getNotifications()
  },

  methods: {
    countUnseenNotifications() {
      let path = 'notifications/' + this.user.name

      db.ref(path)
      .orderByChild("seen")
      .equalTo(false)
      .on("value", (snapshot) => {
        const count = snapshot.numChildren();
        this.unreadNotifications = count
      });
    },
    seenNotif(notification) {
      if (!notification.seen) {
        var ref = db.ref(
          'notifications/' + this.user.name + '/' + notification.id
        )
        notification.seen = true
        ref.set(notification)
      }

      if (notification.link) {
        window.location.href = notification.link
      }
    },
    goTo(notification) {
      if (notification.link && notification.link !== this.$route.path) {
        this.$router.push(notification.link)
      }
    },
    openNotification() {
      this.unreadNotifications = 0
    },
    initialiazeFirebase() {
      let self = this
      const messaging = firebase.messaging()
      let firebaseKeyPair = process.env.VUE_APP_FIREBASE_KEY_PAIR

      messaging
        .getToken({ vapidKey: firebaseKeyPair })
        .then(currentToken => {
          if (currentToken) {
            self.updateUserFcm(currentToken)
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            )
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err)
        })
        .finally(() => {
          this.doneInitFirebase = true
        })

      messaging.onMessage(function(payload) {
        console.log('Receiving foreground message')
        // self.showNotification(payload.notification, payload.data)
      })
    },
    showNotification(notification, data) {
      if (data) notification.link = data.link
      this.notify(notification)
    },
    updateUserFcm(token) {
      User.updateFcm({ device_key: token })
        .then(result => {
          console.log("FCM token updated")
        })
        .catch(() => {})
    },
    deleteAllNotifications() {
      var ref = db.ref('notifications/' + this.user.name)
      ref.set([])
    },
    convertTimestampToDate(time) {
      const timeAgo = new TimeAgo()
      if (time === undefined || time === null) return null
      // var date = new Date(time.seconds * 1000)
      var date = new Date(time * 1000)
      return timeAgo.format(date)
    },
    limitedMessage(message) {
      if (message.length > 50) return message.substring(0, 50) + '...'
      return message
    },
    addNotificationFromFirebase(id, notification) {
      var seen = false
      if (notification.seen) seen = notification.seen
      var obj = {
        id: id,
        user_name: notification.user_name,
        title: notification.title,
        message: notification.message,
        link: notification.link,
        timestamp: notification.timestamp,
        seen: seen
      }
      this.notifications.push(obj)

      this.notifications = this.notifications.sort(function (a, b) {
        return b.timestamp - a.timestamp
      })
    },
    getNotifications() {
      var self = this
      if (!this.user) return false
      let path = 'notifications/' + this.user.name

      this.countUnseenNotifications()
      var ref = db.ref(path)

      ref
        .orderByChild('timestamp')
        .limitToLast(10) // Adjust the limit as needed
        .on("value", (snapshot) => {
          const data = snapshot.val();
          self.notifications = []
          for (var i in data) {
            self.addNotificationFromFirebase(i, data[i])
          }
        });
  
      ref
      .orderByChild('timestamp')
      .limitToLast(1)
      .on("child_added", (snapshot) => {
        const notification = snapshot.val();
        var notificationDate = new Date(notification.timestamp * 1000)
        var currentDate = new Date()
        const differenceSec = Math.abs(currentDate - notificationDate) / 1000;
        
        if (differenceSec < 3){
          this.showNotification(notification)
          this.$store.commit('newNotifications', true)
        }
      });
    },
    notify(notification) {
      this.$toast.open({
        message:
          "<div class='cpras-notification-title'>" +
          notification.title +
          '</div><div class="cpras-notification-body">' +
          notification.message +
          '</div>',
        type: 'info',
        dismissible: true
      })
    }
  }
}
</script>
<style></style>
