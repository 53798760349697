<template>
    <el-form label-position="top">
        <div class="row">
            <div  class="col-md-3"></div>
            <div  class="col-md-6">
                <div>
                    <h5>What do you want to do ?</h5>

                    <div :class="{ 'has-error-field': v$.project_category.$errors.length }">
                        <el-radio-group v-model="form.project_category">
                            <el-radio :value="0" size="large">Public Contracts</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v$.project_category.$errors.length" class="error-text">Select a contract</div>
                </div>
                
                <div style="padding-top: 20px" v-if="form.project_category != null">
                    <label class="large-label">What type of Procurement Project?</label>
                    <br>
                    <div :class="{ 'has-error-field': v$.project_type.$errors.length }">
                        <el-radio-group v-model="form.project_type">
                            <el-radio :value="0" size="large">Standard Procurement</el-radio>
                            <el-radio :value="1" size="large">Framework Agreement</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v$.project_type.$errors.length" class="error-text">Select a project type</div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import { reactive, ref, watch, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import dayjs from 'dayjs'

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    emits: ['update:form'],
    setup(props, { emit }) {

        const rules = computed(() => {
            return {
                project_category: {
                    required,
                    minValue: minValue(0),
                    $autoDirty: true
                },
                project_type: {
                    required,
                    minValue: minValue(0),
                    $autoDirty: true
                },
            }
        })

        const v$ = useVuelidate(rules, props.form)

        watch(() => props.form, (newVal) => emit('update:form', newVal), { deep: true })

        return { v$ }
    }
}
</script>

<style scoped>

</style>