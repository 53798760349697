<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <label>Is this procurement under a special regime? (optional)</label>
                    <br/><label style="color: #a9a9a9">Select all that apply.</label>
                    <span class="row"><el-checkbox v-model="form.is_concession" :checked="form.is_concession" @change="concessionChange()" :key="update">Concession <br/><span style="color: #a9a9a9">Main procurement category must be services or works.</span></el-checkbox></span>
                    <span class="row"><el-checkbox v-model="form.is_defense_and_security" :checked="form.is_defense_and_security" @change="defenseChange()" :key="update">Defence and security</el-checkbox></span>
                    <span class="row"><el-checkbox v-model="form.is_light_touch" :checked="form.is_light_touch" @change="lightTouchChange()" :key="update">Light touch<br/><span style="color: #a9a9a9">Main procurement category must be services.</span></el-checkbox></span>
                    <span class="row"><el-checkbox v-model="form.is_utilities" :checked="form.is_utilities" @change="utilityChange()" :key="update">Utilities</el-checkbox></span>
                    <span class="row">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OR</span>
                    <span class="row"><el-checkbox v-model="form.is_no_special_regime" :checked="form.is_no_special_regime" @change="noRegimeChange()" :key="update">No special regime</el-checkbox></span>
                </div>
                <div class="col-md-12">
                    <label>What is the main procurement category?</label>
                    <br/><label style="color: #a9a9a9">Select the main type if more than one applies</label>
                    <div :class="{ 'has-error-field': v.form.procurement_category.$invalid }">
                        <el-radio-group v-model="form.procurement_category">
                            <el-radio :disabled="form.is_concession || form.is_light_touch" :value="0">Goods</el-radio>
                            <el-radio :value="1">Services</el-radio>
                            <el-radio :disabled="form.is_light_touch" :value="2">Works</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.procurement_category.$invalid" class="error-text" >Category is required</div>
                </div>
                <div class="col-md-12">
                    <br/><el-checkbox v-model="form.has_addl_category" :checked="form.has_addl_category">An additional procurement category with a lower threshold is included
                        <br/><label style="color: #a9a9a9">If so then the lower threshold applies. For example, if goods are included in a contract mainly for works.
                            If so then the lower threshold applies. For example, if goods are included in a contract mainly for works.
                        </label>
                    </el-checkbox>
                </div>
                <div class="col-md-12" v-if="form.has_addl_category">
                    <label>What is the additional procurement category?</label>
                    <br/><label style="color: #a9a9a9">Select the main type if more than one applies</label>
                    <div :class="{ 'has-error-field': v.form.addl_category.$invalid }" style="width: 230px;">
                        <el-radio-group v-model="form.addl_category">
                            <el-radio :value="0" v-if="form.procurement_category !== 0">Goods</el-radio>
                            <el-radio :value="1" v-if="form.procurement_category !== 1">Services</el-radio>
                            <el-radio :value="2" v-if="form.procurement_category !== 2">Works</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.addl_category.$invalid" class="error-text" >Category is required</div>
                </div>
                <div class="col-md-12" v-if="tpp_procurement_type == 'UK2'">
                    <br/><label>Is the total value of the procurement above or below the relevant threshold? (optional)</label>
                    <div class="row">
                        <el-radio-group v-model="form.threshold" :disabled="true" border>
                            <el-radio :value="0">Above threshold</el-radio>
                            <el-radio :value="1">Below threshold</el-radio>
                        </el-radio-group>
                        <div class="col-md-3">
                            <el-button type="primary" @click="form.threshold = null">Clear selection</el-button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="tpp_procurement_type == 'UK4' || tpp_procurement_type == 'UK3'">
                    <br/><label>What is the procedure type?</label>
                    <div class="row">
                        <div :class="{ 'has-error-field': v.form.threshold.$invalid }">
                        <el-radio-group v-model="form.threshold" :disabled="true" border class="col-md-4">
                            <el-radio :value="0">Open procedure (above threshold)</el-radio>
                            <el-radio :value="1">Competitive flexible procedure (above threshold)
                                <br/><label style="color: #a9a9a9">Including if a dynamic market is being used above threshold.</label>
                            </el-radio>
                            <el-radio :value="2">Below threshold</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v.form.threshold.$invalid" class="error-text" >Threshold is required</div>
                </div>
                <div class="row" v-if="(tpp_procurement_type == 'UK4' || tpp_procurement_type == 'UK3') && (form.threshold === 0 || form.threshold === 1)">
                    <div class="col-md-12" v-if="previous_form">
                        <br/><label>Procedure type</label>
                        <br/><label>{{ procurementCategory }}</label>
                    </div>
                    <div class="col-md-12">
                        <!-- TODO: DYNAMIC MARKET ??? -->
                    </div>
                    <div class="col-md-12" v-if="form.threshold === 1">
                        <br/><label v-if="tpp_procurement_type == 'UK4'">Is this notice inviting requests to participate or tenders?</label>
                        <label v-if="tpp_procurement_type == 'UK3'">Will the tender notice invite requests to participate or tenders?</label>
                        <div :class="{ 'has-error-field': v.form.is_inviting_requests.$invalid }">
                            <el-radio-group v-model="form.is_inviting_requests" border>
                                <el-radio :value="0">Request to participate</el-radio>
                                <el-radio :value="1">Tenders</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.is_inviting_requests.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-12" v-if="form.threshold === 1">
                        <br/><label>Competitive flexible procedure description</label>
                        <br/><label style="color: #a9a9a9">Include the steps that will be followed.</label>
                        <div :class="{ 'has-error-field': v.form.competitive_desc.$invalid }">
                            <el-input type="textarea" :rows="3" v-model="form.competitive_desc"></el-input>
                        </div>
                        <div v-if="v.form.competitive_desc.$invalid" class="error-text" >Procedure description is required</div>
                    </div>
                    <div class="row" v-if="form.threshold === 0 || form.threshold === 1">
                        <div class="col-md-12">
                            <br/><label>Is a reduced tendering period being used?</label>
                            <br/><label style="color: #a9a9a9">Starting the day after tenders are invited and ending the day they must be submitted by. Normal minimum is 25 days, with electronic submission and if all associated tender documents are provided with the tender notice.</label>
                            <div :class="{ 'has-error-field': v.form.reduced_tendering_period.$invalid }" style="width: 240px">
                                <el-radio-group v-model="form.reduced_tendering_period" border>
                                    <el-radio :value="true">Yes</el-radio>
                                    <el-radio :value="false">No</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.reduced_tendering_period.$invalid" class="error-text" >Yes/No is required</div>
                        </div>
                        <div class="col-md-6" v-if="form.reduced_tendering_period">
                            <br/><label>Justification for reduced tendering period</label>
                            <div :class="{ 'has-error-field': v.form.reduced_justification.$invalid }">
                                <el-radio-group v-model="form.reduced_justification" border>
                                    <el-radio :value="0" v-if="form.is_light_touch && !(form.is_concession || form.is_defense_and_security || form.is_utilities)">
                                        Light touch contract - no minimum
                                    </el-radio>
                                    <el-radio :value="1" v-if="(form.threshold === 1 && form.is_utilities) || (form.threshold === 1 && !isPublicGovernement)">
                                        Negotiated tendering period - utilities contract or not central government - no minimum 
                                    </el-radio>
                                    <el-radio :value="2" v-if="(form.threshold === 1 && form.is_utilities) || (form.threshold === 1 && !isPublicGovernement)">
                                        Pre-selected tenderers - utilities contract or not central government - minimum 10 days
                                    </el-radio>
                                    <el-radio :value="3" v-if="false">Qualifying planned procurement notice - minimum 10 days</el-radio>
                                    <el-radio :value="4">State of urgency - minimum 10 days</el-radio>
                                    <el-radio :value="5" v-if="form.is_dynamic_market">Using dynamic market - minimum 10 days</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.reduced_justification.$invalid" class="error-text" >Justification is required</div>
                        </div>
                    </div>

                    <div class="col-md-6" v-if="(form.threshold === 0 || form.threshold === 1) && (tpp_procurement_type === TPPNoticeTypes.UK4.value || tpp_procurement_type == 'UK3')">
                        <br/><label>Has preliminary market engagement been carried out?</label>
                        <div :class="{ 'has-error-field': v.form.market_engagement_carried.$invalid }"  style="width: 240px">
                            <el-radio-group v-model="form.market_engagement_carried" border>
                                <el-radio :value="true">Yes</el-radio>
                                <el-radio :value="false">No</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.market_engagement_carried.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-6" v-if="!form.market_engagement_carried && !isAllUtilities">
                        <br/><label>Justification for not publishing a preliminary market engagement notice</label>
                        <div :class="{ 'has-error-field': v.form.market_engagement_justification.$invalid }">
                            <el-input v-model="form.market_engagement_justification" type="textarea" :rows="3"></el-input>
                        </div>
                        <div v-if="v.form.market_engagement_justification.$invalid" class="error-text" >Justification is required</div>
                    </div>
                </div>
            </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required,
     requiredIf
     } from '@vuelidate/validators'
    import { TPPNoticeTypes } from '../../../../constants';
 
     const PROCEDURE = 'PROCEDURE';

     export default {
         props: ['previous_form', 'procurement', 'saved_form', 'is_not_submitted', 'tpp_procurement_type', 'contracting_authorities'],
         data() {
             return {
                form: {
                    is_concession: null, 
                    is_defense_and_security: null, 
                    is_utilities: null, 
                    is_no_special_regime: null, 
                    procurement_category: null, 
                    threshold: null,
                    has_addl_category: null,
                    addl_category: null,
                    //UK4
                    reduced_tendering_period: null,
                    reduced_justification: null,
                    market_engagement_carried: null,
                    market_engagement_justification: null,
                    is_inviting_requests: null,
                    is_dynamic_market: null,
                    competitive_desc: null
                },
                update: 0
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form
            else this.initValues()
        },
        computed: {
            ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
            procurementCategory() {
                if(this.previous_form) {
                    switch(this.previous_form.procurement_category) {
                        case 0: return 'Goods'
                        case 1: return 'Services'
                        case 2: return 'Works'
                        default: return 'Not Available'
                    }
                }
                
                return 'Not Available'
            },
            isPublicGovernement() {
                let flag = false
                this.contracting_authorities.forEach(auth => {
                    auth.organisation_type === 0
                    flag = true
                })

                return flag
            },
            isAllUtilities() {
                let flag = true
                this.contracting_authorities.forEach(auth => {
                    auth.organisation_type !== 3
                    flag = false
                })

                return flag
            }
            
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    procurement_category: {
                        required,
                        $autoDirty: true
                    },
                    addl_category: {
                        required: requiredIf(function() { return this.form.has_addl_category}),
                        $autoDirty: true
                    },

                }
            }


            if(this.tpp_procurement_type == this.TPPNoticeTypes.UK4.value || this.tpp_procurement_type == this.TPPNoticeTypes.UK3.value) {
                let uk4 = {
                    threshold: {
                        required,
                        $autoDirty: true
                    },
                    reduced_tendering_period: {
                        required,
                        $autoDirty: true
                    },
                    reduced_justification: {
                        required: requiredIf(function () {return this.form.reduced_tendering_period}),
                        $autoDirty: true
                    },
                    market_engagement_carried: {
                        required,
                        $autoDirty: true
                    },
                    market_engagement_justification: {
                        required: requiredIf(function () { return !this.form.market_engagement_carried }),
                        $autoDirty: true
                    },
                    competitive_desc: {
                        required: requiredIf(function () { return this.form.threshold === 1}),
                        $autoDirty: true
                    },
                    is_inviting_requests: {
                        required: requiredIf(function () { return this.form.threshold === 1 && !this.form.is_dynamic_market}),
                        $autoDirty: true
                    }
                }

                rules.form = {...rules.form, ...uk4}
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', PROCEDURE, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            concessionChange() {
                if(this.form.procurement_category === 0)this.form.procurement_category = null
                this.form.is_no_special_regime = null
                this.update++
            },
            defenseChange() {
                this.form.is_no_special_regime = null
                this.update++
            },
            lightTouchChange() {
                this.form.procurement_category = null
                this.form.is_no_special_regime = null
                if(this.form.is_light_touch)this.form.procurement_category = 1
                this.update++
            },
            utilityChange() {
                this.form.is_no_special_regime = null
                this.update++
            },
            noRegimeChange() {
                this.form.is_concession = null
                this.form.is_defense_and_security = null
                this.form.is_utilities = null
                this.form.is_light_touch = null
                this.update++
            },
            initValues() {
                if(this.procurement)
                {
                    if(this.tpp_procurement_type == TPPNoticeTypes.UK2.value)
                    {
                        this.form.threshold = this.procurement.threshold
                    }

                    else if(this.tpp_procurement_type == TPPNoticeTypes.UK4.value || this.tpp_procurement_type == TPPNoticeTypes.UK3.value)
                    {
                        if(this.procurement.threshold === 0) 
                        {
                            if(this.procurement.standard_type === 0) this.form.threshold = 0
                            else this.form.threshold = 1
                        }
                        else if(this.procurement.threshold === 1) this.form.threshold = 2 
                    }
                }
            },
        }

     }
 </script>