<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                 <div class="row" v-if="procedure?.threshold === 2"> <!-- if below threshold -->
                    <div class="col-md-6">
                        <label>Will full details of the award criteria or a simple description of them be included in this notice?</label>
                        <div :class="{ 'has-error-field': v.form.full_or_simple.$invalid }">
                            <el-radio-group v-model="form.full_or_simple">
                                <el-radio :value="0">Full Details</el-radio>
                                <el-radio :value="1">Simple description</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.full_or_simple.$invalid" class="error-text" >Full details or simple is required</div>
                    </div>
                    <div class="col-md-6" v-if="form.full_or_simple === 1">
                        <label>Simple description of award criteria</label>
                        <br/><label style="color: #a9a9a9">Provide sufficient information to understand how the award decision will be made.</label>
                        <div :class="{ 'has-error-field': v.form.simple_description.$invalid }">
                            <el-input v-model="form.simple_description" type="textarea" :rows="3"></el-input>
                        </div>
                        <div v-if="v.form.simple_description.$invalid" class="error-text" >Full details or simple is required</div>
                    </div>
                </div>
                <div class="row" v-if="procedure && (procedure.threshold === 0 || procedure.threshold === 1) || form.full_or_simple === 1" style="margin-bottom: 30px">
                    <div class="col-md-4">
                        <label>Weighting type</label>
                        <div :class="{ 'has-error-field': v.form.weighting_type.$invalid }">
                            <el-radio-group v-model="form.weighting_type">
                                <el-radio :value="0">Percentage</el-radio>
                                <el-radio :value="1">Order of importance</el-radio>
                                <el-radio :value="2">Description</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v.form.weighting_type.$invalid" class="error-text" >Weighting type is required</div>
                    </div>
                    <div class="col-md-8" v-if="form.weighting_type === 2">
                        <label>Weighting description</label>
                        <div :class="{ 'has-error-field': v.form.weighting_description.$invalid }">
                            <el-input v-model="form.weighting_description" type="textarea" :rows="3"></el-input>
                        </div>
                        <div v-if="v.form.weighting_description.$invalid" class="error-text" >Weighting description is required and should be equal to 100 is required</div>
                    </div>
                </div>
                <div class="row" v-for="(criterion, index) in form.criterion" v-if="(procedure.threshold === 0 || procedure.threshold === 1 || form.full_or_simple === 0)">
                    <hr style="margin-top: 10px"/>
                    <label>Criteria {{ index + 1 }}</label>
                    <div class="col-md-12">
                        <label>Name</label>
                        <div :class="{ 'has-error-field': !criterion.name}">
                            <el-input v-model="form.criterion[index].name"></el-input>
                        </div>
                        <div v-if="!criterion.name" class="error-text" >Name is required</div>
                    </div>
                    <div class="col-md-12">
                        <br/><label>Type</label>
                        <label style="color: #a9a9a9">For example, select price for the unit cost of an item, cost for future maintenance costs, or quality for social value and other non-financial criteria.</label>
                        <br/>
                        <div :class="{ 'has-error-field': !criterion.type && criterion.type !== 0}">    
                            <el-radio-group v-model="form.criterion[index].type">
                                <el-radio :value="0">Cost</el-radio>
                                <el-radio :value="1">Price</el-radio>
                                <el-radio :value="2">Quality</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="!criterion.type && criterion.type !== 0" class="error-text" >Type is required</div>
                    </div>
                    <div class="col-md-12">
                        <br/><label>Description (optional)</label>
                        <el-input v-model="form.criterion[index].description" type="textarea" :rows="3"></el-input>
                    </div>
                    <div class="col-md-4" v-if=" form.weighting_type === 0">
                        <br/><label>Percentage weighting</label>
                        <div :class="{ 'has-error-field': (!criterion.percentage || !passedPercentEqual100) && this.form.weighting_type === 0 }">
                            <el-input v-model="form.criterion[index].percentage" type="number"></el-input>
                        </div>
                        <div v-if="!passedPercentEqual100 && this.form.weighting_type === 0" class="error-text" >Percentage weight is required and equal 100</div>
                    </div>
                    <div class="col-md-4" v-if="form.weighting_type === 1">
                        <br/><label>Order of importance</label>
                        <label style="color: #a9a9a9">Enter sequential numbers, starting with 1 for most important criterion.</label>
                        <div :class="{ 'has-error-field': !criterion.importance && this.form.weighting_type === 1 }">
                            <el-input v-model="form.criterion[index].importance"></el-input>
                        </div>
                        <div v-if="!criterion.importance && this.form.weighting_type === 1" class="error-text" >Order of importance is required and equal 100</div>
                    </div>
                </div>
                <br/><el-button @click="addCriterion()" class="col-md-3" style="margin-top: 20px" type="primary">Add criterion</el-button>                    
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" class="mb-3" :disabled="v.form.$invalid || !passedCustomValidation" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, numeric,
     requiredIf
     } from '@vuelidate/validators'
import { toHandlers } from 'vue';
 
     const AWARD_CRITERIA = 'AWARD_CRITERIA';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'procedure', 'lot_id'],
         data() {
             return {
                form: {
                    lot_id: null,
                    full_or_simple: null,
                    simple_description: null,
                    criterion: [{
                        name: null,
                        type: null,
                        description: null,
                        percentage: null,
                        importance: null
                    }],
                    weighting_type: null,
                    weighting_description: null
                },
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form
            console.log('lots', this.lot_id)
        },
        computed: {
            ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
            passedCustomValidation() {
                let passed = true
                let percentage = 0
                this.form.criterion.forEach(cri => {
                    console.log(this.form.weighting_type, cri.name, cri.type, cri.percentage, cri.importance)
                    if(!cri.name ||
                    (!cri.type && cri.type !== 0) || 
                    (!cri.percentage && this.form.weighting_type === 0) ||
                    !cri.importance && this.form.weighting_type === 1) passed = false
                    else {
                        if(cri.percentage && this.form.weighting_type === 0)
                        {
                            console.log('pass')
                            percentage = percentage + parseFloat(cri.percentage)
                            if(percentage != 100) passed = false
                        }
                    }
                })
                
                
                return passed
            },
            passedPercentEqual100() {
                let percentage = 0
                this.form.criterion.forEach(cri => {
                    if(!cri.percentage && this.form.weighting_type === 0) return false

                        percentage += parseFloat(cri.percentage)
                })

                return percentage == 100
            }
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    full_or_simple: {
                        required: requiredIf(function () { return this.procedure && (this.procedure.threshold !== 0 && this.procedure.threshold !== 1)}),
                        $autoDirty: true
                    },
                    simple_description: {
                        required: requiredIf(function () { return this.form.full_or_simple === 1}),
                        $autoDirty: true
                    },
                    weighting_type: {
                        required: requiredIf(function () { return (this.procedure && (this.procedure.threshold === 0 || this.procedure.threshold === 1) || this.form.full_or_simple === 1)} ),
                        $autoDirty: true
                    },
                    weighting_description: {
                        required: requiredIf(function () { return this.form.weighting_type === 2 }),
                        $autoDirty: true
                    },
                }
            }
            return rules
        },
        methods: {
            saveForm() {
                this.form.lot_id = this.lot_id
                this.$emit('save', AWARD_CRITERIA, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            addCriterion() {
                this.form.criterion.push({
                    name: null,
                    type: null,
                    description: null,
                    percentage: null,
                    importance: null
             })
            }
        }
     }
 </script>