<style>
.el-segmented{
  padding: 0px !important;
}

.el-segmented__item{
  margin: 0px !important;
  font-weight: normal !important;
  font-size: 14px !important;
  padding: 6px !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  border: 1px solid #dcdfe6
}


.el-segmented__item.is-selected{
  font-weight: bold !important;
}

</style>

<template>

  <div v-loading="loading">
    <div class="row">
      <div class="col-md-12">

        <el-affix :offset="20">
          <div class="card mb-2 " style="border-bottom-left-radius: 0px !important;border-bottom-right-radius: 0px !important;">
            <div class="card-body">
              <div class="row">
                <div class="col-md-11">
                  <h4 ><i class="fa fa-info-circle"></i> {{ overview_project?.name }}</h4>
                </div>
                <div class="col-md-1">
                  <el-tooltip :enterable="false" placement="top-start">
                            <template #content>
                              <span>View History</span>
                            </template>
                            <el-button @click="viewProjectHistory(overview_project)" class="float-end border-0" circle >
                              <el-icon>
                                <template #default>
                                  <i class="fa fa-history"></i>
                                </template>
                              </el-icon>
                            </el-button>
                  </el-tooltip>
                  
                </div>
              </div>
              
              <p>{{ overview_project?.description }}</p>

              <div>
                  <div><strong>Project Type : </strong> {{ overview_project?.project_type == 0 ? 'Standard Procurement':'Framework Agreement' }}</div>
                  <div v-if="overview_project?.project_type == 0">
                      <strong>{{ overview_project?.standard_type == 0 ? 'Open Procedure':'Competitive Flexible Procedure (CFP)' }} </strong> 
                  </div>
                  <div v-if="overview_project?.project_type == 1">
                      <strong>{{ overview_project?.agreement_type == 0 ? 'Open Framework':'Closed Framework' }}</strong> 
                      <div v-if="overview_project?.agreement_type == 0">
                          <div v-if="overview_project?.reopen_regular_interval == 1">
                              <span ><strong>Regular interval:</strong> {{ $helpers.getRecurringIntervalLabel(overview_project?.recurring_interval) }} </span> <span v-if="overview_project?.can_re_open"> (Manual Reopen)</span>
                          </div>
                          <div v-else>
                              <div v-if="overview_project?.first_input_dates && overview_project?.first_input_dates.length">
                                  <div>Reopening schedule for the first 3 years</div>
                                  <div v-for="dateItem, i in overview_project?.first_input_dates" :key="i">{{
                                      overview_project?.first_input_dates.length > 1 ? $helpers.getOrdenalSuffix(i+1)+':' :
                                      '' }} {{ dateItem.date }}</div>
                              </div>
                              <div v-if="overview_project?.second_input_dates && overview_project?.second_input_dates.length">
                                  <div>Reopening schedule for the period after the first 3 years</div>
                                  <div v-for="dateItem, i in overview_project?.second_input_dates" :key="i">{{
                                      overview_project?.second_input_dates.length > 1 ?
                                      $helpers.getOrdenalSuffix(i+1)+':':'' }} {{ dateItem.date }}</div>
                              </div>

                          </div>
                          
                      </div>
                  </div>
                  <div><strong>Contract Value:</strong> {{ overview_project?.contact_value }}</div>

                  <div> <span v-if="overview_project?.can_re_open && overview_project?.project_type == 1"> <strong>Additional Details: </strong> Manual Reopen, </span> <span>{{ overview_project?.threshold == 0 ? 'Above-Threshold':'Below-Threshold'}}</span> <span v-if="overview_project?.project_type == 1">({{ overview_project?.standard_type == 0 ? 'Open Procedure':'Competitive Flexible Procedure' }})</span></div>
                  <div><strong>Status:</strong> {{ overview_project?.status_name }}</div>
                  <div><strong>Date Created:</strong> {{ $filters.dateFormatFilter(overview_project?.created_at) }}</div>
              </div>
              <div class="row">
                <div class="col-md-8 mt-3">
                  <div v-if="if_from_settings == 0 && 
                        (
                          $store.state.isAdmin() ||
                          overview_project?.user?.id == user.id || 
                          user?.company?.id == overview_project?.company?.id || 
                          company_ids.includes(overview_project?.company?.id) 
                          )">

                      <el-button-group>
                        <el-button  v-if="overview_project?.status == ProcurementProjectStatuses.CODE_PENDING || (overview_project?.status == ProcurementProjectStatuses.CODE_PRE_PIN || overview_project?.status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED)" @click="openPublicationModal(overview_project)" type="primary">
                          <i class="fa fa-"></i>
                          Publish 
                          {{ overview_project?.status == ProcurementProjectStatuses.CODE_PENDING ? 'Preliminary Market Engagement (PME)' : '' }}
                          {{ (overview_project?.status == ProcurementProjectStatuses.CODE_PRE_PIN || overview_project?.status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED) ? 'Planned Procurement Notice (PPN)' : '' }}
                        </el-button>

                        <el-tooltip v-if="$store.state.checkUserGroupPermissions('project', 'view_details', false, true, 'overview_project', 'update')" :enterable="false" placement="top-start">
                          <template #content>
                            <span>Timeline</span>
                          </template>

                          <el-button v-allowed="'overview_project_timeline,view'" type="warning" 
                            @click="openTimeline(overview_project)">
                            <i class="fa fa-timeline"></i></el-button>
                        </el-tooltip>

                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Manage</span>
                          </template>

                          <el-button type="primary" 
                            @click="manageProject(overview_project)">
                            <i class="fa fa-edit"></i></el-button>
                        </el-tooltip>

                        <el-tooltip v-if="$store.state.checkUserGroupPermissions('project', 'view_ppn', true)" :enterable="false" placement="top-start">
                          <template #content>
                            <span>View Information</span>
                          </template>

                          <el-button type="success"  @click="viewPIN(overview_project)">
                            <i class="fa fa-eye"></i></el-button>
                        </el-tooltip>

                        <el-tooltip v-if="$store.state.checkUserGroupPermissions('project', 'view_ppn_participants', true)" :enterable="false" placement="top-start">
                          <template #content>
                            <span>View Participants</span>
                          </template>

                          <el-button type="info" 
                            @click="viewParticipants(overview_project?.pin_notice.id, 'pin')">
                            <i class="fa fa-list"></i>&nbsp;({{ overview_project?.total_pin_participants
                            }})</el-button>
                        </el-tooltip>

                        <el-tooltip v-if="$store.state.checkUserGroupPermissions('project', 'view_tn_participants', true)" :enterable="false" placement="top-start">
                          <template #content>
                            <span>View TN Participants</span>
                          </template>

                          <el-button type="primary" 
                            @click="viewParticipants(overview_project?.itt_framework?.id, 'itt')">
                            <i class="fa fa-list"></i>&nbsp; ({{ overview_project?.total_itt_framework_participants
                            }})</el-button>
                        </el-tooltip>

                        <el-tooltip v-if="$store.state.checkUserGroupPermissions('project', 'view_history', true)" :enterable="false" placement="top-start">
                          <template #content>
                            <span>View History</span>
                          </template>

                          <el-button type="warning"  @click="viewProjectHistory(overview_project)">
                            <i class="fa fa-history"></i></el-button>
                        </el-tooltip>

                        <el-tooltip v-if="$store.state.checkUserGroupPermissions('project', 'remove', false, true, 'overview_project', 'delete')" :enterable="false" placement="top-start">
                          <template #content>
                            <span>Delete</span>
                          </template>

                          <el-button type="danger" 
                            @click="showRemove(overview_project)">
                            <i class="fa fa-trash"></i></el-button>
                        </el-tooltip>

                      </el-button-group>

                    </div>
                </div>
                <div class="col-md-4 mt-3">
                  <el-button-group class=" float-end">
                    <el-button @click="viewActiveStage"><i class="fa fa-list"></i>&nbsp;View Active Stage</el-button>
                    <el-button @click="viewNotices" type="success"><i class="fa fa-file-o"></i>&nbsp;View Notices</el-button>
                  </el-button-group>
                </div>
              </div>

              <div class="mt-5 p-0 card-header">
                <hr>
              <div class="row">
                <div class="col-md-2">
                  <el-input v-on:keyup.enter="loadProcurementProjects" v-model="search_value" placeholder="Search" class="input-with-select">
                    <template #append>
                      <el-button @click="loadProcurementProjects"><i class="fa fa-search"></i></el-button>
                    </template>
                  </el-input>
                </div>
                <div class="col-md-5">
                  <el-segmented @change="filterChange" v-model="selected_filter" :options="filter_options" />
                </div>
                <div class="col-md-5" style="text-align: right;">
                  <button type="button" class="btn bg-gradient-secondary btn-sm mb-0 me-2"
                    @click="loadProcurementProjects()">
                    <i class="fa-solid fa-arrow-rotate-right me-1"></i> Reload
                  </button>
                  <button v-if="$store.state.checkUserGroupPermissions('project', 'import', true)" type="button" class="btn btn-info btn-sm mb-0 me-2" @click="import_modal = true">
                    <i class="fa-solid fa-upload me-1"></i> Import
                  </button>
                  <button v-if="($store.state.checkUserGroupPermissions('project', 'create', true))" type="button" class="btn btn-primary btn-sm mb-0 me-2"
                    @click="addProject">
                    <i class="fa-solid fa-plus me-1"></i> Add
                  </button>
                </div>
              </div>
            </div>
              
            </div>
          </div>  
        </el-affix>

        <div class="card" style="border-top-left-radius: 0px !important;border-top-right-radius: 0px !important;">
          <div class="card-body">
            <div 
            v-if="
              ($store.state.checkUserGroupPermissions('project', 'view_list', true))
            "
            class="table-responsive" v-loading="loading">


            <el-table :row-class-name="highlightRow" class="table-striped" header-row-class-name="text-primary" :data="procurement_projects" style="width: 100%">
              
              <el-table-column label="Type">
                <template v-slot="props">
                  {{ props.row.name }}

                      <div v-if="props.row.itt_framework && props.row.itt_framework.re_opened_pin_notice_id" class="notes-text">
                        RE-OPENED
                      </div>
                </template>
              </el-table-column>
              <el-table-column label="Type">
                <template v-slot="props">
                  <div>
                          <div><strong></strong> {{ props.row.project_type == 0 ? 'Standard Procurement':'Framework Agreement' }}</div>
                          <div v-if="props.row.project_type == 0">
                              <strong>{{ props.row.standard_type == 0 ? 'Open Procedure':'Competitive Flexible Procedure (CFP)' }} </strong> 
                          </div>
                          <div v-if="props.row.project_type == 1">
                              <strong>{{ props.row.agreement_type == 0 ? 'Open Framework':'Closed Framework' }}</strong> 
                          </div>
                          
                      </div>

                      <div><a class="link" @click="handleDetails(props.row)">(More Details)</a></div>
                </template>
              </el-table-column>
              <el-table-column label="Date Created">
                <template v-slot="props">
                  {{ $filters.dateFormatFilter(props.row.created_at) }}
                </template>
              </el-table-column>
              <el-table-column label="Status">
                <template v-slot="props">
                  <el-tag v-tooltip="props.row.status_name" :type="props.row.status_tag.color"
                        :effect="props.row.status_tag.type">{{ props.row.status_name }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column v-if="if_from_settings == 1" label="">
                <template v-slot="props">
                  <el-tooltip :enterable="false" placement="top-start">
                        <template #content>
                          <span>View Participants</span>
                        </template>

                        <el-button type="success" size="small"
                          @click="viewParticipants(props.row.pin_notice.id, 'pin')">
                          <i class="fa fa-eye"></i>&nbsp;View Participants ({{ props.row.pin_participants
                          ? Object.keys(props.row.pin_participants).length : 0 }})</el-button>
                      </el-tooltip>

                      <el-tooltip :enterable="false" placement="top-start">
                        <template #content>
                          <span>View TN Participants</span>
                        </template>

                        <el-button type="primary" size="small"
                          @click="viewParticipants(props.row?.itt_framework?.id, 'itt')">
                          <i class="fa fa-eye"></i>&nbsp;View TN Participants ({{ props.row.itt_participants
                          ? Object.keys(props.row.itt_participants).length : 0 }})</el-button>
                      </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column v-if="selected_filter != ProjectFilters.FILTER_MY" label="Creator">
                <template v-slot="props">
                  <div :class="props.row?.user?.id == user.id ? 'text-info text-bold' : ''">{{ props.row?.user?.name }}</div>
                </template>
              </el-table-column>
              <el-table-column v-if="selected_filter != ProjectFilters.FILTER_MY" prop="company.name" label="Company" />\
              <el-table-column v-if="if_from_settings == 0" :min-width="22" label="">
                <template v-slot="props">
                  <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Show Project Overview</span>
                          </template>
                          <el-button @click="showOverview(props.row)" color="#32a852"  type="info" circle :icon="InfoFilled" plain />
                </el-tooltip>
                  
                </template>
              </el-table-column>
            </el-table>
            </div>
            <div v-else>
              <el-empty description="Your group doesnt have access to this list!" />
            </div>
          </div>
          <div class="card-footer pt-0">
            <pagination :meta="meta" v-on:update="loadProcurementProjects()" />
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="if_from_settings == 1 && if_has_leave_request == 1">
      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h5>PPN Leave Request</h5>
            <LeaveParticipantRequestList :type="'pin'"></LeaveParticipantRequestList>
          </div>
        </div>

      </div>
      <div class="col-md-6 mt-3">
        <div class="card">
          <div class="card-body">
            <h5>Tender Notice Leave Request</h5>
            <LeaveParticipantRequestList :type="'itt'"></LeaveParticipantRequestList>
          </div>
        </div>
      </div>
    </div>

    <el-dialog id="modal-remove" v-model="dialogVisible" title="Confirm Delete?" width="500"
      :before-close="handleClose">
      <div v-if="to_remove">
        You are about to remove this procurement project {{ to_remove.name }}, Proceed?
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button v-bind:disabled="loading" type="danger" @click="remove">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog id="add-remove" v-model="add_modal" width="60%" :destroy-on-close="true">
      <ProcurementProjectsForm v-if="selected && selected.id" :key="'project_' + selected.id" @save="afterSave" @cancel="handleClose"
        :selected="selected" @reload="loadProcurementProjects" @fullEdit="handleShowFullEdit"></ProcurementProjectsForm>

        <procurement-stepper :selected="selected" @save="loadProcurementProjects" v-if="selected && !selected.id"></procurement-stepper>
    </el-dialog>

    <el-dialog id="add-remove" v-model="showFullEdit" width="60%" :destroy-on-close="true">
        <procurement-stepper :selected="selected" @save="loadProcurementProjects" v-if="selected"></procurement-stepper>
    </el-dialog>

  </div>

  <el-drawer v-model="showForm" :show-close="true" size="80%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div v-if="pin_notice" class="container">
      <PinNoticeView :view_itt="1" :selected_framework="pin_notice"></PinNoticeView>
    </div>
  </el-drawer>

  <el-drawer v-model="history_drawer" :show-close="true" size="40%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div v-if="selected" class="container">
      <ProcurementProjectHistory :procurement_project_id="selected.id" :name="selected.name">
      </ProcurementProjectHistory>
    </div>
  </el-drawer>

  <el-drawer v-model="participant_drawer" :show-close="true" size="40%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <ParticipantsList :id="participant_form.id" :type="participant_form.type"></ParticipantsList>
    </div>
  </el-drawer>

  <el-dialog destroy-on-close v-model="import_modal" title="Upload File" width="500">
    <form @submit="uploadFile" v-loading="loading">
      <p>Please upload an .xlsx file. You can use the below template for the importing.<br>

        <el-button-group>
          <el-link class="me-3" type="success" link href="/template/import_project_template_official.xlsx">Sample Excel
            Template</el-link>
        </el-button-group>
      </p>

      <dropzone label="Drop or select file" :max_files="1" :clean="0" :multiple="false" extensions="'xlsx,xls'"
        @input="setFile($event)"></dropzone>
      <button type="submit" class="btn btn-success" v-loading="loading" :disabled="!import_form.file">
        {{ loading ? 'Saving' : 'Save' }}
      </button>
    </form>
  </el-dialog>

  <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="false" v-model="error_modal" width="50%">
    <ul class="p-4 list-group">
      <template v-for="(message,i) in error_message" :key="i">
        <li v-if="message != '' " class="list-group-item"><i class="text-danger fa fa-info-circle"></i>&nbsp;{{ message
          }}</li>
      </template>
    </ul>
  </el-dialog>

  <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="true" v-model="showDetails" width="50%">
    <div v-if="selected">
      <procurement-project-details :form="selected"></procurement-project-details>
    </div>
  </el-dialog>

  <el-drawer v-model="timeline_drawer" :show-close="true" size="70%" direction="rtl" :destroy-on-close="true"
    :append-to-body="true">
    <div class="container">
      <TimelineList :procurement_project="selected"></TimelineList>
    </div>
  </el-drawer>

  <el-dialog :destroy-on-close="true" id="publish-modal" :close-on-click-modal="false" v-model="publish_modal" width="40%">
    <ProjectPublication
      :project="selected_project"
      @cancel="handleClose"
      @save="afterSave"
      @load-projects="loadProcurementProjects"
    ></ProjectPublication>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="publish_modal = false">Close</el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog :destroy-on-close="true" id="publish-modal" :close-on-click-modal="true" v-model="showCompanyProcurementForm" width="40%">
      <company-procurement-setting-form v-if="companyForm" v-model:form="companyForm" @save="updateProcurementSetting" @close="showCompanyProcurementForm = false" :type="procurementApiKeyFormType"></company-procurement-setting-form>
  </el-dialog>

  <el-dialog destroy-on-close :close-on-click-modal="false" v-model="notices_modal" width="40%">

    <a-list :locale="{emptyText: 'No notices yet!'}" item-layout="horizontal" :data-source="overview_project?.procurement_project_all_forms">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-list-item-meta>
          <template #description>
            {{ item.submission_id + ' ('+ NOTICE_STATUS[item.overall_form_status] +')' }}
          </template>
            <template #title>
              <a>{{ item.procurement_type + ': ' + NOTICE_DESCRIPTION[item.procurement_type] }}</a>
            </template>
            <template #avatar>
              <a-avatar style="background-color: rgb(177.3, 179.4, 183.6)" v-if="item.overall_form_status == 0">
                <template #icon>
                  <EllipsisOutlined />
                </template>
              </a-avatar>

              <a-avatar style="background-color: rgb(121.3, 187.1, 255)" v-if="item.overall_form_status == 1">
                <template #icon>
                  <FieldTimeOutlined />
                </template>
              </a-avatar>

              <a-avatar style="background-color: rgb(51.2, 126.4, 204)" v-if="item.overall_form_status == 2">
                <template #icon>
                  <DeliveredProcedureOutlined />
                </template>
              </a-avatar>

              <a-avatar style="background-color: #67C23A" v-if="item.overall_form_status == 3">
                <template #icon>
                  <CheckCircleOutlined />
                </template>
              </a-avatar>

              <a-avatar style="background-color: #F56C6C" v-if="item.overall_form_status == 9">
                <template #icon>
                  <WarningOutlined />
                </template>
              </a-avatar>
            </template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </el-dialog>

</template>
    
  <script>
    import { ProcurementProjects } from '@/api_services/procurement_projects'
    import ProcurementProjectsForm from './ProcurementProjectsForm.vue'
    import PinNoticeView from '../pin_notice/PinNoticeView'
    import { PinNotices } from '@/api_services/pin_notices'
    import ProcurementProjectHistory from './ProcurementProjectHistory.vue'
    import ParticipantsList from './ParticipantsList.vue'
    import LeaveParticipantRequestList from './LeaveParticipantRequestList.vue'
    import { mapState } from 'vuex'
    import Dropzone from '@/components/common/Dropzone'
    import ProcurementStepper from './ProcurementStepper.vue'
    import TimelineList from './timeline/TimelineList.vue'
    import ProcurementProjectDetails from './ProcurementProjectDetails.vue'
    import ProjectPublication from './ProjectPublication.vue'
    import CompanyProcurementSettingForm from '../supplier_setting/CompanyProcurementSettingForm.vue'
    import { Clock, InfoFilled } from '@element-plus/icons-vue'
    import { shallowRef } from 'vue'
    import { EllipsisOutlined, DeliveredProcedureOutlined, FieldTimeOutlined, CheckCircleOutlined, WarningOutlined } from '@ant-design/icons-vue'

    export default {
      computed: {
        ...mapState(['ProjectFilters', 'ProcurementProjectStatuses', 'NOTICE_DESCRIPTION', 'NOTICE_STATUS']),
        user () {
          return this.$store.state.savedUser // get state
        }
      }, 
      props: {
        if_from_settings: {
          required: false,
          default: 0
        },
        if_has_leave_request: {
          required: false,
          default: 0
        },
      },
      emits: ["save", "cancel"],
      components: {
        ProcurementProjectsForm, PinNoticeView, ProcurementProjectHistory, ParticipantsList, LeaveParticipantRequestList, Dropzone,
            ProcurementStepper, TimelineList,
            ProcurementProjectDetails, ProjectPublication,
            CompanyProcurementSettingForm,
            EllipsisOutlined, DeliveredProcedureOutlined, FieldTimeOutlined, CheckCircleOutlined, WarningOutlined
      },
      data() {
        return {
          notices_modal: false,
          overview_type: "Recent",
          InfoFilled: shallowRef(InfoFilled),
          Clock: shallowRef(Clock),
          overview_project: null,
          companyForm: null,
          showCompanyProcurementForm: false,
          selected_project: null,
          publish_modal: false,
          timeline_drawer: false,
          error_message: [],
          error_modal: false,
          import_form: {},
          import_modal: false,
          selected_filter: "all",
          filter_options: [
            {
              label: 'All Projects',
              value: 'all'
            },
            {
              label: 'My Company(s) Projects',
              value: 'company'
            },
            {
              label: 'My Projects',
              value: 'my'
            },
          ],
          company_ids: [],
          participant_form: {
            id: null,
            type: null
          },
          participant_drawer: false,
          history_drawer: false,
          pin_notice: null,
          selected: null,
          add_modal: false,
          dialogVisible: false,
          procurement_projects: [],
          to_remove: null,
          meta: {
            page: 1,
            total: 0,
            pages: 1
          },
          showForm: false,
          loading: false,
          search_value: null,
          showDetails: false,
          showFullEdit: false,
          procurementApiKeyFormType: ''
        }
      },
      watch: {
        search_value: function () {
            //if (!this.search_value || this.search_value.length > 2) this.loadProcurementProjects()
        }
      },
      created: function () {
        if(this.$store.state.checkUserGroupModuleRestriction('procurement-project-mod') == 1){
          window.location.href = "/"
        }

        this.loadProcurementProjects()
        
        for (const [key, value] of Object.entries(this.user.companies)) {
          this.company_ids.push(value.id)
        }
      },
      methods: {
        highlightRow({row, rowIndex}){
          if (row.id == this.overview_project.id) {
            return 'row_highlight';
          }
          return '';
        },
        viewNotices(){
          this.notices_modal = true
        },
        viewActiveStage(){
          if(this.overview_project?.itt_framework){
            if(this.overview_project?.status == this.ProcurementProjectStatuses.CODE_ITT_PENDING){
              this.$toast.info("This project's Tender Notice is still pending!")
              return false
            }
            window.open('/main/tender_notices?target=' + btoa(this.overview_project?.itt_framework?.id), '_blank')
          }
          else{
            if(this.overview_project?.status == this.ProcurementProjectStatuses.CODE_PENDING){
              this.$toast.info("This project is still draft!")
              return false
            }
            window.open('/main/planned_procurement_notices?target=' + btoa(this.overview_project?.pin_notice?.id), '_blank')
          }
        },
        showOverview(data){
          this.overview_type = "Selected"
          this.overview_project = data
        },
        updateProcurementSetting(){
          this.showCompanyProcurementForm = false
          if (this.procurementApiKeyFormType == 'project') {
            this.addProject()
          }
        },
        openPublicationModal(data){
          ProcurementProjects.checkIfProjectHasData(data.id, {})
            .then(resp => {
              let lot_count = resp.data.lot_count
              let spec_count = resp.data.spec_count
              let social_val_count = resp.data.social_val_count

              //check if the api key has been provided first
              if (resp.data.procurement_key_data && !resp.data.procurement_key_data.completed) {
                this.showCompanyProcurementForm = true
                this.companyForm = resp.data.procurement_key_data
                this.procurementApiKeyFormType = 'publish'
              } else {
                if(lot_count == 0 || spec_count == 0 || social_val_count == 0){
                  this.$toast.warning("Kindly set up the Framework Lots, Specifications, and Social Values for this project before proceeding.")
                }else {
                  this.selected_project = data
                  this.publish_modal = true
                }
              }
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
        },
        handleShowFullEdit(procurement_project){
          this.selected = Object.assign({}, procurement_project)
          this.showFullEdit = true
          this.add_modal = false
        },
        handleDetails(procurement_project){
          this.selected = Object.assign({}, procurement_project)
          this.showDetails = true
        },
        getUrlTargetId(){
          var target_id = null
          const url = window.location.search
          const url_params = new URLSearchParams(url)
          
          if (url_params.get("target")) {
            try {
              target_id = atob(url_params.get("target"))

              const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)
              if(!isNumeric(target_id)){
                target_id = null
              }
            } catch(e) {
              target_id = null
            }
          }
          
          return target_id
        },
        openTimeline(data){
          this.selected = data
          this.timeline_drawer = true
        },
        uploadFile(evt) {
          evt.preventDefault()

          var formData = new FormData()
          formData.append('file', this.import_form.file)

          this.loading = true

          ProcurementProjects.importProjects(formData)
            .then(result => {
              if(result.data.status == 1 || result.data.status == 2){
                result.data.status == 1 ? this.$toast.success(result.data.message) : this.$toast.warning(result.data.message)
                this.meta.page = 1
                this.loadProcurementProjects()
              }
              else{
                this.$toast.error('Failed to import')
                this.error_message = result.data.message.split(',')
                this.error_modal = true
              }

              this.import_modal = false
              this.import_form.file = null
            })
            .catch(error => {

            }).finally(() => {
              this.loading = false
          })
        },
        setFile(files) {
          this.import_form.file = files[0]
        },
        filterChange(value){
          this.loadProcurementProjects()
        },
        viewParticipants(id, type){
          if(!id){
            id = 0
          }
          this.participant_form.id = id
          this.participant_form.type = type
          this.participant_drawer = true
        },
        viewProjectHistory(project){
            this.selected = project
            this.history_drawer = true
        },
        viewPIN(project){
          this.globalState.loading = true

          PinNotices.show(project.pin_notice.id)
          .then(result => {
            if (result.data.pin_notice) {
              this.pin_notice = result.data.pin_notice
              this.pin_notice.name = project.name
              this.showForm = true
            }
            else{
              this.$toast.error('No data found')
            }
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching request')
            console.error('error:', error.response.data)
          })
          .finally(() => {
            this.globalState.loading = false
          })
        },
        addProject(){
          this.loading = false
          ProcurementProjects.checkProcurementApiKey()
            .then(resp => {
              if (resp.data.procurement_key_data && !resp.data.procurement_key_data.completed) {
                this.showCompanyProcurementForm = true
                this.companyForm = resp.data.procurement_key_data
                this.procurementApiKeyFormType = 'project'
              } else {
                this.selected = { id: null }
                this.add_modal = true
              }
            })
            .catch(error => {
              console.error('error:', error.response.data)
              
            }).finally(() => {
              this.loading = false
            })
        },
        manageProject(project){
            this.selected = project
            this.add_modal = true
        },
        handleClose() {
          this.publish_modal = false
          this.add_modal = false
          this.dialogVisible = false
          this.selected = null
          this.showFullEdit = false
        },
        afterSave() {
          this.loadProcurementProjects()
          this.add_modal = false
          this.publish_modal = false
        },
        search() {
          this.meta.page = 1
          this.loadProcurementProjects()
        },
        remove(bvModalEvt) {
          bvModalEvt.preventDefault()

          this.globalState.loading = true
          ProcurementProjects.delete({ id: this.to_remove.id})
            .then(result => {
              this.$toast.success(result?.data?.message || 'Project has been removed successfully')
              this.loadProcurementProjects()
              
              this.handleClose()
            })
            .catch(error => {
                this.$toast.error('An error occured while deleting project')
                console.error('error:', error.response.data)

            }).finally(() => {
              this.globalState.loading = false
            })
        },
        showRemove(type) {
          this.to_remove = Object.assign({}, type)
          this.dialogVisible = true
        },
        loadProcurementProjects(focus_target = 0) {
          this.globalState.loading = true
          this.handleClose()
          this.procurement_projects = []
          this.loading = true
          ProcurementProjects.get({
              params: {
                page: this.meta.page,
                search_value: this.search_value,
                selected_filter: this.selected_filter 
              }
            })
            .then(result => {
              if (result.data.procurement_projects){
                this.procurement_projects = result.data.procurement_projects
                this.overview_project = this.procurement_projects[0]

                if(result.data.recent_on_going_project){
                  this.overview_project = result.data.recent_on_going_project
                  this.overview_type = "Recent"
                }

                if(result.data.recent_active){
                  this.overview_project = result.data.recent_active
                  this.overview_type = "Active"
                }
                
              }
              this.meta = result.data.meta
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
              this.loading = false
            })
        }
      },
    }
    </script>
    <style>
    .row_highlight, .row_highlight:hover{
      background-color: #EFF9DA !important;
    }
  </style>
    