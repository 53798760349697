<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-6">
                    <label>Lot number</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label style="color: #a9a9a9">For example, 3A</label>
                    <div :class="{ 'has-error-field': v.form.lot_number.$invalid }">
                        <el-input v-model="form.lot_number"></el-input>
                    </div>
                    <div v-if="v.form.lot_number.$invalid" class="error-text" >Lot number is required</div>
                </div>
                <div class="col-md-6">
                    <label>Lot title</label>
                    <div :class="{ 'has-error-field': v.form.lot_title.$invalid }">
                       <el-input v-model="form.lot_title"></el-input>
                    </div>
                    <div v-if="v.form.lot_title.$invalid" class="error-text" >Lot title is required</div>
                </div>
                <div class="col-md-12">
                    <br/><label>Lot description</label>
                    <div :class="{ 'has-error-field': v.form.lot_description.$invalid }">
                        <el-input v-model="form.lot_description" type="textarea" :rows="3"></el-input>
                    </div>
                    <div v-if="v.form.lot_description.$invalid" class="error-text" >Lot description is required and should be longer than title</div>
                </div>
                <div class="col-md-12" v-if="!scope?.cpv_classifications_same">
                    <br/><label>CPV classifications for this lot</label>
                    <br/><label style="color: #a9a9a9">Select all that apply. At least one code for one lot must start with 03 to 44 or 48 for goods contracts, 45 for works, or 49 to 98 for services.</label>
                    <div :class="{ 'has-error-field': v.form.cpv_codes.$invalid }">
                        <el-select v-model="form.cpv_codes" filterable clearable multiple remote :remote-method="searchCPV">
                            <el-option
                                v-for="item in optionsCpv"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </div>
                    <div v-if="v.form.cpv_codes.$invalid" class="error-text" >Classifications is required</div>
                </div>
                <div class="col-md-6" v-if="!scope?.delivery_locations_same">
                    <br/><label>Delivery regions for this lot (optional)</label>
                    <br/><label style="color: #A9A9A9">select all that apply.</label>
                    <el-select v-model="form.delivery_regions" filterable clearable multiple>
                        <el-option-group v-for="(c_values, c_abbrev) in nuts_code_choices" label="Countries">
                            <el-option :value="c_abbrev" :label="c_abbrev + ': ' + c_values.name">
                                <span style="float: left">{{ c_values.name }}</span>
                                <span class="secondary-selection">
                                    {{ c_abbrev }}
                                </span>
                            </el-option>
                                <el-option-group v-if="'parts' in c_values && !form.delivery_regions?.includes(c_abbrev)" :key="c_values.name" :label="'List of ' + c_values.name" style="margin-left: 15px">
                                    <span v-for="(c1_values, c1_abbrev) in c_values.parts" style="margin-left: 15px">
                                        <el-option  :value="c1_abbrev" :label="c1_abbrev + ': ' + c1_values.name">
                                            <span style="float: left">{{ c1_values.name }}</span>
                                            <span class="secondary-selection">
                                                {{ c1_abbrev }}
                                            </span>
                                        </el-option>
                                        <el-option-group v-if="'parts' in c1_values && !form.delivery_regions?.includes(c1_abbrev)" :key="c1_values.name" :label="'List of ' + c1_values.name" style="margin-left: 15px">
                                            <span v-for="(c2_values, c2_abbrev) in c1_values.parts">
                                                <el-option :value="c2_abbrev" :label="c2_abbrev + ': ' + c2_values.name" style="margin-left: 15px">
                                                    <span style="float: left">{{ c2_values.name }}</span>
                                                    <span class="secondary-selection">
                                                        {{ c2_abbrev }}
                                                    </span>
                                                </el-option>
                                                    <el-option-group v-if="'parts' in c2_values && !form.delivery_regions?.includes(c2_abbrev)" :key="c2_values.name" :label="'List of ' + c2_values.name" style="margin-left: 15px">
                                                        <span v-for="(c3_values, c3_abbrev) in c2_values.parts">
                                                            <el-option :value="c3_abbrev" :label="c3_abbrev + ': ' + c3_values.name" style="margin-left: 15px">
                                                                <span style="float: left">{{ c3_values.name }}</span>
                                                                <span class="secondary-selection">
                                                                    {{ c3_abbrev }}
                                                                </span>
                                                            </el-option>
                                                            <el-option-group v-if="'parts' in c3_values && !form.delivery_regions?.includes(c3_abbrev)" :key="c3_values.name" :label="'Parts of ' + c3_values.name" style="margin-left: 15px">
                                                                <el-option v-for="(c4_values, c4_abbrev) in c3_values.parts" :value="c4_abbrev" :label="c4_abbrev + ': ' + c4_values.name" style="margin-left: 15px">
                                                                    <span style="float: left">{{ c4_values.name }}</span>
                                                                    <span class="secondary-selection">
                                                                        {{ c3_abbrev }}
                                                                    </span>
                                                                </el-option>
                                                            </el-option-group>
                                                        </span>
                                                    </el-option-group>
                                            </span>
                                        </el-option-group>
                                    </span>
                            </el-option-group>
                        </el-option-group >
                    </el-select>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <br/><label>Lot value (estimated)</label>
                        <br/><label style="color: #a9a9a9">Maximum value including all possible extensions and options. For a framework lot, if its value is restricted, the sum of the values of all contracts awarded under it, or under the same open framework scheme. For example, 200000 or 417709.28.</label>
                    </div>
                    <div class="col-md-5">
                        <label>Excluding VAT</label>
                        <br/><label style="color: #A9A9A9">Required if a contracting authority is central government and value including VAT is entered.</label>
                        <el-input v-model="form.excluding_vat"></el-input>
                        <div v-if="v.form.excluding_vat.$invalid" class="error-text" >Excluding VAT is required</div>
                    </div>
                    <div class="col-md-5">
                        <label>Including VAT</label>
                        <br/><label style="color: #A9A9A9">Required if not establishing a framework or value excluding VAT is entered.</label>
                        <el-input v-model="form.including_vat"></el-input>
                        <div v-if="v.form.including_vat.$invalid" class="error-text" >Including VAT is required</div>
                    </div>
                    <div class="col-md-2">
                        <br/><label>Currency</label>
                        <div :class="{ 'has-error-field': v.form.currency.$invalid }" style="width: 224px">
                            <el-select v-model="form.currency" filterable clearable>
                                <el-option v-for="(full_name, abbrev) in currency_choices" :value="abbrev" :label="abbrev + ': ' + full_name">{{ abbrev + ': ' + full_name }}</el-option>
                            </el-select>
                        </div>
                        <div v-if="v.form.currency.$invalid" class="error-text" >Currency is required</div>
                    </div>
                    <div class="row" v-if="scope?.contract_dates_same === false">
                        <div class="col-md-6">
                            <br/><label>Contract start date (estimated)</label>
                            <br/><label style="color: #A9A9A9">For example, 01 04 2025</label>
                            <div :class="{ 'has-error-field': v.form.start_date.$invalid }" style="width: 224px">
                                <el-date-picker v-model="form.start_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                            </div>
                            <div v-if="v.form.start_date.$invalid" class="error-text" >Start date is required</div>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Contract end date (estimated)</label>
                            <br/><label style="color: #A9A9A9">Without any extensions.</label>
                            <div :class="{ 'has-error-field': v.form.end_date.$invalid }" style="width: 224px">
                                <el-date-picker :disabled="!form.start_date" v-model="form.end_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disableDateAfterStartDate"></el-date-picker>
                            </div>
                            <div v-if="v.form.end_date.$invalid" class="error-text" >End date is required</div>
                        </div>
                        <div class="col-md-6" v-if="scope?.contract_dates_same === false">
                            <br/><label>Can the contract be extended?</label>
                            <div :class="{ 'has-error-field': v.form.can_extend_contract.$invalid }" style="width: 150px">
                                <el-radio-group v-model="form.can_extend_contract" border>
                                    <el-radio :value="true">Yes</el-radio>
                                    <el-radio :value="false">No</el-radio>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.can_extend_contract.$invalid" class="error-text" >Yes/No is required</div>
                        </div>
                        <div class="row" v-if="form.can_extend_contract">
                            <div class="col-md-4">
                                <br/><label>Extension end date (estimated)</label>
                                <br/><label style="color: #A9A9A9">If all extensions are used.</label>
                                <div :class="{ 'has-error-field': v.form.extension_end_date.$invalid }" style="width: 224px">
                                    <el-date-picker v-model="form.extension_end_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disableDatesBeforeEndDate"></el-date-picker>
                                </div>
                                <div v-if="v.form.extension_end_date.$invalid" class="error-text" >Extension date is required</div>
                            </div>
                            <div class="col-md-8" v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                                <br/><label>Description of extensions</label>
                                <br/><label style="color: #A9A9A9">Include the circumstances in which they could be used, and provide a breakdown if multiple extensions are possible.</label>
                                <div :class="{ 'has-error-field': v.form.extension_description.$invalid }">
                                    <el-input v-model="form.extension_description" type="textarea" :rows="3"></el-input>
                                </div>
                                <div v-if="v.form.extension_description.$invalid" class="error-text" >Extension description is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="scope?.include_options === 1">
                        <div class="col-md-6">
                            <br/><label>Does the contract include options?</label>
                            <div :class="{ 'has-error-field': v.form.include_options.$invalid }"  style="width: 116px; height: 35px">
                                <el-radio-group v-model="form.include_options">
                                    <el-radio-button :value="true">Yes</el-radio-button>
                                    <el-radio-button :value="false">No</el-radio-button>
                                </el-radio-group>
                            </div>
                            <div v-if="v.form.include_options.$invalid" class="error-text" >Yes/No is required</div>
                        </div>
                        <div class="col-md-6" v-if="form.include_options">
                            <br/><label>Description of options</label>
                            <br/><label style="color: #a9a9a9">Include enough information to understand the scope of the options and the circumstances in which they could be used.</label>
                            <div :class="{ 'has-error-field': v.form.option_description.$invalid }">
                                <el-input v-model="form.option_description"></el-input>
                            </div>
                            <div v-if="v.form.option_description.$invalid" class="error-text" >Description is required</div>
                        </div>
                    </div>
                    <div class="row" v-if="!participation?.participation_conditions_same" style="margin-top: 30px">
                        <div class="col-md-6" v-if="procedure?.threshold !== 2">
                            <label>Legal and financial capacity conditions of participation (optional)</label>
                            <br/><label style="color: #a9a9a9">Must not require insurance before contract award, or audited accounts.</label>
                            <el-input v-model="form.legal_conditions" type="textarea" :rows="3"></el-input>
                        </div>
                        <div class="col-md-6" v-if="procedure?.threshold !== 2">
                            <label>Technical ability conditions of participation (optional)</label>
                            <br/><label style="color: #a9a9a9">Must allow equivalent qualifications and standards.</label>
                            <el-input v-model="form.technical_conditions" type="textarea" :rows="3"></el-input>
                        </div>
                        <div class="col-md-6" v-if="(procedure?.threshold != 0 || procedure?.threshold != 1)">
                            <label>Conditions of participation (optional)</label>
                            <br/><label style="color: #a9a9a9">Must allow equivalent qualifications and standards.</label>
                            <el-input v-model="form.participation_condition" type="textarea" :rows="3"></el-input>
                        </div>
                    </div>
                    <div class="row" v-if="procedure?.is_inviting_requests === 0">
                        <br/><label>Number of suppliers to be invited to tender for this lot</label>
                        <div class="col-md-6">
                            <label>Minimum (optional)</label>
                            <el-input v-model="form.minimum_participants"></el-input>
                            <div v-if="v.form.minimum_participants.$invalid" class="error-text" >Minimum should be numeric</div>
                        </div>
                        <div class="col-md-6">
                            <label>Maximum (optional)</label>
                            <el-input v-model="form.maximum_participants"></el-input>
                            <div v-if="v.form.maximum_participants.$invalid" class="error-text" >Maximum should be numeric</div>
                        </div>
                        <div class="col-md-12" v-if="form.maximum_participants">
                            <label>Criteria used to select suppliers to tender for this lot</label>
                            <br/><label style="color: #A9A9A9">If a maximum number will be invited.</label>
                            <div :class="{ 'has-error-field': v.form.supplier_criteria.$invalid }">
                                <el-input type="textarea" v-model="form.supplier_criteria"></el-input>
                            </div>
                            <div v-if="v.form.supplier_criteria.$invalid" class="error-text" >Criteria is required if maximum has value</div>
                        </div>
                    </div>
                    <div class="row" v-if="participation?.is_suitability_same">
                        <br/><label>Is this contract particularly suitable for the following groups?</label>
                        <span class="row">
                            <el-checkbox v-model="form.is_sme" :checked="form.is_sme">Small and medium-sized enterprises (SMEs) <br/>
                                <span style="color: #a9a9a9">Fewer than 250 staff, and either turnover less than £44 million or balance sheet total less than £38 million.</span>
                            </el-checkbox>
                        </span>
                        <span class="row">
                            <el-checkbox v-model="form.is_vcse" :checked="form.is_vcse">Voluntary, community and social enterprises (VCSEs) <br/>
                                <span style="color: #a9a9a9">Non-governmental organisation that is value-driven and principally reinvests its surpluses to further social, environmental or cultural objectives.</span>
                            </el-checkbox>
                        </span>
                    </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" class="mb-3" :disabled="v.form.$invalid" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required, numeric,
     requiredIf, minLength
     } from '@vuelidate/validators'
 
     const LOTS = 'LOTS';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'currency_choices', 'cpv_code_choices', 'nuts_code_choices', 'scope', 'participation', 'procedure', 'tpp_procurement_type'],
         data() {
             return {
                optionsCpv: [],
                listCpv: [],
                form: {
                    lot_number: null,
                    lot_description: null,
                    lot_title: '',
                    start_date: null,
                    end_date: null,
                    cpv_codes: [],
                    currency: 'GBP',
                    delivery_regions: [],
                    excluding_vat: null,
                    including_vat: null,
                    can_extend_contract: null, //bool
                    extension_end_date: null,
                    extension_description: null,
                    include_options: null, //bool
                    option_description: null,
                    legal_conditions: null,
                    technical_conditions: null,
                    participation_condition: null,
                    minimum_participants: null,
                    maximum_participants: null,
                    is_sme: false, //bool
                    is_vcse: false //bool
                },
             }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form
            else this.form.currency = "GBP"

            var self = this;

            Object.values(this.cpv_code_choices).map((values) => {
                Object.entries(values).forEach((desc) => {
                    self.listCpv.push({ label: desc[0] + ": " + desc[1], value: desc[0] })
                })
            })
        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    lot_number: {
                        required,
                        $autoDirty: true
                    },
                    lot_description: {
                        required,
                        minLength: minLength(this.form.lot_title.length + 1),
                        $autoDirty: true
                    },
                    lot_title: {
                        required,
                        $autoDirty: true
                    }, 
                    cpv_codes: {
                        required: requiredIf(function () { return this.scope?.divided_to_lots && !this.scope?.cpv_classifications_same }),
                        $autoDirty: true
                    },
                    currency: {
                        required,
                        $autoDirty: true
                    },
                    start_date: {
                        required: requiredIf(function () { return this.scope?.contract_dates_same === false}),
                        $autoDirty: true
                    },
                    end_date: {
                        required: requiredIf(function () { return this.scope?.contract_dates_same === false}),
                        $autoDirty: true
                    },
                    include_options: {
                        required: requiredIf(function() { return this.scope?.include_options === 1}),
                        $autoDirty: true
                    },
                    option_description: {
                        required: requiredIf(function () { return this.include_options}),
                        $autoDirty: true
                    },
                    minimum_participants: {
                        numeric,
                        $autoDirty: true
                    },
                    can_extend_contract: {
                        required: requiredIf(function () { return this.scope?.contract_dates_same === false && this.scope?.divided_to_lots}),
                        $autoDirty: true
                    },
                    extension_end_date: {
                        required: requiredIf (function() { return this.form.can_extend_contract }),
                        $autoDirty: true
                    },
                    supplier_criteria: {
                        required: requiredIf(function() { return this.form.maximum_participants }),
                        $autoDirty: true
                    },
                    including_vat: {
                        required: requiredIf(function() { return this.form.excluding_vat || this.scope?.framework_establised }),
                        $autoDirty: true
                    },
                    excluding_vat: {
                        required: requiredIf(function() { return this.form.excluding_vat || this.scope?.framework_establised }),
                        $autoDirty: true
                    },
                    extension_description: {
                        required: requiredIf (function() { return this.form.can_extend_contract }),
                        $autoDirty: true
                    },
                    maximum_participants: {
                        numeric,
                        $autoDirty: true
                    },
                    minimum_participants: {
                        numeric,
                        $autoDirty: true
                    }
                }
            }
            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', LOTS, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            disablePastDates(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date < today;
            },
            disableDateAfterStartDate(date) {
                if(this.form.start_date)
                return date < new Date(this.form.start_date);
                else 
                return this.disablePastDates(date)
            },
            disableDatesBeforeEndDate(date) {
                return date < new Date(this.form.end_date);
            },
            searchCPV(query) {
                if (query) {
                this.loading = true
                    setTimeout(() => {
                    this.loading = false
                    this.optionsCpv = this.listCpv.filter((item) => {
                        return item.label.toLowerCase().includes(query.toLowerCase())
                    })
                    this.optionsCpv = this.optionsCpv.slice(0, 20);
                }, 500)
                } else {
                    this.optionsCpv = []
                }
            }
        }
     }
 </script>