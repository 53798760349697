<template>
    
    <div class="card">
        <div class="card-body">
          <h4 class="mb-4">Application History Logs</h4>
          <div class="row ">
                    <div class="col-md-8 mb-4">
                      <div class="row">
                        <div class="col-md-4 mt-3">
                          <label>Category:</label>
                          <el-select filterable v-model="category" placeholder="Select or Search a Category" >
                            <el-option
                              :key="''"
                              :label="'All Categories'"
                              :value="''"
                            />
                            <el-option
                              v-for="category in categories"
                              :key="'category_'+category.category"
                              :label="category.category"
                              :value="category.category"
                            />
                          </el-select>
                        </div>
                        <div class="col-md-4 mt-3">
                          <label>Type:</label>
                          <el-select filterable v-model="type" placeholder="Select or Search a Type" >
                            <el-option
                              :key="''"
                              :label="'All Types'"
                              :value="''"
                            />
                            <el-option
                              v-for="application_type in ApplicationHistoryTypes"
                              :key="'type_'+application_type.value"
                              :label="application_type.text"
                              :value="application_type.value"
                            />
                          </el-select>
                        </div>
                        <div class="col-md-4 mt-3">
                          <label>User:</label>
                          <el-select filterable v-model="user" placeholder="Select or Search a User" >
                            <el-option
                              :key="''"
                              :label="'All Users'"
                              :value="''"
                            />
                            <el-option
                              v-for="user in users"
                              :key="'user_'+user.id"
                              :label="user.name"
                              :value="user.id"
                            />
                          </el-select>
                        </div>
                        <div class="col-md-4 mt-3">
                          <label>Company:</label>
                          <el-select filterable v-model="company" placeholder="Select or Search a Company" >
                            <el-option
                              :key="''"
                              :label="'All Companies'"
                              :value="''"
                            />
                            <el-option
                              v-for="company in companies"
                              :key="'company_'+company.id"
                              :label="company.name"
                              :value="company.id"
                            />
                          </el-select>
                        </div>
                        <div class="col-md-5 mt-3">
                          <label>Date Range:</label><br>
                          <el-date-picker
                              v-model="date_range"
                              type="daterange"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              @change="dateChange"
                              format="YYYY/MM/DD"
                              value-format="YYYY-MM-DD"
                            />
                        </div>
                        <div class="col-md-2 mt-3">
                          <label class="hide"></label><br>
                          <el-button class="mt-1" type="warning" @click="exportHistories"><i class="fas fa-file-export"></i>&nbsp; Export as Excel</el-button>
                        </div>
                      </div>
                      
                    </div>  
                    <div class="col-md-4 mb-3">
                      <nav class=" float-end">
                        <div class="d-flex">
                          <label></label>
                          <input
                          v-on:keyup.enter="loadHistories"
                          type="text" name="" id="" class="form-control form-control-sm border-1 " 
                          v-model="search"
                            placeholder="Search" />
                            <el-button type="primary" @click="reload"><i class="fa fa-refresh"></i>&nbsp; Reload</el-button>
                        </div>
                        </nav>
                      
                  </div>
                  <div class="col-md-3 mb-3"></div>
                </div>
          <div class="table-responsive">
                  
                <table class="table table-fluid align-items-center mb-0 table-hover">
                  <thead>
                    <tr class="text-sm">
                      <th>Name/Label</th>
                      <th>Type</th>
                      <th>Category</th>
                      <th>History</th>
                      <th>Date</th>
                      <th>User</th>
                      <th>Company</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!histories.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No history</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="history in histories" :key="history.id">
                      <td class="text-wrap">{{ history.display_label }}</td>
                      <td class="text-wrap">{{ history.type_label }}</td>
                      <td>{{ history.category }}</td>
                      <td class="text-wrap">{{ history.history_text }}</td>
                      <td>{{ $filters.datetimeFormatFilter(history.created_at) }}</td>
                      <td>{{ history.user?.name }}</td>
                      <td>{{ history.company?.name }}</td>
                      <td><el-button @click="viewDetails(history)" type="primary"><i class="fa fa-eye"></i>&nbsp;Details</el-button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
        </div>
        <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="loadHistories()" />
            </div>
    </div>

    <el-dialog
      v-model="details_modal"
      title="Details"
      width="70%"
    >
      
    <el-descriptions :column="2" border v-if="selected_data" title="">
      <el-descriptions-item label="Name/Label">{{ selected_data.display_label }}</el-descriptions-item>
      <el-descriptions-item label="Type">{{ selected_data.type_label }}</el-descriptions-item>
      <el-descriptions-item label="Category">{{ selected_data.category }}</el-descriptions-item>
      <el-descriptions-item label="History">{{ selected_data.history_text }}</el-descriptions-item>
      <el-descriptions-item label="Date">{{ $filters.datetimeFormatFilter(selected_data.created_at) }}</el-descriptions-item>
      <el-descriptions-item label="Table">{{ selected_data.table_name }}</el-descriptions-item>
      <el-descriptions-item label="ID">{{ selected_data.table_unique_id }}</el-descriptions-item>
      <el-descriptions-item label="Username">{{ selected_data.user?.name }}</el-descriptions-item>
      <el-descriptions-item label="Company">{{ selected_data.company?.name }}</el-descriptions-item>
    </el-descriptions>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="details_modal = false">Close</el-button>
        </div>
      </template>
    </el-dialog>
    
  </template>
  
  <script>
    import { ApplicationHistory } from '@/api_services/application_histories'
    import { mapState } from 'vuex'
    import { User } from '@/api_services/user'
    import { Company } from '@/api_services/company'
    import Swal from 'sweetalert2'

    export default {
      
      data() {
        return {
          company: null,
          companies: null,
          details_modal: false,
          selected_data: null,
          date_range: null,
          date_from: null,
          date_to: null,
          isLoading: false,
          category: "",
          type: "",
          users: null,
          user: null,
          search: null,
          categories: null,
          histories: [],
          meta: {
            page: 1,
            total: 0,
            pages: 1
          },
        }
      },
      created: function () {
        this.getUsers()
        this.loadCategories()
        this.getCompanies()
      },
      watch: {
        search: {
          handler: function(newVal) {
            /*if (!this.search || this.search.length > 2){
              this.meta.page = 1
              this.loadHistories()
            } */
          },
          deep: true
        },
        category: {
          handler: function(newVal) {
            this.loadHistories()
          },
          deep: true
        },
        type: {
          handler: function(newVal) {
            this.loadHistories()
          },
          deep: true
        },
        user: {
          handler: function(newVal) {
            this.loadHistories()
          },
          deep: true
        },
        company: {
          handler: function(newVal) {
            this.loadHistories()
          },
          deep: true
        },
      },
      computed: {
        ...mapState(['ApplicationHistoryTypes']),
      }, 
      methods: {
        exportHistories(){
          Swal.fire({
            icon: 'info',
              text:
                'You are about to export this application histories, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {

                this.loading = true
                ApplicationHistory.exportHistories("export_as_excel", { params: { 
                  category: this.category,
                  type: this.type,
                  user: this.user,
                  company: this.company,
                  search_value: this.search,
                  date_range: this.date_range
                 } })
                  .then(result => {

                    this.$toast.success('Export success!')
                    
                    const url = result.data
                    const link = document.createElement('a')
                    link.href = url
                    link.click()
                  })
                  .catch(error => {
                    this.$toast.error('An error occured while exporting companies')
                    console.error('error:', error.response.data)
                  })
                  .finally(() => {
                    this.loading = false
                  })
                
              }
          })
        },
        getCompanies() {
          this.companies = []
          Company.get({ params: { per_page: 99999 } })
            .then(result => {
              if (result.data.companies) {
                this.companies = result.data.companies
              }
            })
            .catch(error => {
              this.$toast.error('An error occured while fetching companies')
              console.error('error:', error.response.data)
            })
        },
        viewDetails(data){
          this.selected_data = data
          this.details_modal = true
        },
        reload(){
          this.category = null
          this.type = null
          this.user = null
          this.search_value = null
          this.date_range = null
          this.loadHistories()
        },
        dateChange(value){
          this.loadHistories()
        },
        getUsers() {
          this.globalState.loading = true

          User.get({
            params: { term: "", status: 1, page: 0 }
          })
            .then(result => {
              if (result.data.users) this.users = result.data.users
            })
            .catch(error => {
              this.$toast.error('An error occured while fetching users')
              console.error('error:', error.response.data)
             
            })
            .finally(() => {
              this.globalState.loading = false
            })
        },
        loadHistories(){
            this.isLoading = true

            ApplicationHistory.load_histories({
              category: this.category,
              type: this.type,
              user: this.user,
              company: this.company,
              search_value: this.search,
              date_range: this.date_range,
              page: this.meta.page
            })
            .then(result => {
                if (result.data.histories) {
                    this.histories = result.data.histories
                    this.meta = result.data.meta
                }
                else{
                    this.$toast.error('No data found')
                }
            })
            .catch(error => {
                this.$toast.error('An error occured while fetching request')
                console.error('error:', error.response.data)
            })
            .finally(() => {
              this.isLoading = false
            })
        },
        loadCategories(){
            this.globalState.loading = true

            ApplicationHistory.load_categories({})
            .then(result => {
                if (result.data.categories) {
                    this.categories = result.data.categories

                    this.loadHistories()
                }
                else{
                    this.$toast.error('No data found')
                }
            })
            .catch(error => {
                this.$toast.error('An error occured while fetching request')
                console.error('error:', error.response.data)
            })
            .finally(() => {
              this.globalState.loading = false
            })
        }
      }
    }
  </script>
  