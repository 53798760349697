<template>
    <div class="card">
        <div class="card-header">
            <el-alert effect="dark" type="info" show-icon> {{message}}
                <p>This section will allow us to submit a procurement request to "Find A Tender" that coincides with the stage the procurement is currently at.</p>
                <p>Note: If you are unable to complete all of the forms, make sure to complete the section you are filling out and click on Save (and go to next section) so you can revisit at any time.</p>
            </el-alert>
        
            <el-alert effect="dark" type="warning" show-icon> 
                <h6>Form Status: 
                    <span class="badge badge-warning">{{ checkAllFormStatus }} </span>
                    <el-button style="margin-left: 20px" @click="error_modal = true" type="primary" v-if="all_form && all_form.overall_form_status == 9">Show errors</el-button>
                </h6>
            </el-alert>
        </div>
        <div class="card-body">
            <div v-if="tpp_procurement_type == TPPNoticeTypes.UK2.value">
                <u-k-2 :open_all="is_review" :procurement="procurement" :tpp_procurement_type="tpp_procurement_type" :is_not_submitted="is_not_submitted"></u-k-2>
            </div> 
            <div v-if="tpp_procurement_type == TPPNoticeTypes.UK3.value">
                <u-k-3 :open_all="is_review" :procurement="procurement" :tpp_procurement_type="tpp_procurement_type" :is_not_submitted="is_not_submitted"></u-k-3>
            </div> 
            <div v-if="tpp_procurement_type == TPPNoticeTypes.UK4.value">
                <u-k-4 :open_all="is_review" :procurement="procurement" :tpp_procurement_type="tpp_procurement_type" :is_not_submitted="is_not_submitted"></u-k-4>
            </div> 
            <!-- <div class="row" v-if="is_not_submitted"> -->
                <span class="col-md-3 mb-3"><el-button type="primary" @click="isReview" :disabled="!validated">Review Form</el-button></span>
                <span class="col-md-3 mb-3" style="margin-left: 10px" v-if="is_review"><el-button :disabled="!is_review || !validated" type="success" @click="publishPin()">Publish Form</el-button></span>
            <!-- </div>   -->
        </div>
    </div>
    <el-dialog
      v-model="error_modal" 
      title="Errors"
      width="100%"
      @close="error_modal = false"
    >
        <div>
            <p v-for="error in forms.errors">{{ error }}</p>
        </div>
    </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { Tpp } from '@/api_services/fts_tpp'
import { ProcurementProjects } from '@/api_services/procurement_projects';
import Swal from 'sweetalert2';

import UK2 from './notices/UK2PreliminaryMarketEngagementNotice'
import UK3 from './notices/UK3PlannedProcurementNotice'
import UK4 from './notices/UK4TenderNotice'

export default {
    data(){
        return {
            error_modal: false,
            index: 0,
            update: 0,
            step: null,
            is_review: false,
            step_division: null,
            step_contracting: null,
            form_id: null,
            static_data: null,
            previous_forms: null,
            previous_all_form: null,
            forms: {
                contact_organisation: null,
                contracting_authorities: null,
                contracting_authorities_info: [],
                engagement: null,
                notice_linking: [],
                other_organisation: [],
                participation: [],
                procedure: null,
                scope: null,
                submission: null,
                errors: [],
                lots: [],
                award_criteria: [],
                documents: null
            },
            all_form: null
        } 
    },
    props: ['procurement', 'project', 'tpp_procurement_type'],
    components: {
        UK2,
        UK3,
        UK4
    },
    computed: {
        ...mapState(['savedUser', 'ProcurementProjectStatuses', 'TPPNoticeTypes', 'TPPFormParts']),
        is_not_submitted() {
            if(!this.all_form || this.all_form.overall_form_status !== 3){
                return true;
            }
            else return false;

        },
        isIncludingVatValid() {
            if(this.forms.scope) {
                this.forms.lots.forEach(lot => {
                    
                })
            }
            else return true
        },
        isExcludingVatEqual() {
            
        },
        validated() {
            let validated = false;

            if(
                this.forms.contracting_authorities && this.forms.contracting_authorities.number_of_ca <= this.forms.contracting_authorities_info.length &&
            (this.forms.contracting_authorities.number_other_org ? this.forms.other_organisation.length >= this.forms.contracting_authorities.number_other_org : true)
            )
            {
                if(this.tpp_procurement_type === this.TPPNoticeTypes.UK2.value) {
                    if(
                        this.forms.scope &&
                        this.forms.engagement &&
                        this.forms.contracting_authorities &&
                        this.forms.procedure &&
                        this.forms.contracting_authorities_info &&
                        this.forms.other_organisation
                    ) {

                        validated = true
                    }
                }
                else if(this.tpp_procurement_type === this.TPPNoticeTypes.UK3.value || this.tpp_procurement_type === this.TPPNoticeTypes.UK4.value)
                {
                    if(
                        this.forms.procedure &&
                        this.forms.scope &&
                        this.forms.lots &&
                        // this.forms.documents &&
                        this.forms.submission
                    )
                    {
                        if(this.forms.scope.framework_establised)
                        {
                            if(!this.forms.framework) return false 
                        }
                        else if(this.forms.scope.divided_to_lots)
                        {
                            if(!this.forms.participation) return false
                        }

                        if(this.tpp_procurement_type === this.TPPNoticeTypes.UK3.value && 
                        this.forms.scope.have_award_criteria_decided &&
                        !this.forms.award_criteria) return false

                        validated = true
                    }  
                }
            }
       
            
            return validated
        },     
        checkAllFormStatus() {
            if(this.all_form) {
                switch (this.all_form.overall_form_status) {
                    case 0: return "Not Started"
                    case 1: return "In Progress"
                    case 2: return "Submitted"
                    case 3: return "Published"
                    case 9: return "Error"
                }
            }
            
            else return 'Not Started'
        },
    },
    created() {
        this.getAllForms();

    }, 
    methods: {
        saveForm(form_type, form_values) {
                let procurement_type = this.tpp_procurement_type
                form_values.form_type = form_type;
                form_values.form_status = 1; // 1 is completed
                form_values.procurement_type = procurement_type
                form_values.procurement_project_id = this.procurement.id 
                form_values.procurement_forms_id = this.form_id 
                
                Tpp.create(procurement_type, form_values).then(result => {
                    this.$toast.success('Saved successfully')
                    
                    if(form_type == this.TPPFormParts.CONTRACTING_AUTH_INFO && (this.forms.contracting_authorities.number_of_ca < this.step_contracting)) this.step_contracting++
                    else if(form_type == this.TPPFormParts.OTHER_ORG) this.step_other_org++
                    else {
                            this.step++
                        }

                    this.getAllForms();
                })
        },
        getAllForms() {
            Tpp.getAllForms(this.procurement.id, this.tpp_procurement_type).then(result => {
                if(result.data.procurement_project_all_form != null) {
                    this.forms = result.data
                    this.all_form = result.data.procurement_project_all_form;
                }

                else this.step = 0;
            })
        },
        isReview() {
            this.getAllForms();

            Swal.fire({
                title: 'Please make sure that all details are correct in order to proceed with submission',
                text: 'Submitted form will be posted on Find a Tender',
                icon: 'info',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Continue',
                }).then((result) => {
                if (result.isConfirmed) {
                    this.is_review = true
                }
            })
        },
        publishPin() {
            if (this.all_form.id && this.validated) {
                Swal.fire({
                title: 'Submit Procurement Publish Request?',
                text: 'Do you want to proceed?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.isConfirmed) {
                    this.globalState.loading = true

                    Tpp.submitToTender(this.all_form.id, this.tpp_procurement_type).then(result => {
                        this.globalState.loading = true

                        this.forms.status = this.ProcurementProjectStatuses.CODE_SUBMITTED
                        this.$emit('load-projects')

                        ProcurementProjects.update(this.procurement.id, this.form)
                        .then(result => {
                            this.$toast.success('PPN request submitted')
                            this.$emit('close-dialog');
                        })
                        .catch(error => {
                            console.log(error)
                        })
                        .finally(() => {
                            this.globalState.loading = false
                        })
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.globalState.loading = false
                    })
                }
                });
            }
        },
        getStaticData() {
            Tpp.getStaticData().then(result => {                
                this.static_data = result.data
            })
        },
        checkEachFormStatus(form_type) {
            if(form_type && "id" in form_type) {
                switch(form_type.form_status) {
                    case 0: return "DRAFT"
                    case 1: return "SAVED"
                }
            }
            
            return "DRAFT"
        }
    }
}
</script>