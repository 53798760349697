<template>
    <div>
      <h4 v-if="!show_participants" class="text-dark">List of Suppliers</h4>
      <div class="row">
        <div class="col-md-12">
          <div class="card my-3">
            <div class="card-body px-0 pb-2">
                <button v-if="show_imported" type="button" class="btn btn-success btn-sm mb-0 ms-3 mb-3 " @click="export_modal = true">
                    <i class="fa-solid fa-download me-1"></i> Export Suppliers
                  </button><br>
                <el-tabs v-model="main_tab" type="" class="demo-tabs ps-4 pe-4">
                    <el-tab-pane :name="'registered'">
                        <template #label>
                        <span class="custom-tabs-label">
                            <i class="fa fa-list"></i>
                            <span>&nbsp;Registered {{ show_participants ? 'Suppliers' : '' }}</span>
                        </span>
                        </template>

                        <div class="table-responsive">
                            <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                            <div class="bg-white border-radius-lg d-flex me-2">
                                <input v-on:keyup.enter="loadParticipants" type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="search_value"
                                placeholder="Search" />
                            </div>
                            </nav>
                            <table class="table table-fluid align-items-center mb-0">
                            <thead>
                                <tr class="text-sm">
                                    <th>Company Name</th>
                                    <th>Contact Information</th>
                                    <th>Address</th>
                                    <th v-if="type == 'pin'">Additional Files</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!participants.length">
                                    <td v-if="isLoading" colspan="100%" class="text-sm">
                                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                                    </td>
                                <td v-else colspan="100%">No registered suppliers {{ type == 'itt' ? 'for this Tender Notice' : '' }} found!</td>
                                </tr>
                                <tr v-else class="text-sm" v-for="participant in participants" :key="participant.id">
                                    <td>{{ participant?.name }}</td>
                                    <td>
                                        <template v-for="user in participant.participated_users">
                                            <p class="m-1 text-sm">{{ user.name }} <el-divider direction="vertical" /> <a :href="'mailto:' + user.email">{{ user.email }}</a></p>
                                            <hr class="m-0">
                                        </template>
                                    </td>
                                    <td class="text-wrap">{{ participant?.address }}</td>
                                    <td v-if="type == 'pin'">
                                        <el-button v-if="(pin_notice?.project_status == ProcurementProjectStatuses.CODE_ACTIVE || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED) || (pin_notice?.project_status == ProcurementProjectStatuses.CODE_PRE_PIN || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED)" @click="openFileModal(participant, 'upload')" class="mb-1" size="small" type="primary" ><i class="fa fa-upload"></i>&nbsp;Upload PDF</el-button><br>
                                        <el-button @click="openFileModal(participant, 'view')" size="small" type="success" ><i class="fa fa-list"></i>&nbsp;View PDFs ({{ (participant?.uploaded_files?.file_count ? participant?.uploaded_files?.file_count : 0) }})</el-button>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <hr>
                        <div class="card-footer pt-0">
                          <pagination :meta="meta" v-on:update="loadParticipants()" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane v-if="show_imported" :name="'all'">
                        <template #label>
                        <span class="custom-tabs-label">
                            <i class="fa fa-list"></i>
                            <span>&nbsp;Imported</span>
                        </span>
                        </template>

                        <div class="table-responsive">
                            <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                              <button v-if="(pin_notice?.project_status == ProcurementProjectStatuses.CODE_ACTIVE || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED) || (pin_notice?.project_status == ProcurementProjectStatuses.CODE_PRE_PIN || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED)" type="button" class="btn btn-info btn-sm mb-0 me-2" @click="import_modal = true">
                                <i class="fa-solid fa-upload me-1"></i> Import
                              </button>
                            <div class="bg-white border-radius-lg d-flex me-2">
                                <input v-on:keyup.enter="loadProjectSuppliers" type="text" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="supplier_search_value"
                                placeholder="Search" />
                            </div>
                            </nav>
                            <table class="table table-fluid align-items-center mb-0">
                            <thead>
                                <tr class="text-sm">
                                    <th>Company Name</th>
                                    <th>Contact Information</th>
                                    <th>Email</th>
                                    <th>Address</th>
                                    <th>Additional Files</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!project_suppliers.length">
                                    <td v-if="isLoading" colspan="100%" class="text-sm">
                                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                                    </td>
                                <td v-else colspan="100%">No suppliers found!</td>
                                </tr>
                                <tr v-else class="text-sm" v-for="project_supplier in project_suppliers" :key="project_supplier.id">
                                    <td>{{ project_supplier?.company_name }}</td>
                                    <td>{{ project_supplier?.contact_info }}</td>
                                    <td>{{ project_supplier?.email }}</td>
                                    <td class="text-wrap">{{ project_supplier?.address }}</td>
                                    <td>
                                        <el-button v-if="(pin_notice?.project_status == ProcurementProjectStatuses.CODE_ACTIVE || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED) || (pin_notice?.project_status == ProcurementProjectStatuses.CODE_PRE_PIN || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED)" @click="openFileModal(project_supplier, 'upload')" class="mb-1" size="small" type="primary" ><i class="fa fa-upload"></i>&nbsp;Upload PDF</el-button><br>
                                        <el-button @click="openFileModal(project_supplier, 'view')" size="small" type="success" ><i class="fa fa-list"></i>&nbsp;View PDFs ({{ (project_supplier?.uploaded_files?.file_count ? project_supplier?.uploaded_files?.file_count : 0) }})</el-button>
                                        <el-button v-if="(pin_notice?.project_status == ProcurementProjectStatuses.CODE_ACTIVE || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED) || (pin_notice?.project_status == ProcurementProjectStatuses.CODE_PRE_PIN || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED)" @click="deleteSupplier(project_supplier.id)" class="mb-1" size="small" type="danger" ><i class="fa fa-trash"></i>&nbsp;Delete</el-button><br>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        <hr>
                        <div class="card-footer pt-0">
                          <pagination :meta="project_supplier_meta" v-on:update="loadProjectSuppliers()" />
                        </div>
                    </el-tab-pane>
                </el-tabs>

            </div>
            
          </div>
        </div>

      </div>
  
      <el-dialog :before-close="loadList" destroy-on-close v-model="file_modal" width="50%">
        <SupplierFiles :if_can_delete="((pin_notice?.project_status == ProcurementProjectStatuses.CODE_ACTIVE || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PPN_ACTIVE_NOT_PUBLISHED) || (pin_notice?.project_status == ProcurementProjectStatuses.CODE_PRE_PIN || pin_notice?.project_status == ProcurementProjectStatuses.CODE_PME_ACTIVE_NOT_PUBLISHED) ? true : false)" :data="file_data" @deleteFile="deleteFile" @uploadChange="uploadchange"></SupplierFiles>
        <template v-if="!file_data.disabled" #footer>
          <div class="dialog-footer">
            <el-button @click="file_modal = false">Close</el-button>
            <el-button type="primary" @click="uploadFiles">
              Confirm
            </el-button>
          </div>
        </template>
      </el-dialog>

      <el-dialog destroy-on-close v-model="import_modal" title="Upload File" width="500">
        <form @submit="importSuppliers" v-loading="loading">
          <p>Please upload an .xlsx or .csv file. You can use the below template for the importing.<br>

            <el-button-group>
              <el-link class="me-3" type="success" link href="/template/project_supplier_official_template.xlsx">Sample Excel
                Template</el-link>
              <el-link type="primary" link href="/template/project_supplier_official_template.csv">Sample CSV
                Template</el-link>
            </el-button-group>
          </p>

          <dropzone label="Drop or select file" :max_files="1" :clean="0" :multiple="false" extensions="'xlsx,xls,csv'"
            @input="setFile($event)"></dropzone>
          <button type="submit" class="btn btn-success" v-loading="loading" :disabled="!import_form.file">
            {{ loading ? 'Saving' : 'Save' }}
          </button>
        </form>
      </el-dialog>

      <el-dialog destroy-on-close id="error-modal" :close-on-click-modal="false" v-model="error_modal" width="50%">
        <ul class="p-4 list-group">
          <template v-for="(message,i) in error_message" :key="i">
            <li v-if="message != '' " class="list-group-item"><i class="text-danger fa fa-info-circle"></i>&nbsp;{{ message
              }}</li>
          </template>
        </ul>
      </el-dialog>

      <el-dialog destroy-on-close v-model="export_modal" title="Select Export Type" width="50%">
        <el-radio-group v-model="export_type">
          <el-radio value="1" size="large" border>Registered Suppliers</el-radio>
          <el-radio value="2" size="large" border>Imported Suppliers</el-radio>
          <el-radio value="3" size="large" border>All Suppliers</el-radio>
        </el-radio-group>

        <template #footer>
          <div class="dialog-footer">
            <el-button @click="export_modal = false">Close</el-button>
            <el-button type="primary" @click="exportSuppliers">
              Export
            </el-button>
          </div>
        </template>
      </el-dialog>

    </div>
  </template>
  
  <script>
  import { ProcurementProjectParticipants } from '@/api_services/procurement_project_participants'
  import { ProcurementProjectSupplier } from '@/api_services/procurement_project_supplier'
  import SupplierFiles from './SupplierFiles.vue'
  import Dropzone from '@/components/common/Dropzone'
  import Swal from 'sweetalert2'
  import TransferComponent from '@/components/common/TransferComponent.vue'
  import { mapState } from 'vuex'

  export default {
    watch: {
      main_tab: {
        handler: function (newValue) {
          this.loadList()
        },
        deep: true
      }
    },
    props: {
        id: Number,
        project_id: Number,
        pin_notice: Object,
        type: String,
        show_imported: {
          type: Boolean,
          required: false,
          default: true
        },
        show_participants: {
          type: Boolean,
          required: false,
          default: false
        },
    },
    components: {
      SupplierFiles, Dropzone, TransferComponent
    },
    
    data() {
      return {
        export_modal: false,
        export_type: "1",
        loading: false,
        error_modal: false,
        error_message: [],
        import_form: {},
        import_modal: false,
        supplier_search_value: null,
        project_suppliers: [],
        file_delete_form:{
          id: null,
          path: null
        },
        main_tab: "registered",
        file_data:{
          id: null,
          project_id: this.project_id,
          pin_notice_id: this.id,
          disabled: false,
          files: null,
          access_type: 'upload',
          type: null,
          company_id: null,
          project_supplier_id: null
        },
        file_modal: false,
        search_value: null,
        isLoading : true,
        participants: [],
        meta: {
          page: 1,
          total: 0,
          pages: 1
        },
        project_supplier_meta: {
          page: 1,
          total: 0,
          pages: 1
        },
      }
    },
    created: function() {
      this.loadParticipants()
    },
    methods: {
      exportSuppliers(){
          Swal.fire({
            icon: 'info',
              text:
                'You are about to export these suppliers, are you sure you are going to proceed?',
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              cancelButtonText: 'Cancel',  
          }).then(result => {
              if (result.isConfirmed) {
                
                this.loading_title_message = "Exporting Suppliers"
                this.loading_content_message = "Currently exporting selected suppliers. Please wait!"

                this.loading = true
                this.globalState.loading = true
                ProcurementProjectSupplier.export({ 
                  params: { 
                      project_id: this.project_id,
                      pin_notice_id: this.id,
                      if_export: 1,
                      type: this.export_type
                }
                })
                  .then(result => {

                    const blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const link = document.createElement('a');
                    const url = window.URL.createObjectURL(blob);

                    link.href = url;
                    link.setAttribute('download', 'project_supplier_export.xlsx'); 
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url); 

                    this.export_modal = false
                    this.loading = false
                    this.globalState.loading = false
                  })
                  .catch(error => {
                    this.$toast.error('An error occured while exporting suppliers')
                    console.error('error:', error.response.data)
                  })
                  .finally(() => {
                    this.loading = false
                    this.globalState.loading = false
                  })
                
              }
          })
        },
      deleteSupplier(id) {

        Swal.fire({
          icon: 'info',
            text:
              'You are about to delete this supplier, are you sure you are going to proceed?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',  
        }).then(result => {
            if (result.isConfirmed) {

              this.globalState.loading = true
              ProcurementProjectSupplier.delete({ id: id})
                .then(result => {
                  this.$toast.success(result?.data?.message || 'Supplier has been removed successfully')
                  this.loadProjectSuppliers()
                })
                .catch(error => {
                    this.$toast.error('An error occured while deleting supplier')
                    console.error('error:', error.response.data)

                }).finally(() => {
                  this.globalState.loading = false
                })
              
            }
        })
        },
      loadList(){
        if(this.main_tab == "registered"){
          this.loadParticipants()
        }
        else{
          this.loadProjectSuppliers()
        }
      },
      importSuppliers(evt) {
          evt.preventDefault()

          var formData = new FormData()
          formData.append('project_id', this.project_id)
          formData.append('pin_notice_id', this.id)
          formData.append('file', this.import_form.file)

          this.loading = true

          ProcurementProjectSupplier.import(formData)
            .then(result => {
              if(result.data.status == 1 || result.data.status == 2){
                result.data.status == 1 ? this.$toast.success(result.data.message) : this.$toast.warning(result.data.message)
                this.loadProjectSuppliers()
              }
              else{
                this.$toast.error('Failed to import')
                this.error_message = result.data.message.split(',')
                this.error_modal = true
              }

              this.import_modal = false
              this.import_form.file = null
            })
            .catch(error => {

            }).finally(() => {
              this.loading = false
          })
        },
      setFile(files) {
        this.import_form.file = files[0]
      },
      loadProjectSuppliers(){ 
        this.isLoading = true

        ProcurementProjectSupplier.get(
        { 
            params: { 
                project_id: this.project_id,
                pin_notice_id: this.id,
                page: this.project_supplier_meta.page,
                search_value: this.supplier_search_value
          } 
        }
        )
        .then(result => {
          if (result.data.suppliers) this.project_suppliers = result.data.suppliers
          this.project_supplier_meta = result.data.meta
          this.file_modal = false
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
      },
      deleteFile(file){
        this.globalState.loading = true
        this.file_delete_form.path = file.path
        this.file_delete_form.id = this.file_data.id

        ProcurementProjectParticipants.removeSupplierParticipantFile(
          this.file_delete_form
        )
        .then(result => {
          this.$toast.success(result.data.message)
        })
        .catch(error => {
          console.error('error:', error)
        })
        .finally(() => {
          this.globalState.loading = false
        })
      },
      uploadFiles(){
        let formData = new FormData()
        formData.append('pin_notice_id', this.id)
        formData.append('project_id', this.project_id)
        formData.append('type', this.file_data.type)
        formData.append('company_id', this.file_data.company_id)
        formData.append('project_supplier_id', this.file_data.project_supplier_id)
        this.file_data.files.forEach(function(file) {
          if(file.raw){
            formData.append('files[]', file.raw)
          }
        })

        this.isLoading = true

        ProcurementProjectParticipants.uploadSupplierParticipantFiles(formData)
        .then(result => {
          this.file_modal = false
          this.$toast.success('Successfully uploaded!')
          if(this.file_data.type == 0){
            this.loadParticipants()
          }
          else{
            this.loadProjectSuppliers()
          }
          this.resetFileData()
        })
        .catch(error => {
          this.$toast.error('Failed to upload!')
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
      },
      uploadchange(files){
        this.file_data.files = files
      },
      resetFileData(){
        this.file_data = {
          id: null,
          project_id: this.project_id,
          pin_notice_id: this.id,
          disabled: false,
          files: null,
          access_type: 'upload',
          type: null,
          company_id: null,
          project_supplier_id: null
        }
      },
      openFileModal(selected_data, type){
        this.resetFileData()

        if(selected_data.uploaded_files){
          this.file_data.id = selected_data.uploaded_files.id
          this.file_data.files = selected_data.uploaded_files.files
        }

        this.file_data.access_type = type
        this.file_data.disabled = (type == "view" ? true : false)
        if(this.main_tab == "registered"){
          this.file_data.type = 0
          this.file_data.company_id = selected_data.id
        }
        else{
          this.file_data.type = 1
          this.file_data.project_supplier_id = selected_data.id
        }

        this.file_modal = true
      },
      loadParticipants(){ 
        this.isLoading = true

        ProcurementProjectParticipants.loadSupplierCompanyParticipants(this.id, 
        { 
            params: { 
                participation_type: this.type,
                page: this.meta.page,
                search_value: this.search_value
          } 
        }
        )
        .then(result => {
          if (result.data.suppliers) this.participants = result.data.suppliers
          this.meta = result.data.meta
          this.file_modal = false
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    computed: {
      ...mapState(['ProcurementProjectStatuses']),
    }
  }
  </script>
  