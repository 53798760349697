<template>
    <div>
      <div class="row">
        <div class="col-md-12">
          <div class="card my-3">
            
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <div class="bg-white border-radius-lg d-flex me-2">
                    <input type="text" v-on:keyup.enter="loadLeaveRequest" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="search_value"
                      placeholder="Search" />
                  </div>
                </nav>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr class="text-sm">
                      <th>Supplier</th>
                      <th>Project</th>
                      <th>Reason</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!participants.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No leave request</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="participant in participants" :key="participant.id">
                      <td>{{ participant?.user.name }}</td>
                      <td>{{ participant?.procurement_project.name }}</td>
                      <td>{{ type == "pin" ? participant?.pin_user_leave_reason : participant?.itt_user_leave_reason }}</td>
                      <td>
                        <el-button
                        
                              type="primary" size="small" @click="openLeaveRequestModal(participant)" >
                                <i class="fa fa-edit"></i>&nbsp;Manage</el-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="loadLeaveRequest()" />
            </div>
          </div>
        </div>

      </div>
  
  
    </div>

    <el-dialog id="modal-leave" v-model="leave_modal" title="Leave Request Response" width="500">
      <div v-if="selected_request">
        {{ selected_request?.user.name }} wants to leave the {{ type }} for the project: {{ selected_request?.procurement_project.name }}:<br>
        <el-switch
            v-model="status_switch"
            size="large"
            style="--el-switch-on-color: red; --el-switch-off-color: #13ce66"
            active-text="Reject"
            inactive-text="Approve"
            @change="change"
        />
        <br>
        <label v-if="if_admin_rejected == 1" class="h6 mt-3">Reason:</label>
        <el-input v-if="if_admin_rejected == 1" autosize type="textarea" class="mt-1" v-model="leave_reason" placeholder="Reason here..." />
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="leave_modal = false">Cancel</el-button>
          <el-button type="danger" @click="saveLeaveRequest(selected_request)">
            Confirm
          </el-button>
        </div>
      </template>
    </el-dialog>

  </template>
  
  <script>
  import { ProcurementProjectParticipants } from '@/api_services/procurement_project_participants'
  import Swal from 'sweetalert2';
  
  export default {
    watch: {
      search_value: function () {
        //if (!this.search_value || this.search_value.length > 2) this.loadLeaveRequest()
      }
    },
    props: {
        type: String  
    },
    components: {
      
    },
    
    data() {
      return {
        leave_reason: null,
        status_switch: false,
        if_admin_rejected: 0,
        selected_request: null,
        leave_modal: false,
        search_value: null,
        isLoading : true,
        participants: [],
        meta: {
          page: 1,
          total: 0,
          pages: 1
        },
        form: {
          participation_type: null,
          procurement_project_id: null,
          leave_request_type: null,
          reason: null,
          if_admin_rejected: null,
          target_supplier_user_id: null
        }
      }
    },
    created: function() {
      this.loadLeaveRequest()
    },
    methods: {
        openLeaveRequestModal(leave_request){
        this.selected_request = leave_request,
        this.leave_modal = true
      },
      saveLeaveRequest(leave_request){
        if((this.leave_reason && this.leave_reason !== "" && this.if_admin_rejected == 1) || this.if_admin_rejected == 0 ){
          Swal.fire({
            title: 'Continue?',
            text: 'Do you want to proceed?',
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
          }).then((result) => {
            if (result.isConfirmed) {
              this.globalState.loading = true
              
              let target_id = leave_request?.pin_notice?.id
              if(this.type == "itt"){
                target_id = leave_request?.itt_framework?.id
              }

              this.form.participation_type = this.type
              this.form.procurement_project_id = leave_request?.procurement_project.id
              this.form.leave_request_type = 1
              this.form.reason = this.leave_reason
              this.form.if_admin_rejected = this.if_admin_rejected
              this.form.target_supplier_user_id = leave_request.user_id

              ProcurementProjectParticipants.leaveParticipant(target_id, this.form)
              .then(result => {
                this.leave_reason = null
                this.selected_pin = null
                this.leave_modal = false
                this.status_switch = false
                this.if_admin_rejected = 0

                this.$toast.success(result.data.message)
                this.loadLeaveRequest()
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })
              
            }
          });
        }
        else{
          this.$toast.warning("Please enter a reason for rejecting.")
        }
      },
      change(value){
        if(value){
            this.if_admin_rejected = 1
        }
        else{
            this.if_admin_rejected = 0
        }
      },
      loadLeaveRequest(){
        this.isLoading = true

        ProcurementProjectParticipants.loadLeaveRequest(
        { params: { 
            participation_type: this.type,
            page: this.meta.page,
            search_value: this.search_value
          } 
        }
        )
        .then(result => {
          if (result.data.leave_request) this.participants = result.data.leave_request
          this.meta = result.data.meta
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    computed: {
  
    }
  }
  </script>
  