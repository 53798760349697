<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12" v-if="tpp_procurement_type === TPPNoticeTypes.UK2.value">
                    <label>Publication date of the tender or transparency notice (estimated) (optional)</label>
                    <br/><el-date-picker v-model="form.publication_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                </div>
                <div class="col-md-12" v-if="tpp_procurement_type === TPPNoticeTypes.UK3.value">
                    <label>Publication date of the tender or transparency notice (estimated)</label>
                    <div :class="{ 'has-error-field': v.form.publication_date.$invalid }" style="width: 224px;">
                        <el-date-picker v-model="form.publication_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                    </div>
                    <div v-if="v.form.publication_date.$invalid" class="error-text" >Publication date is required</div>
                </div>
                <div class="row" v-if="tpp_procurement_type === TPPNoticeTypes.UK4.value ||
                tpp_procurement_type === TPPNoticeTypes.UK3.value">
                    <div class="col-md-6">
                        <label>Enquiry deadline (optional)</label>
                        <br/><el-date-picker v-model="form.enquiry_deadline" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                    </div>
                    <div class="col-md-6" v-if="procedure?.is_inviting_requests === 0 && procedure?.threshold === 1">
                        <label>Deadline for requests to participate</label>
                        <div :class="{ 'has-error-field': v.form.request_deadline.$invalid }" style="width: 224px;">
                            <el-date-picker v-model="form.request_deadline" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disableBeforeEnquiryDates"></el-date-picker>
                        </div>
                        <div v-if="v.form.request_deadline.$invalid" class="error-text" >Deadline is required</div>
                    </div>
                    <div class="col-md-6" v-if="procedure?.is_inviting_requests === 1 || procedure?.threshold === 0 || aboveThreshold">
                        <label>Tender submission deadline</label>
                        <div :class="{ 'has-error-field': v.form.submission_deadline.$invalid }" style="width: 224px;">
                            <el-date-picker v-model="form.submission_deadline" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                        </div>
                        <div v-if="v.form.submission_deadline.$invalid" class="error-text" >Deadline is required</div>
                    </div>
                    <div class="col-md-4">
                        <br/><label>May tenders be submitted electronically?</label>
                        <br/><label style="color: #a9a9a9">Electronic submissions is required if possible.</label>
                        <div :class="{ 'has-error-field': v.form.can_submit_electronically.$invalid }" style="width: 116px;  height: 35px">
                            <el-radio-group v-model="form.can_submit_electronically">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <br/><el-button type="primary" @click="form.can_submit_electronically = null">Clear selection</el-button>
                        <div v-if="v.form.can_submit_electronically.$invalid" class="error-text" >Yes/No is required</div>
                    </div>
                    <div class="col-md-8">
                        <br/><label>Submission address and any special instructions</label>
                        <br/><label style="color: #a9a9a9">Must include a website if tenders may be submitted electronically. For example, https://www.example.com/.</label>
                        <div :class="{ 'has-error-field': v.form.submission_instructions.$invalid }">
                            <el-input v-model="form.submission_instructions" type="textarea" :rows="3"></el-input>
                        </div>
                        <div v-if="v.form.submission_instructions.$invalid" class="error-text" >Address and instructions is required</div>
                    </div>
                    <div class="col-md-12">
                            <el-checkbox v-model="form.use_electronic_auction" :checked="form.use_electronic_auction">An electronic auction will be used (optional)</el-checkbox>
                    </div>
                    <div class="col-md-12" v-if="aboveThreshold">
                        <label>Languages that may be used for submission</label>
                        <div :class="{ 'has-error-field': v.form.languages.$invalid }">
                            <el-select v-model="form.languages" placeholder="Select" style="width: 100%;" filterable multiple>
                                <el-option v-for="(name, abbrev) in languages" :value="abbrev" :label="name"></el-option>
                            </el-select>
                        </div>
                        <div v-if="v.form.languages.$invalid" class="error-text" >Language is required</div>
                    </div>
                    <div class="row" v-if="procedure?.is_inviting_requests === 0 && !scope?.divided_to_lots">
                        <div class="col-md-12">
                            <label>Number of suppliers to be invited to tender</label>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Minimum (optional)</label>
                            <el-input v-model="form.minimum_supplier"></el-input>
                            <div v-if="v.form.minimum_supplier.$invalid" class="error-text" >Minimum should be numeric</div>
                        </div>
                        <div class="col-md-6">
                            <br/><label>Maximum (optional)</label>
                            <el-input v-model="form.maximum_supplier"></el-input>
                            <div v-if="v.form.maximum_supplier.$invalid" class="error-text" >Maximum should be numeric</div>
                        </div>
                        <div class="col-md-12">
                            <br/><label>Criteria used to select suppliers to tender</label>
                            <br/><label style="color: #a9a9a9">If a maximum number will be invited</label>
                            <el-input v-model="form.supplier_criteria" type="textarea" :rows="3"></el-input>
                        </div>
                    </div>
                     <div class="col-md-4">
                        <br/><label>Award decision date (estimated)</label>
                        <div :class="{ 'has-error-field': v.form.decision_date.$invalid }" style="width: 224px;">
                            <el-date-picker v-model="form.decision_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                        </div>
                        <div v-if="v.form.decision_date.$invalid" class="error-text" >Award decision date is required</div>
                     </div>
                     <div class="col-md-4" v-if="aboveThreshold">
                        <br/><label>Is this a recurring procurement?</label>
                        <div :class="{ 'has-error-field': v.form.is_recurring_procurement.$invalid }" style="width: 116px; height: 35px">
                            <el-radio-group v-model="form.is_recurring_procurement">
                                <el-radio-button :value="true">Yes</el-radio-button>
                                <el-radio-button :value="false">No</el-radio-button>
                            </el-radio-group>
                        </div>
                        <br/><el-button type="primary" @click="form.is_recurring_procurement = null">Clear selection</el-button>
                        <div v-if="v.form.is_recurring_procurement.$invalid" class="error-text" >Yes/No is required</div>
                     </div>
                     <div class="col-md-5" v-if="form.is_recurring_procurement">
                        <br/><label>Publication date of next tender notice (estimated)</label>
                        <div :class="{ 'has-error-field': v.form.recurrence_publication_date.$invalid }" style="width: 224px">
                            <el-date-picker v-model="form.recurrence_publication_date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" :disabled-date="disablePastDates"></el-date-picker>
                        </div>
                        <div v-if="v.form.recurrence_publication_date.$invalid" class="error-text" >Publication date is required</div>
                     </div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
    import {
    useVuelidate
    } from '@vuelidate/core'
    import {
    required, numeric,
    requiredIf, url
    } from '@vuelidate/validators'

    const SUBMISSION = 'SUBMISSION';

    export default {
        props: ['previous_form', 'saved_form', 'is_not_submitted', 'tpp_procurement_type', 'contract_start_date', 'engagement_deadline', 'languages', 'procedure'],
        data() {
            return {
                form: {
                    publication_date: null,
                    // Uk4
                    enquiry_deadline: null,
                    submission_deadline: null,
                    request_deadline: null,
                    can_submit_electronically: null,
                    languages: [],
                    minimum_supplier: null,
                    maximum_supplier: null,
                    supplier_criteria: null,
                    submission_instructions: null,
                    decision_date: null,
                    is_recurring_procurement: null,
                    recurrence_publication_date: null,
                    use_electronic_auction: null
                },
            }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form
        },
        computed: {
            ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
            aboveThreshold() {
                return this.procedure && (this.procedure.threshold === 0 || this.procedure.threshold === 1)   
            }
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        validations() {
            let rules = {
                form: {
                    publication_date: {
                        required: requiredIf(function () { return this.tpp_procurement_type === this.TPPNoticeTypes.UK3.value }),
                        $autoDirty: true
                    },
                    submission_deadline: {
                        required: requiredIf(function () { return this.tpp_procurement_type === this.TPPNoticeTypes.UK3.value }),
                        $autoDirty: true
                    }
                }
            }

            if(this.tpp_procurement_type === this.TPPNoticeTypes.UK3.value || this.tpp_procurement_type === this.TPPNoticeTypes.UK4.value) {
                let uk4 = {
                    submission_deadline: {
                        required,
                        $autoDirty: true
                    },
                    request_deadline: {
                        required: requiredIf(function () { return this.procedure.is_inviting_requests === 0 }),
                        $autoDirty: true
                    },
                    can_submit_electronically: {
                        required: requiredIf(function () { return this.tpp_procurement_type === this.TPPNoticeTypes.UK4.value }),
                        $autoDirty: true
                    },
                    submission_instructions: {
                        required: requiredIf(function () { return this.tpp_procurement_type === this.TPPNoticeTypes.UK4.value }),
                        $autoDirty: true
                    },
                    decision_date: {
                        required,
                        $autoDirty: true
                    },
                    is_recurring_procurement: {
                        required: requiredIf(function () { return this.tpp_procurement_type === this.TPPNoticeTypes.UK4.value }),
                        $autoDirty: true
                    },
                    use_electronic_auction: {
                        required,
                        $autoDirty: true
                    },
                    languages: {
                        required: requiredIf(function () { return this.tpp_procurement_type === this.TPPNoticeTypes.UK4.value }),
                        $autoDirty: true
                    },
                    minimum_supplier: {
                        numeric,
                        $autoDirty: true
                    },
                    maximum_supplier: {
                        numeric,
                        $autoDirty: true
                    },
                    supplier_criteria: {
                        required: requiredIf(function () { return this.form.maximum_supplier }),
                        $autoDirty: true
                    },
                    recurrence_publication_date: {
                        required: requiredIf(function () { return this.form.is_recurring_procurement }),
                        $autoDirty: true
                    }
                }

                rules.form = {...rules.form, ...uk4}
            }

            return rules
        },
        methods: {
            saveForm() {
                this.$emit('save', SUBMISSION, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
            // disablePastDates(date) {
            //     const startDate = new Date(this.contract_start_date)
            //     const engagementDeadline = new Date(this.engagement_deadline)
            //     startDate.setDate(startDate.getDate() - 1)
            //     engagementDeadline.setDate(engagementDeadline.getDate() - 1)
            //     return date < engagementDeadline || date > startDate
            // },
            disablePastDates(date) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return date < today;
            },
            disableBeforeEnquiryDates(date)
            {
                const startDate = new Date(this.form.contract_start_date)
                const enquiryDeadline = new Date(this.form.enquiry_deadline)
                startDate.setDate(startDate.getDate() - 1)
                enquiryDeadline.setDate(enquiryDeadline.getDate() - 1)
                return date < enquiryDeadline || date > startDate
            }
        }
     }
 </script>