<template>
  <div class="card">
    <div class="card-body">
      <div>
        <el-button-group class="mb-3">
          <el-button v-if="$store.state.checkUserGroupPermissions('meeting', 'create', true) || isOriginator" size="large" type="primary" class="" @click="meetingModal = true" 
          >Set a meeting</el-button>

          <el-button v-if="$store.state.checkUserGroupPermissions('meeting', 'external_invitation', true) || isOriginator" size="large" type="success" class="" @click="invite_modal = true" 
          ><i class="fa-solid fa-user-plus"></i>&nbsp;Invite External Users</el-button>
        </el-button-group>
      </div>
      <div
      v-if="
        ($store.state.checkUserGroupPermissions('meeting', 'view_list', true))
      "
      class="table-responsive">
        <table class="table table-hover table-fluid align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Organiser
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Organisation
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Meeting Title
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Start Date  
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                End Date
              </th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                Actions
              </th>
            </tr>
            <tr v-for="(meeting, index) in meetings" :key="index">
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.organizer.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.company.name }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.title }}
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.start_datetime}}  
              </td>
              <td class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                {{ meeting.end_datetime }}
              </td>
              <td class="fit-cell text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                <el-button-group>
                  <el-button v-if="$store.state.checkUserGroupPermissions('meeting', 'join', true)" @click="openJaasModal(meeting)" size="small" type="success">Join meeting</el-button>
                  <el-button v-if="$store.state.checkUserGroupPermissions('meeting', 'view_details', true)" @click="openMeetingModal(meeting)"  size="small" type="primary">Meeting Details</el-button>
                  <el-button v-if="($store.state.checkUserGroupPermissions('meeting', 'external_invitation', false) && meeting.company_id == user.company.id) || isOrganizer(meeting)" @click="showInvitations(meeting.id)"  size="small" type="warning">External Invitations</el-button>
                  <el-button v-if="($store.state.checkUserGroupPermissions('meeting', 'remove', false) && meeting.company_id == user.company.id) || isOrganizer(meeting)" @click="deleteMeeting(meeting.id)"  size="small" type="danger">Cancel/Remove Meeting</el-button>
                </el-button-group>
              </td>
            </tr>
          </thead>
        </table>
      </div>
      <div v-else>
              <el-empty description="Your group doesnt have access to this list!" />
      </div>
    </div>
  </div>

  <el-dialog
      v-model="jaasModal" 
      title="Meet"
      width="67%"
      @close="closeJaasModal()"
    >
    <div>
      <jaas-component :key="selected_meeting ? 'jaas_component_' + selected_meeting.id : 'jaas_component_default'" :meeting="selected_meeting"></jaas-component>
    </div>
  </el-dialog>

    <el-dialog
      v-model="meetingModal" 
      :title = "selected_meeting ? 'Meeting Details' : 'Schedule Meet the Buyer Event'"
      width="67%"
      @close="closeMeetingModal()"
    >
      <div>
        <meeting-details :key="selected_meeting ? 'meeting_details_' + selected_meeting.id : 'meeting_details_default'" @close_modal="closeMeetingModal()" @update="getMeetings()" :meeting="selected_meeting" :currentUser="savedUser"></meeting-details>
      </div>
    </el-dialog>

    <el-dialog id="modal-invite" v-model="invite_modal" title="Invite Participants to join this Event?" 
    width="80%">
      
      <div class="form-group">
        <label class="form-label">Select Meeting</label>
          <el-select @change="meetingSelectChange" placeholder="Select or search a meeting" filterable v-model="selected_meeting_for_invite" value-key="title" class="">
            <template v-for="(meeting, index) in meetings">
              <el-option  v-if="isOrganizer(meeting)" :value="meeting" :label="meeting.title"></el-option>
            </template>
        </el-select>       
      </div>

        <ExternalInvitationImport
              v-if="selected_meeting_for_invite"
              :invitation_list="external_invitations"
              :button_size="'default'"
              :add_text="'Add External User Email'"
              :tag_size="'large'"
              @update="getSelectedExternalUser"
        ></ExternalInvitationImport>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="invite_modal = false">Cancel</el-button>
          <el-button :disabled="!external_users" type="primary" @click="inviteExternalUsers"><i class="fa fa-save"></i>&nbsp;Invite</el-button>
        </div>
      </template>
    </el-dialog>

    <el-drawer v-model="invitation_drawer" :show-close="true" size="70%" direction="rtl" :destroy-on-close="true"
      :append-to-body="true">
      <div class="container">
        <ExternalInvitationImport :show_invite_tab="false"
        :invitation_list="external_invitations"></ExternalInvitationImport>
      </div>
    </el-drawer>

    <el-dialog
      v-model="responseModal" 
      title = "Response to this invitation"
      width="50%"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="responseModal = false"
    >

    <div class="row mb-4">
        <p>Please update your attendance to this meeting by selecting a status from below and click the "Update Attendance" button.</p>
        <div class="col-md-8">
            <el-select v-model="selected_meeting_attendance_status" placeholder="Select an Attendance Status" style="width: 100%;">
            <el-option :label="'Attend this Meeting'" :value="1"></el-option>
            <el-option :label="'Decline this Meeting'" :value="2"></el-option>
            <el-option :label="'Tentative'" :value="3"></el-option>
            </el-select>
        </div>
        <div class="col-md-4">
            <el-button @click="updateAttendanceStatus()" size="medium" type="success">Update Attendance</el-button>
        </div>
    </div>  
    
    <div class="row mb-4" v-if="selected_meeting_for_details && selected_meeting_for_details.calendar_links && if_invite_responded == 1 && (selected_meeting_attendance_status == 1 || selected_meeting_attendance_status == 3)">
        <div class="col-md-12">
            <label>Add this Meeting to:</label><br>
            <el-button-group>
                <el-button @click="addToCalendar(selected_meeting_for_details.calendar_links['Google Calendar'])" 
                type="success"><i class="fa fa-google" aria-hidden="true"></i>&nbsp;Google Calendar</el-button>

                <el-button @click="addToCalendar(selected_meeting_for_details.calendar_links['Yahoo Calendar'])" 
                type="primary"><i class="fa fa-yahoo" aria-hidden="true"></i>&nbsp;Yahoo Calendar</el-button>

                <el-button @click="addToCalendar(selected_meeting_for_details.calendar_links['Outlook Office Calendar'])" 
                type="danger"><i class="fa fa-windows" aria-hidden="true"></i>&nbsp;Outlook Office Calendar</el-button>

                <el-button @click="addToCalendar(selected_meeting_for_details.calendar_links['Outlook Live Calendar'])" 
                type="warning"><i class="fa fa-windows" aria-hidden="true"></i>&nbsp;Outlook 365 Calendar</el-button>
            </el-button-group>
        </div>
    </div>

    <h5>Meeting Details</h5>
    <div class="form-group">
      <el-descriptions
        class="margin-top"
        :column="2"
        :size="size"
        border
      >
        <el-descriptions-item :span="3">
          <template #label>
            <div class="cell-item">
              Title
            </div>
          </template>
          {{ selected_meeting_for_details.title }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              Meeting Start Date
            </div>
          </template>
          {{ $filters.datetimeFormatFilter(selected_meeting_for_details.start_datetime) }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">
              Meeting End Date
            </div>
          </template>
          {{ $filters.datetimeFormatFilter(selected_meeting_for_details.start_datetime) }}
        </el-descriptions-item>
        
        <el-descriptions-item :span="2">
          <template #label>
            <div class="cell-item">
              Description
            </div>
          </template>
          {{ selected_meeting_for_details.description }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <template v-if="if_invite_responded == 1" #footer>
        <div class="dialog-footer">
          <el-button type="warning" @click="backToMeeting">Back to Meeting</el-button>
        </div>
      </template>
    </el-dialog>

</template>

<script>
  import { mapState } from 'vuex'
  import Modal from '@/components/common/Modal'
  import JaasComponent from '@/components/common/Jaas/jaas.vue'
  import MeetingDetails from '@/components/common/Jaas/jaas_meeting_details.vue'
  import { Jaas } from '@/api_services/jaas'
  import ExternalInvitationImport from '@/components/common/ExternalInvitationImport.vue'
  import Swal from 'sweetalert2'

  export default {
    components: {
      Modal,
      JaasComponent, 
      MeetingDetails,
      ExternalInvitationImport
    },
    props: {

    },
    data() {
      return {
        if_invite_responded: 0,
        selected_meeting_attendance_status: null,
        selected_meeting_for_details: null,
        responseModal: false,
        invitation_drawer: false,
        external_invitations: [],
        selected_meeting_for_invite: null,
        invite_modal: false,
        external_users: null,
        attachFileForm: null,
        jaasModal: false,
        meetingModal: false,
        meetings: null,
        selected_meeting: null
      }
    },
    computed: {
      ...mapState(['savedUser']),
      isOriginator() {
        return this.currentRole == "originator"
      },
      currentRole() {
        const roleNames = (this.savedUser.role_names || []).map(r => r.toLowerCase())
        if (roleNames.includes('originator')) {
          return 'originator'
        }
        return null
      },
      user() {
        return this.$store.state.savedUser // get state
      }
    },
    created () {
      if(this.$store.state.checkUserGroupModuleRestriction('meet-a-buyer-mod') == 1){
        window.location.href = "/"
      }

      this.getMeetings(1)
    },
    methods: {
      backToMeeting(){
        window.location.href = '/main/meetings'
      },
      addToCalendar(url){
        window.open(url, '_blank').focus()
      },
      updateAttendanceStatus() {
            Jaas.updateAttendance(this.savedUser.id, {meeting_id: this.selected_meeting_for_details.id, attendance_status: this.selected_meeting_attendance_status}).
            then( result => {
                this.$toast.success('Attendance has been updated');
                this.selected_meeting_for_details = result.data.updated_attendance
                this.if_invite_responded = 1
            })
      },
      openMeetingResponseModal(meeting) {
        this.selected_meeting_for_details = meeting
        let this_user = this.savedUser

        this.selected_meeting_attendance_status = this.selected_meeting_for_details.invitees.filter(function( obj ) {
          return obj.id == this_user.id
        })[0].pivot.attendance_status
        this.selected_meeting_attendance_status = this.selected_meeting_attendance_status == 0 ? null : this.selected_meeting_attendance_status

        if(this.selected_meeting_attendance_status && this.selected_meeting_attendance_status > 0){
          this.if_invite_responded = 1
        }

        this.responseModal = true
      },
      getUrlTargetId(){
        var target_id = null
        const url = window.location.search
        const url_params = new URLSearchParams(url)
        
        if (url_params.get("target")) {
          try {
            target_id = atob(url_params.get("target"))

            const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)
            if(!isNumeric(target_id)){
              target_id = null
            }
          } catch(e) {
            target_id = null
          }
        }
        
        return target_id
      },
      showInvitations(id){
        this.loadInvitations(id)
        this.invitation_drawer = true
      },
      meetingSelectChange(){
        this.loadInvitations(this.selected_meeting_for_invite?.id)
      },
      loadInvitations(id){ 
        this.isLoading = true

        Jaas.loadExternalInvitations(id, 
          { 
              params: { 
                
            } 
          }
          )
          .then(result => {
            if (result.data.invitations) this.external_invitations = result.data.invitations
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.isLoading = false
          })
      },
      inviteExternalUsers(){
        Swal.fire({
          icon: 'info',
            text:
              'You are about to invite these participants into joining this meet a buyer event, are you sure you are going to proceed?',
            showCancelButton: true,
            confirmButtonText: 'Proceed',
            cancelButtonText: 'Cancel',  
        }).then(result => {
            if (result.isConfirmed) {
              
              this.globalState.loading = true

              this.loading = true
              Jaas.inviteExternalUsers(
                this.selected_meeting_for_invite.id,
                {
                    invite_participant_type: this.invite_participant_type,
                    invited_users: JSON.stringify(this.external_users)
                }
              ).then(result => {
                  this.$toast.success(result.data.message)
                  this.selected_meeting_for_invite = null
                  this.external_users = null
                  this.invite_modal = false
                })
                .catch(error => {
                  this.$toast.error('An error occured while inviting external users')
                  console.error('error:', error.response.data)
                })
                .finally(() => {
                  this.globalState.loading = false
                })
              
            }
        })
      },
      getSelectedExternalUser(values){
        //const array = values.split(",");
        //this.external_users = array
        this.external_users = values
      },
      isOrganizer(meeting) {
        return meeting.organizer_id == this.savedUser.id
      },
      getMeetings(show_target = 0) {
        Jaas.get().then(result => {
          this.meetings = [...result.data.invitee_meetings, ...result.data.organizer_meetings]

          if(show_target == 1){
            for (var x = 0; x <= this.meetings.length - 1; x++) {
              if(this.meetings[x].id == this.getUrlTargetId()){
                this.openMeetingResponseModal(this.meetings[x])
              }
            }
          }
        })
      },
      openJaasModal(meeting) {
        this.selected_meeting = meeting
        if(this.selected_meeting) this.jaasModal = true;
      },
      closeJaasModal() {
        this.selected_meeting = null;
        this.jaasModal = false
      },
      openMeetingModal(meeting) {
        this.selected_meeting = meeting
        this.meetingModal = true;
      },
      closeMeetingModal() {
        this.selected_meeting = null;
        this.meetingModal = false
      },
      deleteMeeting(id) {
        Jaas.delete(id).then(deleted => {
          this.$toast.success('Selected meeting has been deleted')
          this.getMeetings()
        })
      }
    }
  }

</script>
<style scoped>

</style>