<template>
    <div>
      
      <div style="text-align: left;" class="row" v-if="pin_notice">
        <div class="col-md-12">
          <div class="">
            <div class="card-body px-0 pb-2">
              <div class="row">
                <div class="col-md-12">
                  <el-button plain type="primary" @click="getLots()" size="small"
                    style="margin-left: 10px;float: right;">Refresh</el-button>
                </div>
              </div>
  
              <div class="table-responsive" style="max-height: 500px;" v-loading="loading">
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-sm font-weight-bolder">
                        Name
                      </th>
                      <th class="text-uppercase text-secondary text-sm font-weight-bolder">
                        Awardee Limit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!lots.length && !loading">
                      <td colspan="2" class="small-note">No data found</td>
                    </tr>
                    <tr v-for="lot in lots" :key="lot.id">
                      <td class="text-sm">
                        {{ lot.name }}
                      </td>
                      <td class="text-sm">

                        <el-input-number style="width: 200px;" @change="awardLimitChange(lot)" v-model="lot.awardees_limit" :min="0" :max="10000">
                            <template #prefix>
                                <span>{{  (!lot.awardees_limit || lot.awardees_limit == 0) ? 'All Awardees' : '' }}</span>
                            </template>
                        </el-input-number>
                        <el-tooltip :enterable="false" placement="top-start">
                          <template #content>
                            <span>Update Limit</span>
                          </template>
                          <el-button class="ms-2" size="default" type="primary" @click="saveLimit(lot)"><i class="fa fa-edit"></i>Update</el-button>
  
                        </el-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td v-if="loading">Loading....</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="getLots()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {
    SupplierhubLot
  } from '@/api_services/supplierhub_lot'
  import {
    mapState
  } from 'vuex'
  
  export default {
    components: {  },
    props: {
      pin_notice: {
        type: Object,
        required: false,
        default: null
      },
      itt_framework: {
        type: Object,
        required: false,
        default: null
      },
    },
    data() {
      return {
        itt_framework_id: null,
        pin_notice_id: null,
        lots: [],
        term: null,
        status: 1,
        loading: false,
        selected: null,
        meta: {
          page: 1,
          total: 0,
          pages: 1
        }
      }
    },
    watch: {
      status: function () {
        this.getLots()
      }
    },
    computed: {
      ...mapState(['savedUser']),
      this_user() {
        return this.$store.state.savedUser
      }
    },
    created: function () {
      if (this.itt_framework) this.itt_framework_id = this.itt_framework.id
      this.getLots()
    },
    methods: {
      saveLimit(lot){
        SupplierhubLot.update(lot)
        .then(result => {
            this.$toast.success('Saved!')
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            
        })
      },
      awardLimitChange(lot){
        if(lot.awardees_limit == 0){
            lot.awardees_limit = null
        }
      },
      checkIfNotJoined() {
        if (
          this.itt_framework &&
          !this.itt_framework.if_user_joined &&
          this.itt_framework?.user?.id != this.this_user.id &&
          this.this_user?.company?.id != this.itt_framework?.company?.id
        ) {
          return true
        }
        return false
      },
      search() {
        this.meta.page = 1
        this.getLots()
      },
      getLots() {
        let params = {
          page: this.meta.page,
          term: this.term,
          pin_notice_id: this.pin_notice?.id,
          if_pin_notice: 1
        }
        this.loading = true
        SupplierhubLot.get({
            params: params
          })
          .then(result => {
            if (result.data.lots) this.lots = result.data.lots
            this.meta = result.data.meta
            this.loading = false
          })
          .catch(error => {
            this.$toast.error('An error occured while fetching lots')
            console.error('error:', error.response.data)
          })
          .finally(() => {})
      }
    }
  }
  </script>
  
  <style scoped>
  .completed {
    background: #d3ffe3;
  }
  </style>
  