<template>
    <el-text size="large">Group: {{ group_data.name }}</el-text>
    <hr class="mt-1">
    <div class="row">
        <div class="col-md-12 mb-2">
            <TransferComponent 
            v-if="group_users"
            :option_format="'group_users'" 
            :filter_placeholder="'Search...'" 
            :transfer_data="group_users" 
            :left_title="'Users from your company'"
            :right_title="'Selected Users for this group'" 
            :model_data="selected_group_users"
            @update_value="getSelectedUsers"></TransferComponent>
        </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-2">
        <hr class="mt-1">
        <el-button @click="saveUsers" type="primary"><i class="fa fa-save"></i>&nbsp;Save</el-button>
      </div>
    </div>
  </template>
  
  <script>

  import { Group } from '@/api_services/group';
  import TransferComponent from '@/components/common/TransferComponent.vue'

    export default {
      props: {
        selected_group_data: {
          type: Object,
          required: true,
          default: []
        },
        company: {
          type: String,
          required: true,
          default: ""
        },
      },
      components: {
        TransferComponent
      },
      data() {
        return {
            selected_group_users: [],
            group_users: null,
            selected_users: null
        }
      },
      created: function () {
        this.loadGroupUsers()
      },
      watch: {
        selected_group_data: {
          handler(newValue) {
            this.group_data=newValue
            this.loadGroupUsers()
          },
          deep: true
        },
        company: {
          handler(newValue) {
            this.company_id=newValue
            this.loadGroupUsers()
          },
          deep: true
        },
      },
      computed: {
        group_data(){
          return this.selected_group_data
        },
        company_id(){
          return this.company
        } 
      },
      methods: {
        loadGroupUsers(){
          this.globalState.loading = true
          Group.loadGroupUsers({
              params: {
                group_id: this.group_data.id,
                company_id: this.company_id
              }
            })
            .then(result => {
              var self = this

              if (result.data.group_users) this.group_users = result.data.group_users
              if (result.data.selected_group_users){
                result.data.selected_group_users.forEach(function(x){ 
                    self.selected_group_users.push(x.id)
                })
              }
            })
            .catch(error => {
    
            })
            .finally(() => {
              this.globalState.loading = false
            })
        },
        getSelectedUsers(value){
            this.selected_users = value
        },
        saveUsers(){
            if(!this.group_data?.group_permissions || this.group_data?.group_permissions.length == 0){
              this.$toast.warning('Please configure the permissions for this group first before you can save users!')
              return false
            }

            this.globalState.loading = true
            Group.saveGroupUsers({
                selected_users: this.selected_users,
                group_id: this.group_data.id
            })
            .then(resp => {
              
              this.$toast.success('Group users has been saved successfully')
              this.$emit('save', true)
            })
            .catch(error => {
              console.error('error:', error.response.data)
            })
            .finally(() => {
                this.globalState.loading = false
            })
        }
      }
    }
  </script>
  