<template>
    <div>
        <el-button type="primary" @click="getPrevious()" v-if="previous_form">Fetch Previous Data</el-button>
    </div>
    <div class="card-body">
        <el-card>
            <div class="row">
                <div class="col-md-12">
                    <br/><label>Payment terms</label>
                    <br/><label style="color: #a9a9a9">
                        In addition to payments being made within 30 days for valid, undisputed invoices that are not for concession contracts, utilities contracts awarded by private utilities, or contracts awarded by schools. You may also describe the processes for submitting invoices and dealing with disputed invoices.</label>
                    <el-input v-model="form.payment_terms" type="textarea" :rows="3"></el-input>
                </div>
                <div class="col-md-12">
                    <br/><label>Description of risks to contract performance</label>
                    <br/><label style="color:#a9a9a9">Include anything that could jeopardise the satisfactory performance of the contract. Only risks described in the tender or transparency notice can be used to justify contract modification.</label>
                    <el-input v-model="form.risk_description" type="textarea" :rows="3"></el-input>
                </div>
                <label class="col-md-12" style="margin-top: 20px">Relevant trade agreements</label>
                <label style="color:#a9a9a9">Select all that apply</label>
                <div class="col-md-12">
                    <el-checkbox v-model="form.is_gpa" :checked="form.is_gpa">Government Procurement Agreement</el-checkbox>
                </div>
                <div class="col-md-12">
                    <el-checkbox v-model="form.is_cptpp" :checked="form.is_cptpp">Comprehensive and Progressive Agreement for Trans-Pacific Partnership (CPTPP)</el-checkbox>
                </div>
                <div class="col-md-12" v-if="tpp_procurement_type === TPPNoticeTypes.UK4.value">
                    <br/><label>A conflicts assessment has been prepared and revised</label>
                    <div :class="{ 'has-error-field': v.form.has_conflict_assessment.$invalid }">
                        <el-checkbox v-model="form.has_conflict_assessment" :checked="form.has_conflict_assessment">A conflicts assessment has been prepared and revised</el-checkbox>
                    </div>
                    <div v-if="v.form.has_conflict_assessment.$invalid" class="error-text" >Yes/No is required</div>
                </div>
            </div>
        </el-card>
    </div>
    <span class="col-md-3 mb-3" v-if="is_not_submitted"><el-button type="success" @click="saveForm()">Save and go to Next Section</el-button></span>
 </template>
 
 <script>
    import { mapState } from 'vuex'
     import {
     useVuelidate
     } from '@vuelidate/core'
     import {
     required
     } from '@vuelidate/validators'
import { TPPNoticeTypes } from '../../../../constants';
 
     const OTHER_INFO = 'OTHER_INFO';

     export default {
         props: ['previous_form', 'saved_form', 'is_not_submitted', 'tpp_procurement_type', 'procedure'],
         data() {
             return {
                form: {
                    payment_terms: null,
                    risk_description: null,
                    is_gpa: null,
                    is_cptpp: null,
                    has_conflict_assessment: false
                }
            }
         },
        setup: () => ({
             v: useVuelidate()
        }),
        created() {
            if(this.saved_form) this.form = this.saved_form

        },
        validations() {
            let rules = {
                form: {
                    has_conflict_assessment: {
                        required,
                        $autoDirty: true
                    }
                }
            }

            // if(this.tpp_procurement_type == this.TPPNoticeTypes.UK4.value) {
            //     rules.form = {...rules, ...{
                    
            //     }}
            // }
            
            return rules
        },
        computed: {
        ...mapState(['TPPNoticeTypes', 'TPPFormParts']),
        },
        watch: {
            saved_form: {
                handlers(form){
                    this.form = form
                },
                deep: true
            }
        },
        methods: {
            saveForm() {
                this.$emit('save', OTHER_INFO, this.form)
            },
            getPrevious() {
                delete this.previous_form.id
                this.form = this.previous_form
            },
        }

     }
 </script>