<template>
    <div>
      <h4 class="text-dark">List of Participants</h4>
      <div class="row">
        <div class="col-md-12">
          <div class="card my-3">
            
            <div class="card-body px-0 pb-2">
              <div class="table-responsive">
                <nav class="navbar shadow-none my-3 navbar-transparent float-end">
                  <div class="bg-white border-radius-lg d-flex me-2">
                    <input type="text" v-on:keyup.enter="loadParticipants" name="" id="" class="form-control form-control-sm border-1 ps-3" v-model="search_value"
                      placeholder="Search" />

                    <el-button v-if="view_type == 'shortlist' && show_shortlist_info" @click="openListShortlist" class="ms-2" type="primary"><i class="fa fa-eye"></i>View Participant Shortlist</el-button>
                  </div>
                </nav>
                <table class="table table-fluid align-items-center mb-0">
                  <thead>
                    <tr class="text-sm">
                      <th>Name</th>
                      <th>Company</th>
                      <th>Date Joined</th>
                      <th>Status Joined</th>
                      <th v-if="view_type == 'shortlist' && show_shortlist_info">Is in Shortlist?</th>
                      <th v-if="view_type == 'shortlist' && show_shortlist_info">Is Excluded in Shortlist?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!participants.length">
                      <td v-if="isLoading" colspan="100%" class="text-sm">
                        <i class="fas fa-cog fa-spin me-2"></i>Loading...
                      </td>
                      <td v-else colspan="100%">No participants</td>
                    </tr>
                    <tr v-else class="text-sm" v-for="participant in participants" :key="participant.id">
                      <td>{{ participant?.user.name }}</td>
                      <td>{{ participant?.company.name }}</td>
                      <td>{{ type == "pin" ? $filters.datetimeFormatFilter(participant.pin_join_date) : $filters.datetimeFormatFilter(participant.itt_join_date) }}</td>
                      <td>{{ participant?.pin_status_joined }}</td>
                      <td v-if="view_type == 'shortlist' && show_shortlist_info">
                        <el-tag v-if="participant.if_shortlist_included" type="success">Yes</el-tag>
                        <el-tag v-else type="warning">No</el-tag>
                      </td>
                      <td v-if="view_type == 'shortlist' && show_shortlist_info">
                        <el-tag v-if="participant.if_shortlist_excluded" type="danger">Yes</el-tag>
                        <el-tag v-else type="info">No</el-tag>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-footer pt-0">
              <pagination :meta="meta" v-on:update="loadParticipants()" />
            </div>
          </div>
        </div>

      </div>
    </div>

    <el-dialog id="shortlist-modal" :close-on-click-modal="false" v-model="shortlist_modal" width="70%">
      
      <AntDesignTransferComponent
          :filter_placeholder="'Search...'"
          :transfer_data="participants_for_shortlist"
          :left_title="': List of Participants'"
          :right_title="': Selected Participants for Shortlist'"
          :right_button_text="null"
          :left_middle_button_text="'Remove from Shortlist'"
          :model_data="selected_shortlist"
          :right_middle_button_text="'Select for Shortlist'"
          :option_format="'jaas_invite'"
          @update_value="getSelectedParticipants"
      ></AntDesignTransferComponent>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="shortlist_modal = false">Close</el-button>
          <el-button type="primary" @click="saveShortList"><i class="fa fa-save"></i>&nbsp;Save</el-button>
        </div>
      </template>
    </el-dialog>
    
  </template>
  
  <script>
  import { ProcurementProjectParticipants } from '@/api_services/procurement_project_participants'
  import AntDesignTransferComponent from '@/components/common/AntDesignTransferComponent.vue'
  import Swal from 'sweetalert2'

  export default {
    watch: {
      search_value: function () {
        //if (!this.search_value || this.search_value.length > 2) this.loadParticipants()
      }
    },
    props: {
        id: Number,
        type: String,
        view_type: {
          type: String,
          required: false,
          default: "participants"
        },
        show_shortlist_info: {
          type: Boolean,
          required: false,
          default: true
        },
    },
    components: {
      AntDesignTransferComponent
    },
    
    data() {
      return {
        selected_shortlist: [],
        participants_for_shortlist: [],
        shortlist_modal: false,
        search_value: null,
        isLoading : true,
        participants: [],
        meta: {
          page: 1,
          total: 0,
          pages: 1
        },
      }
    },
    created: function() {
      this.loadParticipants()
    },
    methods: {
      saveShortList(){
        Swal.fire({
          title: 'Save this Shortlist?',
          text: 'By clicking continue, you will be saving selected participants to be included in shortlist. Do you want to proceed?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Continue',
          cancelButtonText: 'Cancel',
        }).then((result) => {
          if (result.isConfirmed) {
            this.globalState.loading = true

            ProcurementProjectParticipants.updateShortlist(this.id, { ids: this.selected_shortlist })
              .then(result => {
                this.$toast.success(result.data.message)
                this.loadParticipants()
                this.shortlist_modal = false
              })
              .catch(error => {
                console.log(error)
              })
              .finally(() => {
                this.globalState.loading = false
              })

          }
        });
      },
      openListShortlist(){
        this.loadParticipants(0)
      },
      getSelectedParticipants(value){
        this.selected_shortlist = value
      },
      loadParticipants(if_paginated = 1){
        this.isLoading = true
        let self = this
        self.participants_for_shortlist = []
        self.selected_shortlist = []

        ProcurementProjectParticipants.loadParticipants(this.id, 
        { params: { 
            participation_type: this.type,
            page: this.meta.page,
            search_value: this.search_value,
            if_paginated: if_paginated,
            view_type: this.view_type
          } 
        }
        )
        .then(result => {
          if (result.data.participants){
            if(if_paginated == 1){
              this.participants = result.data.participants
              this.meta = result.data.meta
            }
            else{

              result.data.participants.forEach(function(
                    data, key) {
                      self.participants_for_shortlist.push({
                                value: data.participant_id,
                                label: data?.user.name,
                                id: data.participant_id,
                                name: data?.user.name,
                                disabled: false,
                                email: data?.email,
                                company_name: data?.company.name,
                                sector: data?.company?.sector,
                                type: data?.user?.type
                      })
                      
                      if(data.if_shortlist_included == 1){
                        self.selected_shortlist.push(data.participant_id)
                      }
                    }
                )

              this.shortlist_modal = true
            }
          } 
          
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
      }
    },
    computed: {
  
    }
  }
  </script>
  