import api from './api'

const API = '/api/procurement_project_timeline'

export const ProcurementProjectTimeline = {
  get: function(params) {
    return api.get(API, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(id, params) {
    return api.update(API+'/'+id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  },
}
