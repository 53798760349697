export const InvoicePaymentStatuses = Object.freeze({
  DRAFT: 0,
  APPROVED: 1,
  PAID: 2
})

export const InvoicePaymentStatusTexts = Object.fromEntries(
  Object.entries(InvoicePaymentStatuses).map(a => a.reverse())
)

export const InvoiceItemsStatuses = Object.freeze({
  REGULAR: 0,
  DEDUCTION: 1,
  DISCOUNT: 2
})

export const InvoiceItemsStatusTexts = Object.fromEntries(
  Object.entries(InvoiceItemsStatuses).map(a => a.reverse())
)


export const InvoiceTypes = Object.freeze({
  CARD_TRANSACTIONS: 0,
  BANK_REPORT_TRANSACTIONS: 1,
  COMPANY_INVOICE: 2
})

export const CommonStatuses = Object.freeze({
  QUEUED: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  ERROR: 3
})

export const ProductStatuses = Object.freeze({
  STATUS_NEW: 0,
  STATUS_PROCESSING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3,
  STATUS_DELETING: 4
})

export const CommonStatusTexts = Object.fromEntries(
  Object.entries(CommonStatuses).map(a => a.reverse())
)

export const ProcurementProjectStatuses = Object.freeze({
  CODE_PENDING: "PEN",
  CODE_PRE_PIN: "PRE",
  CODE_SUBMITTED: "SUB",
  CODE_ACTIVE: "ATC",
  CODE_PIN_ARCHIVED: "PAR",
  CODE_DOC_FINALIZE: "DOC",
  CODE_ITT_PENDING: "ITP",
  CODE_ITT_SUBMITTED: "ITS",
  CODE_ITT: "ITT",
  CODE_MARKING_STAGE: "MKR",
  CODE_TN_ARCHIVED: "IAR",
  CODE_COM: "COM",
  CODE_PME_SUBMITTED: "PMS",
  CODE_PPN_ACTIVE_NOT_PUBLISHED: "PLN",
  CODE_PME_ACTIVE_NOT_PUBLISHED: "PMN",
  CODE_AWARD_STANDSTILL: "ASL",
  CODE_ITT_ACTIVE: "ITA"
})

export const FATFormParts = Object.freeze({
  PRELIMINARY: 0,
  SECTION_ONE: 1,
  SECTION_TWO: 2,
  SECTION_THREE: 3,
  SECTION_FOUR: 4,
  SECTION_FIVE: 5,
  SECTION_SIX: 6,
})

export const FATNoticeTypes = Object.freeze({
  F01: {
    value: 0,
    parts: [FATFormParts.PRELIMINARY, FATFormParts.SECTION_ONE, FATFormParts.SECTION_TWO, FATFormParts.SECTION_FOUR, FATFormParts.SECTION_SIX]},
  F02: {
    value: 1,
    parts: [FATFormParts.PRELIMINARY, FATFormParts.SECTION_ONE, FATFormParts.SECTION_TWO, FATFormParts.SECTION_THREE, FATFormParts.SECTION_FOUR, FATFormParts.SECTION_SIX]},
  F03: {
    value: 2,
    parts: [FATFormParts.PRELIMINARY, FATFormParts.SECTION_ONE, FATFormParts.SECTION_TWO, FATFormParts.SECTION_THREE, FATFormParts.SECTION_FOUR, FATFormParts.SECTION_FIVE, FATFormParts.SECTION_SIX]}
})

export const TPPFormParts = Object.freeze({
  NOTICE_LINKING: 'NOTICE_LINKING',
  CONTRACTING_AUTH: 'CONTRACTING_AUTH',
  CONTRACTING_AUTH_INFO: 'CONTRACTING_AUTH_INFO',
  CONTACT_ORG: 'CONTACT_ORG',
  OTHER_ORG: 'OTHER_ORG',
  PROCEDURE: 'PROCEDURE',
  SCOPE: 'SCOPE',
  PARTICIPATION: 'PARTICIPATION',
  ENGAGEMENT: 'ENGAGEMENT',
  SUBMISSION: 'SUBMISSION',
  DOCUMENTS: 'DOCUMENTS',
  LOTS: 'LOTS',
  AWARD_CRITERIA: 'AWARD_CRITERIA',
  OTHER_INFO: 'OTHER_INFO',
  FRAMEWORK: 'FRAMEWORK'
})

export const TPPNoticeTypes = Object.freeze({
  UK2: {
    value: 'UK2',
    parts: [TPPFormParts.NOTICE_LINKING, TPPFormParts.CONTRACTING_AUTHORITIES, TPPFormParts.CONTACT_ORGANISATION, TPPFormParts.PROCEDURE, TPPFormParts.SCOPE, TPPFormParts.PARTICIPATION, TPPFormParts.ENGAGEMENT, TPPFormParts.SUBMISSION]},
  UK3: {
    value: 'UK3',
    parts: [TPPFormParts.NOTICE_LINKING, TPPFormParts.CONTRACTING_AUTHORITIES, TPPFormParts.CONTACT_ORGANISATION, TPPFormParts.PROCEDURE, TPPFormParts.SCOPE, TPPFormParts.PARTICIPATION, TPPFormParts.SUBMISSION]},
  UK4: {
    value: 'UK4',
    parts: [TPPFormParts.NOTICE_LINKING, TPPFormParts.CONTRACTING_AUTHORITIES, TPPFormParts.CONTACT_ORGANISATION, TPPFormParts.PROCEDURE, TPPFormParts.SCOPE, TPPFormParts.PARTICIPATION, TPPFormParts.SUBMISSION]},
  UK6: {
    value: 'UK6',
    parts: [TPPFormParts.PRELIMINARY, TPPFormParts.SECTION_ONE, TPPFormParts.SECTION_TWO, TPPFormParts.SECTION_THREE, TPPFormParts.SECTION_FOUR, TPPFormParts.SECTION_FIVE, TPPFormParts.SECTION_SIX]}
})

export const ONSCodes_Country = Object.freeze({
  // K02000001:	'United Kingdom',
  E10000003:	'Cambridgeshire',
  E10000006:	'Cumbria',
  E10000007:	'Derbyshire',
  E10000008:	'Devon',
  E10000011:	'East Sussex',
  E10000012:	'Essex',
  E10000013:	'Gloucestershire',
  E10000014:	'Hampshire',
  E10000015:	'Hertfordshire',
  E10000016:	'Kent',
  E10000017:	'Lancashire',
  E10000018:	'Leicestershire',
  E10000019:	'Lincolnshire',
  E10000020:	'Norfolk',
  E10000023:	'North Yorkshire',
  E10000024:	'Nottinghamshire',
  E10000025:	'Oxfordshire',
  E10000027:	'Somerset',
  E10000028:	'Staffordshire',
  E10000029:	'Suffolk',
  E10000030:	'Surrey',
  E10000031:	'Warwickshire',
  E10000032:	'West Sussex',
  E10000034:	'Worcestershire',
  E11000001:	'Greater Manchester',
  E11000002:	'Merseyside',
  E11000003:	'South Yorkshire',
  E11000005:	'West Midlands',
  E11000006:	'West Yorkshire',
  E11000007:	'Tyne and Wear'
})
export const ONSCodes_Borough = Object.freeze({
  E09000002:	'Barking and Dagenham',
  E09000003:	'Barnet',
  E09000004:	'Bexley',
  E09000005:	'Brent',
  E09000006:	'Bromley',
  E09000007:	'Camden',
  E09000008:	'Croydon',
  E09000009:	'Ealing',
  E09000010:	'Enfield',
  E09000011:	'Greenwich',
  E09000012:	'Hackney',
  E09000013:	'Hammersmith and Fulham',
  E09000014:	'Haringey',
  E09000015:	'Harrow',
  E09000016:	'Havering',
  E09000017:	'Hillingdon',
  E09000018:	'Hounslow',
  E09000019:	'Islington',
  E09000020:	'Kensington and Chelsea',
  E09000021:	'Kingston upon Thames',
  E09000022:	'Lambeth',
  E09000023:	'Lewisham',
  E09000024:	'Merton',
  E09000025:	'Newham',
  E09000026:	'Redbridge',
  E09000027:	'Richmond upon Thames',
  E09000028:	'Southwark',
  E09000029:	'Sutton',
  E09000030:	'Tower Hamlets',
  E09000031:	'Waltham Forest',
  E09000032:	'Wandsworth',
  E09000033:	'Westminster',
})

export const BuyerRoleName = "BUYER"

export const MonthyReportCategories = Object.freeze({
  bff: "Banking and Finance",
  fspf: "Financial Services and Payments"
})
export const SupplierRoleName = "SUPPLIER"
export const OriginatorRoleName = "ORIGINATOR"

export const PinNoticeStatuses = Object.freeze({
  PENDING: 0,
  ACTIVE: 1,
  ARCHIVED: 2
})

export const IttStatuses = Object.freeze({
  PENDING: 2,
  ACTIVE: 1,
  ARCHIVED: 0
})

export const QaQuestionTypes = Object.freeze({
  TYPE_BOOLEAN: 1,
  TYPE_TEXT: 2,
  TYPE_FILE: 3,
  TYPE_HAS_SUB_QUESTION: 4,
  TYPE_TABLE: 5
})

export const GuidanceEvaluationAnswerRequirements = Object.freeze({
  ANSWER_REQUIREMENT_ALL: 0,
  ANSWER_REQUIREMENT_ATLEAST_1: 1,
  ANSWER_REQUIREMENT_NO_ANSWER: 2,
})

export const GuidanceEvaluationAnswerType = Object.freeze({
  NEGATIVE_ANSWER: 0,
  POSITIVE_ANSWER: 1,
})

export const QaSubmissionStatuses = Object.freeze({
  STATUS_PENDING: 0,
  STATUS_VERIFYING: 1,
  STATUS_APPROVED: 2,
  STATUS_REJECTED: 3
})

export const ApplicationHistoryTypes = Object.freeze({
  TYPE_CREATE: { text: "Create", value: 1 },
  TYPE_APPROVAL_REQUEST: { text: "Request Approval", value: 2 },
  TYPE_EDIT: { text: "Edit", value: 3 },
  TYPE_DELETE_REQUEST: { text: "Request Deletion", value: 4 },
  TYPE_DELETE: { text: "Delete", value: 5 },
  TYPE_GENERIC_REQUEST: { text: "Generic Request", value: 6 },
  TYPE_DOWNLOAD: { text: "Download", value: 7 },
  TYPE_ACTIVATION: { text: "Activation", value: 8 },
  TYPE_ARCHIVE: { text: "Archive", value: 9 },
  TYPE_GENERIC_PROCESS: { text: "Generic Process", value: 10 },
  TYPE_UPLOAD: { text: "Upload", value: 11 }
})

export const ApplicationThemeTypes = Object.freeze({
  TYPE_INDIVIDUAL_DEFAULT: 0,
  TYPE_APPLICATION_DEFAULT: 1,
  TYPE_BCP: 2
})

export const SpecTypes = Object.freeze({
  selectType: Object.freeze({ value: null, text: 'Select Type', unit: null }),
  time: Object.freeze({
    value: 'time', text: 'Time', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 's', text: 'second (s)', unit: 's' }),
      Object.freeze({ value: 'm', text: 'minute (m)', unit: 'm' }),
      Object.freeze({ value: 'hr', text: 'hour (hr)', unit: 'hr' })
    ])
  }),
  weight: Object.freeze({
    value: 'weight', text: 'Weight', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'g', text: 'gram (g)', unit: 'g' }),
      Object.freeze({ value: 'kg', text: 'kilogram (kg)', unit: 'kg' }),
      Object.freeze({ value: 'mg', text: 'milligram (mg)', unit: 'mg' })
    ])
  }),
  height: Object.freeze({
    value: 'height', text: 'Height', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'cm', text: 'centimeter (cm)', unit: 'cm' }),
      Object.freeze({ value: 'm', text: 'meter (m)', unit: 'm' }),
      Object.freeze({ value: 'mm', text: 'millimeter (mm)', unit: 'mm' })
    ])
  }),
  choice: Object.freeze({
    value: 'choice', text: 'Choice', units: Object.freeze([
      Object.freeze({ value: 'yes', text: 'Yes/No', unit: 'yes/no' }),
    ])
  }),
  speed: Object.freeze({
    value: 'speed', text: 'Speed', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'mps', text: 'meters per second (m/s)', unit: 'm/s' }),
      Object.freeze({ value: 'kph', text: 'kilometers per hour (km/h)', unit: 'km/h' })
    ])
  }),
  distance: Object.freeze({
    value: 'distance', text: 'Length/Distance', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'mm', text: 'millimeter (mm)', unit: 'mm' }),
      Object.freeze({ value: 'cm', text: 'centimeter (cm)', unit: 'cm' }),
      Object.freeze({ value: 'm', text: 'meter (m)', unit: 'm' }),
      Object.freeze({ value: 'km', text: 'kilometer (km)', unit: 'km' })
    ])
  }),
  volume: Object.freeze({
    value: 'volume', text: 'Volume', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'ml', text: 'milliliter (ml)', unit: 'ml' }),
      Object.freeze({ value: 'l', text: 'liter (L)', unit: 'L' })
    ])
  }),
  area: Object.freeze({
    value: 'area', text: 'Area', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'sqm', text: 'square meter (m²)', unit: 'm²' }),
      Object.freeze({ value: 'sqcm', text: 'square centimeter (cm²)', unit: 'cm²' }),
      Object.freeze({ value: 'sqmm', text: 'square millimeter (mm²)', unit: 'mm²' }),
      Object.freeze({ value: 'hectare', text: 'hectare', unit: 'hectare' })
    ])
  }),
  digital: Object.freeze({
    value: 'digital', text: 'Digital', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'b', text: 'byte (B)', unit: 'B' }),
      Object.freeze({ value: 'kb', text: 'kilobyte (KB)', unit: 'KB' }),
      Object.freeze({ value: 'mb', text: 'megabyte (MB)', unit: 'MB' }),
      Object.freeze({ value: 'gb', text: 'gigabyte (GB)', unit: 'GB' }),
      Object.freeze({ value: 'tb', text: 'terabyte (TB)', unit: 'TB' })
    ])
  })
});

export const SocValTypes = Object.freeze({
  selectType: Object.freeze({ value: null, text: 'Select Type', unit: null }),
  time: Object.freeze({
    value: 'time', text: 'Time', units: Object.freeze([
      Object.freeze({ value: null, text: 'Select a unit', unit: null }),
      Object.freeze({ value: 'hrs', text: 'hours', unit: 'hours' }),
      Object.freeze({ value: 'weeks', text: 'weeks', unit: 'weeks' }),
    ])
  }),

  choice: Object.freeze({
    value: 'choice', text: 'Choice', units: Object.freeze([
      Object.freeze({ value: 'yes', text: 'Yes/No', unit: 'yes/no' }),
    ])
  }),

  number: Object.freeze({
    value: 'number', text: 'Number', units: Object.freeze([
      Object.freeze({ value: 'FTE', text: 'FTE', unit: 'FTE' }),
      Object.freeze({ value: 'Individuals', text: 'Individuals', unit: 'Individuals' }),
      Object.freeze({ value: 'Instances', text: 'Instances', unit: 'Instances' }),
      Object.freeze({ value: 'Audits', text: 'Audits', unit: 'Audits' }),
      Object.freeze({ value: 'sessions', text: 'Sessions', unit: 'sessions' }),
      Object.freeze({ value: 'innovations', text: 'Innovations', unit: 'innovations' }),
      Object.freeze({ value: 'apprenticeships', text: 'Apprenticeships', unit: 'apprenticeships' }),
      Object.freeze({ value: 'tonnes', text: 'tonnes', unit: 'tonnes' }),
      Object.freeze({ value: 'programmes', text: 'Programmes', unit: 'programmes' }),
    ])
  }),

  percentage: Object.freeze({
    value: 'percentage', text: 'Percentage', units: Object.freeze([
      Object.freeze({ value: 'Percentage', text: '%', unit: '%' }),
    ])
  }),

  currency: Object.freeze({
    value: 'currency', text: 'Currency', units: Object.freeze([
      Object.freeze({ value: 'GBP', text: 'GBP', unit: 'GBP' }),
    ])
  }),

});

export const ProjectFilters = Object.freeze({
  FILTER_ALL: "all",
  FILTER_COMPANY: "company",
  FILTER_MY: "my"
})

export const ContractYears = Object.freeze([
  Object.freeze({ value: 0, text: '1-4 years' }),
  Object.freeze({ value: 1, text: '4-6 years' }),
  Object.freeze({ value: 2, text: '6-8 years' }),
  Object.freeze({ value: 3, text: '8-10 years' }),
  Object.freeze({ value: 4, text: '10+ years' }),
])

export const MonthyReportCategoryIDs = Object.freeze({
  bff: 1,
  fspf: 2
})

export const DirectAwardReasons = Object.freeze([
  "Extreme Urgency",
    "No Suitable Competition",
    "Intellectual Property Rights",
    "Continuation of Existing Services",
    "Only One Supplier Available (Technical or Artistic Reasons)",
    "National Security or Confidentiality",
    "Supplier’s Proven Track Record",
    "Compatibility with Existing Infrastructure",
    "Framework Agreement (Direct Award Permitted)",
    "Value Below Threshold",
    "Contract Termination",
    "Urgent Health and Safety Requirements",
    "Proprietary Technology",
    "Specialized Workforce or Expertise",
    "Pilot Project",
    "Collaborative Project with Another Entity",
    "Supplier Financial Stability",
    "Continuity of Warranty or Maintenance Agreements",
    "Compliance with Grant or Funding Requirements",
    "Procurement as a Sole Source from a Government Framework",
    "Supplier’s Geographic Proximity",
    "Urgent Compliance with New Legislation",
    "Risk to Critical Services",
    "Supplier's Environmental Impact",
    "Interruption to Existing Projects",
    "Avoiding Duplication of Work",
    "Specialist Equipment Compatibility",
    "Non-Disclosure of Sensitive Information",
    "Supplier's Ethical or Social Value Alignment",
    "Supplier’s History with Similar Projects",
    "Pre-existing Supplier Knowledge",
    "Preventing Supply Chain Interruptions",
    "Customer Service Excellence",
    "Supplier’s Financial Support (e.g., Installment Plans)",
    "Availability of Supplier-Specific Training",
    "Unique Construction or Technical Requirements",
    "Supplier’s Security Clearance",
    "Time-Limited Funding Opportunities",
    "Strategic Supplier Partnerships",
    "Supplier’s Ability to Meet Tight Timelines",
    "Availability of Local Suppliers",
    "Supplier Specializing in Small-Scale Production",
    "Legal Restrictions on Supplier Choice",
    "Supplier Expertise in High-Risk Environments",
    "Compliance with Accessibility Standards",
    "Unavailability of Substitute Products",
    "Single-Source for Repairs or Maintenance",
    "Unique Customer Requirements",
    "Supplier-Specific Certifications",
    "Supplier’s Adherence to Regulatory Bodies",
    "Supplier’s Ability to Scale Production",
    "Supplier’s Experience in International Markets",
    "Pre-approved Sole Source Supplier",
    "Innovative Technology or Methodologies",
    "Supplier’s Legal Protections for Proprietary Data",
    "Buyer-Supplier Long-term Relationship",
    "Significant Time or Cost Savings",
    "Supply Chain Transparency",
    "Buyer Requirements for Product Customization",
    "Regulatory Compliance for International Shipping",
    "Supplier’s Ability to Provide Post-Sale Support",
    "Cost of Switching Suppliers",
    "Avoiding Contractual Disputes",
    "Market Volatility or Instability",
    "Supplier’s Ability to Provide Technical Documentation",
    "Avoiding Regulatory Fines or Penalties",
    "Supplier Offering Lifetime Warranties",
    "Direct Supply from Manufacturer",
    "Supplier’s Ability to Meet Environmental or Sustainability Goals",
    "Supplier’s Reputation for Ethical Practices",
    "Cultural or Language Barriers",
    "Avoiding Intellectual Property Violations",
    "Limited Timeframe for Completion",
    "Project is of a Highly Specialized Nature",
    "Cost-Effectiveness of Using a Local Supplier",
    "Existing Service Level Agreements (SLAs)",
    "Supplier’s Unique Market Position",
    "Avoiding Brand Reputation Risk",
    "Supplier’s Ability to Provide Integrated Solutions",
    "Historical Data Supporting Supplier's Performance",
    "Avoiding Duplication of Framework Agreements",
    "Supplier’s Commitment to Corporate Social Responsibility",
    "Supplier’s Experience in Specific Geographic Regions",
    "Supplier’s Ability to Meet Disaster Recovery Objectives",
    "Legal Precedent or Obligations",
    "Conflict of Interest Avoidance",
    "Supplier’s Ownership of Required Patents",
    "Historical Working Relationship with Public Entities",
    "Supplier’s Ability to Maintain Consistent Service Delivery",
    "Specialized Contractual Conditions",
    "Avoiding Supplier Switching Costs",
    "Unique Financial Arrangements (e.g., Financing or Leasing)",
    "Supplier’s Access to Required Data",
    "Supplier’s Established Supply Chain",
    "Supplier’s Cultural or Community Connections",
    "Unique Pricing Structures",
    "Supplier’s Ability to Meet Strict Environmental Regulations",
    "Risk Mitigation for Critical Infrastructure Projects",
    "Supplier’s Ability to Implement Specialized Software or IT Solutions",
    "Pre-existing Relationship with Key Stakeholders"
]);

export const NOTICE_DESCRIPTION = Object.freeze({
    'UK2': "Preliminary Market Engagement (PME)",
    'UK3': "Planned Procurement Notice (PPN)",
    'UK4': "Tender Notice",
    'UK6': "Contract Award Notice",
    'UK7': "Contract Details Notice"
})

export const NOTICE_STATUS = Object.freeze({
  0: "DRAFT",
  1: "IN-PROGRESS",
  2: "SUBMITTED",
  3: "PUBLISHED",
  9: "ERROR"
})