<template>
    <el-form label-position="top">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <div class=""  >
                    <div>
                        <label>Choose Threshold</label>
                        <div :class="{ 'has-error-field': v$.threshold.$errors.length }">
                            <el-radio-group v-model="form.threshold" >
                                <el-radio :value="0" size="large" >Above-Threshold</el-radio>
                                <el-radio :value="1" size="large" >Below-Threshold</el-radio>
                            </el-radio-group>
                        </div>
                        <div v-if="v$.threshold.$errors.length" class="error-text">Select an option</div>
                    </div>
                </div>

                <div v-if="form.threshold != null">
                    <label class="large-label">Please select the procedure for this framework.</label>
                    <div :class="{ 'has-error-field': v$.standard_type.$errors.length }">
                        <el-radio-group v-model="form.standard_type">
                            <el-radio :value="0" size="large">Open Procedure</el-radio>
                            <el-radio :value="1" size="large" v-if="form.threshold == 0">Competitive Flexible Procedure (CFP)</el-radio>
                        </el-radio-group>
                    </div>
                    <div v-if="v$.standard_type.$errors.length" class="error-text">Select an option</div>
                </div>

                <div class="" style="padding-top: 20px" v-if="form.threshold != null && form.standard_type != null">
                    <div>
                        <label>Contact Value (Incl. VAT)</label>
                        <div :class="{ 'has-error-field': v$.contact_value.$errors.length }">
                            <el-input v-model="form.contact_value" type="number"></el-input>
                        </div>
                        <div v-if="v$.contact_value.$errors.length" class="error-text">Please provide an amount</div>
                    </div>
                </div>
            </div>
            
        </div>
    </el-form>
</template>

<script>
import { reactive, ref, watch, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'
import dayjs from 'dayjs'

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    emits: ['update:form'],
    setup(props, { emit }) {

        const rules = computed(() => {
            return {
                standard_type: {
                    required,
                    minValue: minValue(0),
                    $autoDirty: true
                },
                threshold: {
                    required,
                    minValue: minValue(0),
                    $autoDirty: true
                },
                contact_value: {
                    required,
                    minValue: minValue(0),
                    $autoDirty: true
                },
            }
        })

        const v$ = useVuelidate(rules, props.form)

        watch(() => props.form, (newVal) => emit('update:form', newVal), { deep: true })

        return { v$ }
    }
}
</script>

<style scoped>

</style>