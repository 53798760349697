<style lang="css">
  /* .navigation_title_font_color{
    color: v-bind('theme?.navigation_title?.font_color?.value') !important
  } */
  /* .upper_body_color{
    background-color: v-bind('theme?.upper_body?.background_color?.value') !important;
  } */
  /* .sidebar_background{
    background-color: v-bind('theme?.sidebar?.background_color?.value') !important;
  } */
  /* .navigation_font_color, .navigation_font_color a{
    color: v-bind('theme?.navigation?.font_color?.value') !important;
  } */
  :root {
    --worksss: pink;
  }
</style>

<style lang="scss">
  /*$colors: (
        'primary': (
                'base': green,
        ),
        'success': (
                'base': #13ce66,
        ),
        'warning': (
                'base': #ffba00,
        ),
        'danger': (
                'base': #ff4949,
        ),
        'error': (
                'base': #db2828,
        ),
        'info': (
                'base': #42b8dd,
        ),
);
$testt: 20px;
@forward "element-plus/theme-chalk/src/common/var.scss" with (
$colors: $colors
);
*/
@use "element-plus/theme-chalk/src/index.scss" as *;
</style>

<template>
  <div>
    <div id="app" class="g-sidenav-show" :class="{ 'logged-in': !is_route_public }">
      <div class="staging text-center text-white bg-danger sticky-top" v-if="mode != 'production'">
        {{mode}} {{ is_bcp ? ' BCP':''}}
      </div>

      <div class="text-center text-white bg-warning sticky-top p-2"
       style="background-color: rgb(206, 237, 32) !important;"
       v-if="user?.if_configuring_theme && user?.if_configuring_theme == 1 && is_bcp == 1" >
        <b class="text-dark">Configuring Theme Mode: click the button below to open the settings drawer
          <el-button type="danger"
          @click="updateThemeConfig(0, 0, 'exit')"
          class="ms-2" native-type="button"
          ><i class="fa fa-times"></i>&nbsp;Close without saving</el-button>

          <el-button type="primary"
          @click="updateThemeConfig(1, 1, 'save')"
          class="ms-2" native-type="button"
          ><i class="fa fa-save"></i>&nbsp;Save Changes</el-button>
        </b>
      </div>

      <div v-if="status == 1">
        <!-- <page-not-found-component></page-not-found-component> -->
      </div>
      <div v-if="status == 2">
        <div class="min-height-300 position-absolute w-100" :style="{ backgroundColor: theme?.upper_body?.background_color?.value ?? '#2dce89' }"></div>
        <sidebar-drawer-component v-if="!is_route_public"></sidebar-drawer-component>
        <main class="position-relative max-height-vh-100 h-100" :class="!is_route_public ? 'content-adjustment':''">
          <navbar-component v-if="!is_login_route && !is_route_public"></navbar-component>
          <div class="py-4 container-fluid">
            <router-view></router-view>
          </div>
        </main>
      </div>
    </div>

    <sidebar-component v-if="!is_login_route"></sidebar-component>

  </div>

  <el-button  
  native-type="button" 
  @click.prevent="openDrawer" 
  v-if="user?.if_configuring_theme && user?.if_configuring_theme == 1 && is_bcp == 1" 
  class="theme_config_btn" size="large" type="warning"  >
  <i class="fa fa-gear"></i>&nbsp; Configure Theme</el-button>


  <el-drawer ref="themeDrawer" :modal="false" v-model="theme_config_drawer" :show-close="true" :size="drawer_size"  :destroy-on-close="true">
    <div style="z-index: 20000 !important;" class="container">
      <ApplicationThemeConfig
      :selected_theme="theme"
      @updateDrawerSize="updateDrawerSize"
      ></ApplicationThemeConfig>
    </div>
  </el-drawer>
</template>

<script setup>
    //for css
</script>

<script>
import SidebarDrawerComponent from './components/common/SidebarDrawerComponent.vue'
import SidebarVue from './components/pages/Sidebar.vue'
import NavBarComponent from './components/pages/Navbar.vue'
import PageNotFoundComponent from './components/common/PageNotFoundComponent.vue'
import store from './store/index.js'
import Swal from 'sweetalert2';
import { ProductSearchMessageApi } from '@/api_services/product_search_message'
import { mapState } from 'vuex'
import CPRAS_logo from '@/assets/CPRAS_logo.png'
import ApplicationThemeConfig from './components/pages/application_themes/ApplicationThemeConfig.vue'
import { setIndividualColor, setTheme } from '@/utils/theme/theme'
import { setButtonColors } from '@/utils/theme/element_plus_button_theme'
import { User } from '@/api_services/user'

export default {
  data() {
    return {
      drawer_size: "40%",
      color: '255 0 0',
      theme_config_drawer: false,
      default_logo: CPRAS_logo,
      theme_type: null,
      theme: null,
      route_name: null,
      correspondent_count: 0,
      is_route_public: false,
      is_login_route: false,
      status: 0,
      mode: process.env.VUE_APP_MODE,
      is_bcp: parseInt(process.env.VUE_APP_IS_BCP),
      theme_config: {
        selected_theme_type: null,
        id: null,
        type: null,
        theme_content: null,
        if_finalized: null,
        if_save_content: null
      }
    }
  },
  computed: {
    ...mapState(['ApplicationThemeTypes']),
    user() {
      return this.$store.state.savedUser // get state
    }
  },
  components: {
    'sidebar-component': SidebarVue,
    'navbar-component': NavBarComponent,
    SidebarDrawerComponent,
    PageNotFoundComponent,
    ApplicationThemeConfig
  },
  watch: {
    $route(to, from) {
      this.checkIfPublic()
    },
    '$store.state.new_notifications':{
      handler(value){
        if(value){
          this.getMessagesNotifications()
        }
      },
      deep:true
    },
    '$store.state.test_mail_cooldown':{
      handler(value){
        if(value == 1){
          this.startCooldown(0, 60, 60)
        }
      },
      deep:true
    },
    'user.selected_theme.theme_content':{
      handler(value){
        if(this.is_bcp == 1){
          if(this.user?.selected_theme?.theme_content){
            this.theme = this.user?.selected_theme?.theme_content
            this.$store.commit('saveTemporarySelectedTheme', this.user?.selected_theme)
            
            this.modifyBodyColor()
            this.modifyElementPlusButtonColors()
            document.title = this.theme?.navigation_title?.text?.value
          }
        }
      },
      deep:true
    }
  },
  created: function() {
    
    this.checkIfPublic()
    this.isCooldownSet()

    if(this.is_bcp == 1){
      if(this.user?.selected_theme?.theme_content){
        if(this.user?.if_configuring_theme == 1){
          this.theme_type = this.user?.selected_theme_type
        }
        else{
          this.theme_type = this.user?.selected_theme?.type
        }
        this.theme = this.user?.selected_theme?.theme_content
        document.title = this.theme?.navigation_title?.text?.value
      }
    }
    else{
      this.theme = this.user?.default_theme?.theme_content
      this.theme_type = this.user?.selected_theme_type
    }
    this.modifyBodyColor()
    this.modifyElementPlusButtonColors()
  },
  methods: {
    openDrawer(){
      this.drawer_size = "40%"
      this.theme_config_drawer = true
    },
    updateDrawerSize(size){
      this.drawer_size = size + "%"
    },
    updateThemeConfig(if_finalized, if_save_content, type){
      let self = this 
      let close_config_status = 0
      Swal.fire({
        title: 'Proceed to '+type+'?',
        text: 'Proceed to '+type+' this BCP Theme configuration?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          self.globalState.loading = true
          
          var formData = new FormData()
          formData.append('selected_theme_type', self.user?.selected_theme?.type)
          formData.append('id', self.user?.selected_theme?.id)
          formData.append('type', self.theme_type)
          formData.append('theme_content', JSON.stringify(self.user?.selected_theme?.theme_content))
          formData.append('if_finalized', if_finalized)
          formData.append('if_save_content', if_save_content)
          
          User.switchThemeConfig(close_config_status, formData)
          .then(result => {
            Swal.fire({
              icon: 'info',
              title: 'Information',
              text: result.data.message,
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store.commit('saveTemporarySelectedTheme', self.user?.selected_theme)
              
              window.location.href = "/main/dashboard"
            }
          })
            
          })
          .catch(error => {
            self.$toast.error('An error occured while switching')
            console.error('error:', error.response.data)
          })
        }
      });
    },
    modifyElementPlusButtonColors(){
      
      let colors = { colors: [] }
      if(this.theme !== null && this.theme != undefined){
        
        Object.keys(this.theme).forEach(main_key => {
          if(this.theme[main_key].button_source == "element plus"){
            Object.keys(this.theme[main_key]).forEach(key => {
              if(this.theme[main_key][key].input_type && key == "background_color"){
                colors.colors[main_key.split("_")[1]] = this.theme[main_key][key].value
              }
            })
          }
        })
      }
      
      setButtonColors(colors)
    },
    modifyBodyColor(){
      let main_body_color = this.theme?.main_body?.background_color?.value
      if(main_body_color == undefined){
        main_body_color = "#2dce89"
      }
      document.getElementsByTagName('body')[0].style = 'background-color: ' + main_body_color + " !important";
    },
    startCooldown(min, sec, secLeft){
            
            if(min > 0 || secLeft > 0){
                if(secLeft == 0){ 
                    min -= 1
                    sec = 60
                }
                this.$button_send_test_mail_cooldown_min = min
                this.$store.commit('updateCooldown', { min: min, sec: sec })
                this.countDown(min, sec);
            }else{
                localStorage.removeItem("test_mail_cooldown");
                this.$store.commit('updateCooldown', { min: 0, sec: 0 })
                this.$store.commit('initiateTestEmailCooldown', 0)
            }
        },
        isCooldownSet(){
            if(localStorage.getItem("test_mail_cooldown") != null){
                var time = localStorage.getItem("test_mail_cooldown");
                var fields = time.split(':');

                this.$store.commit('updateCooldown', { min: fields[0], sec: fields[1] })
                this.startCooldown(fields[0], fields[1], fields[1]);
            }
        },
        rememberCooldown(min, sec){
            if (typeof(Storage) !== "undefined") {
                localStorage.setItem("test_mail_cooldown", min+':'+sec);
            }
        },
        countDown(min, sec){
            
            var time = sec;
            this.$store.commit('updateCooldown', { min: min, sec: time })
            let self = this
            var interval = setInterval(function(){
                var seconds = -- time
                self.$store.commit('updateCooldown', { min: min, sec: time })
                self.rememberCooldown(min, time);
                if (time == 0) {
                    clearInterval(interval);
                    self.startCooldown(min, sec, "00");
                }
            }, 1000);
        },
    getMessagesNotifications() {
        // ProductSearchMessageApi.get({type:"notification_count"})
        //   .then(result => {
        //     this.correspondent_count = result.data.product_search_message_reply_count
        //     this.$store.commit('updateMessageCount', this.correspondent_count)
        //   })
        //   .catch(error => {
        //     console.error('error:', error?.response?.data || error)
        //   })
        //   .finally(() => {
        //     this.$store.commit('newNotifications', false)
        //   })
      },
    checkIfPublic() {
      this.status = 0
      if (typeof this.$route.name === 'undefined') {
        this.status = 1
      } else {
        this.is_route_public = this.$route.meta && this.$route.meta.public
        this.status = 2
      }
      //console.log(this.$route)
    }
  },
  mounted() {
    Swal.close()
    //console.log(store.state.main_loading)
    //store.state.main_loading = false
  },
  beforeMount(){ 
    
  }
}
</script>

<style>

.theme_config_btn{
	position:fixed;
	bottom:40px;
	right:40px;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  /* color: #2c3e50; */
}

.v-notices {
  z-index: 99999 !important;
}

.staging {
  line-height: 1.5rem;
  font-size: 0.8rem;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

@media print {
  body.print-element *:not(.print) {
    display: none;
  }

  @page {
    size: landscape;
  }
}

.menu-label {
  font-size: 12px;
  display: inline;
  margin-left: 15px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (min-width: 768px) {
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  .b-sidebar-outer {
    display: none;
  }

  /* #sidebar.sb-sidenav-toggled li a span.menu-label{
      /* display: none; */
  /* } */

  /* #sidebar li a span.menu-label{
      animation: 5s fadeIn;
    } */

  body.sb-sidenav-toggled #app #sidebar-wrapper .logout-label {
    display: none;
  }

  /* .logged-in {
      margin-left: -210px;
      background-color: #f5f7f8;
      transition: margin-left .8s;
    } */

  body.sb-sidenav-toggled #app.logged-in #page-content-wrapper {
    margin-left: -210px;
    background-color: #f5f7f8;
    transition: margin-left 0.8s;
    /* z-index: 1; */
  }

  body #app #page-content-wrapper {
    margin-left: 0px;
    background-color: #f5f7f8;
    transition: margin-left 0.8s;
  }

  body #app #sidebar-wrapper {
    width: 270px;
    transition: width 0.8s;
  }

  #sidebar .nav-collapse {
    z-index: 1;
    background-color: #343a40;
  }

  #sidebar .nav-item:hover {
    z-index: 2;
  }
}
.content-adjustment {
  transition: all 0.2s ease-in-out;
}
@media (max-width: 1199.98px) {
    .content-adjustment {
      margin-left: 6rem !important
  }
}
@media (min-width: 1200px) {
    .content-adjustment {
      margin-left: 18.5rem
  }
}
</style>
